import * as React from "react";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { WithTranslation, withTranslation } from 'react-i18next';
import html2canvas from 'html2canvas';
import { notifyUser } from "../app";
import { InputAdornment, Typography } from "@material-ui/core";
import { Email } from "@material-ui/icons";
import { SlideLeft } from "./transitions";
import { Restore } from "../utils/restore";
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";
import { Logging } from "src/utils/logging";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Storage from "src/utils/storage";
import RenderUtils from "src/render_utils";
import { sa_event } from "src/utils/analytics";
import JSZip from "jszip";
import environment from "src/environment";
import { t } from "i18next";
import { badWordFilter } from "src/utils/badWords";
/**
 * The props for the BugReportModal
 */
interface BugReportProps extends WithTranslation {
    readonly trigger: any;
}

/**
 * The state for the BugReportModal
 */
interface BugReportState {
    open: boolean;
    email: string;
    description: string;
    textEmpty: boolean;
    submitted: boolean;
    uploading: boolean;
    screenshot: string;
    error: string;
    takingScreenshot: boolean;
}

/**
 * Displays a modal that allows sending a bug report.
 */
class BugReportModal extends React.Component<BugReportProps, BugReportState> {
    constructor(props: BugReportProps) {
        super(props);
        this.state = {open: false, email: '', description: '', textEmpty: true, submitted: false, screenshot: '', uploading: false, error: '', takingScreenshot: false};
    }

    /**
     * We do the screenshot before the dialog is opened.
     */
    open = () => {
        this.setState({takingScreenshot: true})
        setTimeout(async () => {
            const screenshotCanvas = await html2canvas(document.body);
            this.setState({open: true, submitted: false, screenshot: screenshotCanvas.toDataURL(), takingScreenshot: false});
        }, 250);
        sa_event('feedback_open');
    };

    close = () => {
        this.setState({open: false});
    };

    handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({email: event.target.value});
    };

    handleChangeDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({description: event.target.value, textEmpty: event.target.value === ''});
    };

    /**
     * Set submitted to false and close dialog
     */
    submit = () => {
        const {email, description} = this.state;

        if(this.spamtestFrequency() === false){
            notifyUser(t('BugReportModal.NOTIFY_FREQ_ERROR'), {variant: 'error'})
            this.setState({error: t('BugReportModal.NOTIFY_ERROR'), uploading: false})
        }
        if(this.spamtestDescription(description)){
            this.sendBugReport(email, description)
        }
        else {
            notifyUser(t('BugReportModal.NOTIFY_SUCCESS'), {variant: 'success'});
            this.close();
        }
    };

    /**
     * Tests the description for spam content such as bad words or too many emojis
     * use session storage to save the timestamps in ms from the last 3 submitted bugreports
     * if there are more than 3 bugreports dont submit the report
     */
    spamtestDescription(description: string){
        if (description.match(/\p{Emoji_Presentation}{3,3}/ug) !== null){
            return false
        }
        console.warn("before reading file")
        let badwords = badWordFilter.badwords
        console.warn(badwords)
        for(let i = 0; i < badwords.length; i = i+1){
            if(description.includes(badwords[i])){
                console.warn("badword")
                return false;
            }
        }
        console.warn("all good spamtestdesc")
        return true
    }

        /**
     * Tests the description for spam content such as bad words or too many emojis
     * use session storage to save the timestamps in ms from the last 3 submitted bugreports
     * if there are more than 3 bugreports dont submit the report
     */
    spamtestFrequency(){
        let timestamps = sessionStorage.getItem("spamFilterList")?.split(',')
        let count = 0
        if(timestamps){
            for(let i = 0; i <= 2; i++){
                    if(Number(timestamps[i]) < (Date.now()-(5*60*1000))){ // 5*60*1000 5 min in ms
                        timestamps[i] = Date.now().toString()
                        let newtimestamps = timestamps[0] + ',' + timestamps[1] + ',' + timestamps[2]
                        sessionStorage.setItem("spamFilterList", newtimestamps)
                        break;
                    }
                    else
                        count = count + 1
            }
            if(count === 3){
                return false
            }
            else
                return true
        }
        return true
    }

    async sendBugReport(email: string, description: string) {

        this.setState({uploading: true, error: ''})
        const {t} = this.props;
        const renderUtils = (window as any).renderUtils as RenderUtils;
        const levelManager = (window as any).levelManager;
        const stageCanvas = renderUtils.renderer.extract.canvas(renderUtils.world.container);
        const stageImage = stageCanvas.toDataURL();
        Restore.shrinkRestorePoints();
        const content = {
            replyTo: email,
            message: description,
            logs: Logging.getLogs(),
            restoreManager: Storage.get('RestoreManager'),
            stage: stageImage
        };

        try {           
            const newZip = new JSZip()

            newZip.file("details.json", JSON.stringify(content));
            newZip.file("level.cubi", levelManager.exportFile());
            const newZipImages = newZip.folder("images")
            newZipImages?.file("stage.png", await(await fetch(stageImage)).blob())
            newZipImages?.file("screen.png", await(await fetch(this.state.screenshot)).blob())
            const zipContent = await newZip.generateAsync({type:"blob"})

            const resp = await fetch(environment.BUG_REPORT_URL, {
                method: 'POST',
                body: JSON.stringify({ filesize: zipContent.size, description, email }),
                headers: {
                    'Content-Type': 'application/json'
                }
            })

            const signedResp = await resp.json();
            const respUpload = await fetch(signedResp.uploadUrl, {
                method: 'PUT',
                body: zipContent
            })

            this.setState({uploading: false})

            const status = respUpload.status;
            if (status < 400) {
                this.setState({submitted: true})
                notifyUser(t('BugReportModal.NOTIFY_SUCCESS'), {variant: 'success'});
                this.close();
            } else {
                this.setState({error: t('BugReportModal.NOTIFY_ERROR')})
            }
        } catch(err) {
            Logging.error(err)
            this.setState({error: t('BugReportModal.NOTIFY_ERROR'), uploading: false})
        }
    }

    render() {
        const {t} = this.props;
        return ([
            (this.state.takingScreenshot) ? <CircularProgress disableShrink={true} size={20} style={{marginRight: '20px'}} /> : React.cloneElement(this.props.trigger, {key: "1", onClick: this.open}),
            (
                <Dialog
                    key="2"
                    open={this.state.open}
                    onClose={this.close}
                    TransitionComponent={SlideLeft}
                    maxWidth={'md'}
                    fullWidth={true}
                >
                    <DialogTitle id="form-dialog-title">{t('BugReportModal.title')}</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus={true}
                            id="bug-email"
                            label={t('BugReportModal.EMailFieldLabel')}
                            value={this.state.email}
                            onChange={this.handleChangeEmail}
                            fullWidth={true}
                            disabled={this.state.uploading}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Email
                                            style={{color: '#3f51b5'}}
                                        />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <p>{t('BugReportModal.text')}</p>
                        <TextField
                            autoFocus={true}
                            multiline={true}
                            rows={5}
                            rowsMax={Infinity}
                            id="bug-description"
                            label={t('BugReportModal.DescriptionFieldLabel')}
                            value={this.state.description}
                            onChange={this.handleChangeDescription}
                            fullWidth={true}
                            required={true}
                            disabled={this.state.uploading}
                        />
                        {this.state.uploading && <LinearProgress />}
                        {this.state.error !== '' && <Typography color={'error'} align={'center'} style={{marginTop: '20px'}}>{this.state.error}</Typography>}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.close} color="primary" disabled={this.state.uploading}>
                            {t('Cancel')}
                        </Button>
                        <Button onClick={this.submit} color="primary" 
                                disabled={this.state.textEmpty || this.state.uploading}>
                            {t('Senden')}
                        </Button>
                    </DialogActions>
                </Dialog>
            )
        ]);
    }
}

export default withTranslation()(BugReportModal);