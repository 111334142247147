import { alpha, Avatar, InputBase, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    spriteName: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.black, 0.15),
        transition: 'background-color 0.3s',
        marginLeft: 0,
        padding: 0,
        width: "100%",
        display: "inline-block",
    },
    spriteNameWithHover: {
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.black, 0.05)
        },
    },
    spriteNameIcon: {
        width: theme.spacing(5),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 5),
        transition: theme.transitions.create('width'),
        width: '100%',
        maxWidth: 100,
    },
    spriteAvatar: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    spriteAction: {
        width: theme.spacing(5),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
}));

export function SpriteEditorSpriteName(props: any) {
    const classes = useStyles();
    const {value, onChange, onBlur, avatarSrc, actionElement, placeholder} = props;

    return (
        <div className={`${classes.spriteName} ${classes.spriteNameWithHover}`}>
            {avatarSrc !== undefined && <div className={classes.spriteNameIcon}>
                <Avatar src={avatarSrc} className={classes.spriteAvatar}/>
            </div>}
            <InputBase
                placeholder={placeholder}
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                }}
                onChange={onChange}
                value={value}
                onBlur={onBlur}
            />
            {actionElement}
        </div>
    );
}
