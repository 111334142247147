import CodeRunner from "../interpreter";
import Sprite from "../sprite";

export default class Graphics {

    private _interpreter: CodeRunner;

    constructor(interpreter: CodeRunner) {
        this._interpreter = interpreter;
    }

    /**
     * Switches to the next costume of a sprite.
     * Asks for a new frame.
     * @param sprite
     */
    nextCostume(sprite: Sprite) {
        sprite.nextCostume();
        this._interpreter.newFrame();
    }

    /**
     * Switches to the previous costume of a sprite.
     * Asks for a new frame.
     * @param sprite
     */
    previousCostume(sprite: Sprite) {
        sprite.previousCostume();
        this._interpreter.newFrame();
    }

    /**
     * Switches the costume of a sprite by the costumeID.
     * A costume with this ID must exist on the sprite.
     * Asks for a new frame.
     * @param sprite
     * @param id
     */
    setCostume(sprite: Sprite, id: number) {
        const costume = sprite.costumes.find((c) => c.id === id);
        if (costume) {
            sprite.costumeId = costume.id;
            sprite.updateCostume();
            this._interpreter.newFrame();
        }
    }

    /**
     * Switches a sprites visibility.
     * Asks for a new frame.
     * @param sprite
     * @param visible
     */
    setVisibility(sprite: Sprite, visible: boolean) {
        sprite.visible = visible;
        if (!visible) {
            sprite.clearText();
        }
        this._interpreter.newFrame();
    }

    /**
     * Scales a sprite.
     * Asks for a new frame.
     * @param sprite
     * @param value
     */
    changeSize(sprite: Sprite, value: number) {
        sprite.scale *= (1 + value / 100);
        this._interpreter.newFrame();
    }

    /**
     * Sets the scale of a sprite.
     * Asks for a new frame.
     * @param sprite
     * @param value
     */
    setSize(sprite: Sprite, value: number) {
        sprite.scale = sprite.getStartScale() * (value / 100);
        this._interpreter.newFrame();
    }

    mirrorX(sprite: Sprite) {
        sprite.mirror("X");
        this._interpreter.newFrame();
    }

    mirrorY(sprite: Sprite) {
        sprite.mirror("Y");
        this._interpreter.newFrame();
    }

    /**
     * Attaches a text-bubble indefinetly to a sprite.
     * Asks for a new frame.
     * @param sprite
     * @param text The text of the bubble.
     * @param type "speech" or "thought" to indicate the bubbletype.
     */
    attachTextPermanently(sprite: Sprite, text: string, type: "speech" | "thought") {
        if (text === '') {
            sprite.clearText();
        } else {
            sprite.attachText(text, type);
            this._interpreter.newFrame();
        }
    }

    /**
     * Attaches a text-bubble to a sprite for a specific duration.
     * Asks for a new frame.
     * @param sprite
     * @param text The text of the bubble.
     * @param type "speech" or "thought" to indicate the bubbletype.
     * @param seconds Number of seconds the bubble should be shown.
     * @param callback A callback called after seconds.
     */
    attachTextAsync(sprite: Sprite, text: string, type: "speech" | "thought", seconds: number, callback: Function) {
        sprite.attachText(text, type, false);
        this._interpreter.newFrame();
        setTimeout(() => {
            sprite.clearText();
            this._interpreter.newFrame();
            callback();
        }, seconds * 1000);
    }

    /**
     * Moves a sprite to the top layer.
     * Asks for a new frame.
     * @param sprite
     */
    toTopLayer(sprite: Sprite) {
        sprite.moveToTop();
        this._interpreter.newFrame();
    }

    /**
     * Moves a sprite a number layer down.
     * Asks for a new frame.
     * @param sprite
     * @param layers Number of layers.
     */
    moveLayersBack(sprite: Sprite, layers: number) {
        sprite.moveBack(layers);
        this._interpreter.newFrame();
    }
}
