// language=jsx

import * as React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Files from "../utils/react-files";
import {ReactFilesFile} from "react-files";

import Sprite from "../sprite";
import {WithTranslation, withTranslation} from 'react-i18next';
import {SlideUp} from "./transitions";
import {Restore, RestoreCostumeAddStatus} from "../utils/restore";
import { sa_event } from "src/utils/analytics";

interface UploadCostumeProps extends WithTranslation {
    readonly trigger: any;
    readonly sprite: Sprite;
    readonly parent: any;
}

interface UploadCostumeState {
    open: boolean;
}

class UploadCostumeModal extends React.Component<UploadCostumeProps, UploadCostumeState> {
    constructor(props: UploadCostumeProps) {
        super(props);
        this.state = {open: false};
    }

    open = () => {
        this.setState({open: true});
        this.props.parent.handleCostumeMenuClose();
    };

    close = () => {
        this.setState({open: false});
    };

    readFile = (files: ReactFilesFile[]) => {
        if (files.length > 0) {
            let name = files[0].name.slice(0, -(files[0].extension.length + 1));
            Restore.costumeAddStatus = RestoreCostumeAddStatus.COSTUME;
            this.props.sprite.addCostumeBlob(name, files[0] as Blob);
            sa_event('custom_Costume_upload')
            this.close();
        }
    };

    render() {
        const {t, trigger} = this.props;

        return ([
            React.cloneElement(trigger, {key: "1", onClick: this.open}),
            (
                <Dialog
                    key="2"
                    open={this.state.open}
                    onClose={this.close}
                    TransitionComponent={SlideUp}
                >
                    <DialogTitle id="costume-upload-dialog-slide-title">
                        {t('SpriteEditor.UploadCostumeModal.title')}
                    </DialogTitle>
                    <DialogContent>
                        <div style={{border: "dashed", borderColor: "gray"}}>
                            <Files
                                style={{height: "200px"}}
                                accepts={['image/svg+xml', 'image/png', 'image/jpeg']}
                                onChange={this.readFile}
                                maxFileSize={10000000}
                                minFileSize={0}
                                multiple={false}
                                clickable={true}
                            >
                                <div
                                    style={{
                                        padding: "0 20px 0 20px",
                                        textAlign: "center",
                                        verticalAlign: "middle",
                                        lineHeight: "200px"
                                    }}
                                >{t('SpriteEditor.UploadCostumeModal.DragDropMessage')}
                                </div>
                            </Files>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.close} color="primary">
                            {t('Cancel')}
                        </Button>
                    </DialogActions>
                </Dialog>
            )
        ]);
    }
}

export default withTranslation()(UploadCostumeModal);
