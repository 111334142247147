/***
 * Debug can hold various methods for poking around in the sandbox.
 * @constructor
 */
export default class Debug {

    /**
     * Simply prints a test-string to the console.
     * This can be used to test the connection between Blockly and this API
     * by using the testConnection-Block and checking your console for the expected output.
     */
    testConnection() {
        console.log("API-TEST successful!");
    }

    output(obj:any){
        console.error(obj);
    }
    
}