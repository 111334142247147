import * as React from 'react';
import {
    CardActions,
    CardHeader,
    CardMedia,
    FormControl,
    IconButton,
    Input,
    makeStyles,
    Tooltip,
    Typography
} from '@material-ui/core';
import { CostumeEntry } from '../../../sprite';
import DeleteIcon from '@material-ui/icons/Delete';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';

import HoveringCard from '../HoveringCard/HoveringCard';
import { useTranslation } from 'react-i18next';
import Box from "@material-ui/core/Box";

const useStyles = makeStyles({
    media: {
        verticalAlign: "bottom",
        objectFit: "contain" as any,
        flex: 1,
        overflow: "hidden",
        aspectRatio: "4 / 3"
    },
    footer:{
        display: "flex",
        width: "20px",
        textAlign: "right",
        padding: "0px 10px 0px 10px",
    },
    header: {
        padding: "10px 10px 5px 10px"
    }
});

/***
 * Props for the {@link CostumeDisplay} component.
 */
interface CostumeDisplayProps {
    /** The costume to container */
    readonly costume: CostumeEntry;
    /** Called when the delete icon is clicked */
    readonly deleteCostume?: (costume: CostumeEntry) => void;
    /** Called when the container was clicked */
    readonly onCostumeClicked?: (costume: CostumeEntry) => void;
    /** Called when the name was changed */
    readonly onCostumeNameChanged?: (costume: CostumeEntry) => void;
    /** Optional Readonly-flag which forbids editing the title and setting the deleteable state */
    readonly readonly?: boolean;
    /** Optional method called when right-clicking the costume */
    readonly setIsSystemState?: (costume: CostumeEntry) => void;
    /** Number of Costumes*/
    readonly onlyCostume?: boolean;
}

const MAX_NAME_LENGTH = 20;

/***
 * Container for displaying a costume.
 * It provides an interface for deletion and changing the name.
 * Callbacks can be injected via the component props.
 */
const CostumeDisplay: React.FC<CostumeDisplayProps> = (props) => {
    const [editName, setEditName] = React.useState(false);
    const [isSystemCostumeState, setIsSystemCostumeState] = React.useState(props.costume.isSystemCostume);

    const handleNameInputTrigger = (e?: React.MouseEvent | React.FocusEvent) => {
        if (e) {
            e.stopPropagation();
        }
        if (editName) {
            if (props.onCostumeNameChanged) {
                props.onCostumeNameChanged(props.costume);
            }
        }
        setEditName(!editName);
    };

    const catchEnterKey = (e: React.KeyboardEvent) => {
        if (editName && e.key === "Enter") {
            handleNameInputTrigger();
        }
    };

    const handleCostumeNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.length <= MAX_NAME_LENGTH) {
            props.costume.name = e.target.value;
        }
    };

    const classes = useStyles(props);
    const {t} = useTranslation();

    let titleElement;
    if (editName) {
        titleElement =
            (
                <FormControl>
                    <Input
                        multiline={true}
                        autoFocus={true}
                        onBlur={handleNameInputTrigger}
                        onKeyDown={catchEnterKey}
                        defaultValue={props.costume.name.replace(".png","")}
                        onChange={handleCostumeNameChange}
                        id="costumeName"
                        inputProps={{maxLength: MAX_NAME_LENGTH}}
                    />
                </FormControl>
            );
    } else {
        if (props.costume.isSystemCostume) {
            titleElement =
                (
                    <Typography
                        style={{wordWrap: "break-word", maxHeight: "1.75rem"}}
                        variant="subtitle1"
                    >
                        {props.costume.name.replace(".png","")}
                    </Typography>
                );

        } else {
            titleElement =
                (
                    <Tooltip title={t('CostumeDisplay.ChangeNameTooltip') as string}>
                        <Typography
                            style={{wordWrap: "break-word", maxHeight: "1.75rem"}}
                            onClick={handleNameInputTrigger}
                            variant="subtitle1"
                        >
                            {props.costume.name.replace(".png","")}
                        </Typography>
                    </Tooltip>
                );
        }

    }

    let costumeDeleteElement = [];
    /* Create delete and system buttons*/
    if (props.deleteCostume && !props.costume.isSystemCostume && !props.onlyCostume) {
        costumeDeleteElement.push(
            <Tooltip style={{marginBottom: "auto"}} title={t('CostumeDisplay.DeleteCostumeTooltip') as string}>
                <IconButton  size={'small'} onClick={() => props.deleteCostume!(props.costume)}>
                    <DeleteIcon/>
                </IconButton>
            </Tooltip>);
    }
    if (props.setIsSystemState) {
        costumeDeleteElement.push(
            <Tooltip style={{marginTop: "auto"}} title={t('CostumeDisplay.SetSystemCostumeTooltip') as string}>
                <IconButton size={'small'}
                            onClick={(event) => {
                                event.stopPropagation();
                                props.setIsSystemState!(props.costume);
                                setIsSystemCostumeState(props.costume.isSystemCostume);
                            }}>
                    {
                        isSystemCostumeState ? <LockIcon/> : <LockOpenIcon/>
                    }
                </IconButton>
            </Tooltip>
        );
    }

    return (
        <HoveringCard
            style={{height: "100%"}}
            onClick={() => props.onCostumeClicked && props.onCostumeClicked(props.costume)}>
            <CardHeader
                title={titleElement}
                className={classes.header}
            />
            <Box margin={"5px"} display={"flex"} alignItems={"space-between"} flexDirection={"row"}>
                <CardMedia
                    className={classes.media}
                    component="img"
                    src={props.costume.image.src}
                />
                <CardActions>
                    <Box display={"flex"} flexDirection={"column"} height={"100%"}>
                        {costumeDeleteElement}
                    </Box>
                </CardActions>
            </Box>
        </HoveringCard>
    );
};

export default CostumeDisplay;
