import { Workspace } from './workspace';
import { Logging } from './utils/logging';
import { sa_event } from './utils/analytics';

export default class Analytics {

    unfocusedCallback: () => void;
    inactivityCallback: () => void;
    cntClicks: number;
    isInactive: boolean;
    timerInactive: number;
    blocksDeletedCount: number;
    blocksTotalCount: number;
    projectResetCount: number;
    projectExecutionsCount: number;
    sessionStartTime: Date;
    levelSessionStartTime: Date;
    sessionID: string;
    levelSessionID: string;

    static registerInactivity() {
        return 3;
    }

    constructor() {
        // Variables for analytics
        this.sessionStartTime = new Date(Number(sessionStorage.getItem("sessionStartTime")) || Date.now());
        sessionStorage.setItem("sessionStartTime", this.sessionStartTime.getTime().toString());
        this.levelSessionStartTime = new Date(Date.now());

        this.sessionID = sessionStorage.getItem("sessionID") || crypto.randomUUID();
        sessionStorage.setItem("sessionID", this.sessionID);
        this.levelSessionID = crypto.randomUUID();
        this.projectExecutionsCount = 0;
        this.projectResetCount = 0;
        this.blocksTotalCount = 0;
        this.blocksDeletedCount = 0;
        this.timerInactive = 0;
        this.isInactive = false;
        this.cntClicks = 0;
        this.inactivityCallback = () => Logging.silly("Inactivity detected.");
        this.unfocusedCallback = () => Logging.silly("Unfocus detected.");
        this.setupEvents();
    }

    /**
     * Resets all variables except the session ID.
     * Is called whenever a level is loaded, either by the user or when the editor is fixed or the window is reloaded.
     */
    resetAll() {
        this.levelSessionStartTime = new Date(Date.now());
        this.levelSessionID = crypto.randomUUID();
        this.projectExecutionsCount = 0;
        this.projectResetCount = 0;
        this.blocksTotalCount = 0;
        this.blocksDeletedCount = 0;
        this.timerInactive = 0;
        this.isInactive = false;
        this.cntClicks = 0;
        Logging.debug("Analytics was reset.");
    }

    // Gets the exact time for the current session
    getTimeExact() {
        const timeElapsed = new Date(Date.now() - this.sessionStartTime.getTime());
        if (timeElapsed.getTimezoneOffset() !== 0) {
            return "".concat("" + (timeElapsed.getHours() - Math.abs(timeElapsed.getTimezoneOffset()) / 60), " h ",
                "" + timeElapsed.getMinutes(), " m ", "" + timeElapsed.getSeconds(), " s");
        } else {
            return "".concat("" + timeElapsed.getHours(), " h ", "" + timeElapsed.getMinutes(),
                " m ", "" + timeElapsed.getSeconds(), " s");
        }
    }

    // Gets the passed minutes for the current session
    getLevelSessionDurationInSeconds() {
        let timeElapsed = new Date(Date.now() - this.levelSessionStartTime.getTime());
        return Math.round(timeElapsed.getTime() / 1000);
    }

    getSessionDurationInSeconds() {
        let timeElapsed = new Date(Date.now() - this.sessionStartTime.getTime());
        return Math.round(timeElapsed.getTime() / 1000);

    }

    // Getter and Setter methods for variables
    getProjectExecutionsCount() {
        return this.projectExecutionsCount;
    }

    addProjectExecutionsCount() {
        this.projectExecutionsCount += 1;
    }

    getProjectResetCount() {
        return this.projectResetCount;
    }

    addProjectResetCount() {
        this.projectResetCount += 1;
    }

    getCurrentBlockCount() {
        this.blocksTotalCount = 0;
        (window as any).workspaceManager.workspaces.forEach((workspace: Workspace) => {
            if ((window as any).workspaceManager.world.sprites[workspace.id].programmable) {
                this.blocksTotalCount += workspace.blocklyWorkspace.getAllBlocks(false).filter((block: any) => block.hidden_ === false).length;
            }
        });
        return this.blocksTotalCount;
    }

    getBlocksDeletedCount() {
        return this.blocksDeletedCount;
    }

    addBlocksDeletedCount(value: number) {
        this.blocksDeletedCount += value;
    }

    reportToSimpleAnalytics() {
        sa_event('anonymous_learning_analytics', {
            levelSessionDurationInSeconds: this.getLevelSessionDurationInSeconds(),
            sessionDurationInSeconds: this.getSessionDurationInSeconds(),
            executionsCount: this.getProjectExecutionsCount(),
            resetCount: this.getProjectResetCount(),
            currentBlockCount: this.getCurrentBlockCount(),
            deletedBlockCount: this.getBlocksDeletedCount(),
            isInactive: this.isInactive,
            totalBlocks: this.getBlocksDeletedCount() + this.getCurrentBlockCount()
        });
    }

    // Handles inactivity and counter for clicks
    setupEvents() {
        setInterval(this.timerIncrement.bind(this), 20000); // 20 seconds
        setInterval(this.reportToSimpleAnalytics.bind(this), 60000); // 60 seconds
        document.addEventListener('keyup', () => this.resetInactivity());
        document.addEventListener('mousemove', () => this.resetInactivity());
        document.addEventListener('mousemove', () => this.resetInactivity());
        document.addEventListener("mouseup", () => {
            this.resetInactivity();
            this.cntClicks++;
        });

        window.addEventListener("blur", () => {
            if ((this.unfocusedCallback != null) && (this.unfocusedCallback instanceof Function)) {
                this.unfocusedCallback();
            }
        });
    }

    // Resets timer and flag for inactivity
    resetInactivity() {
        this.timerInactive = 0;
        this.isInactive = false;
    }

    // Incrementing idle time counter at each interval
    timerIncrement() {
        this.timerInactive++;
        if (this.timerInactive >= Analytics.registerInactivity() * 3) {
            if ((this.inactivityCallback != null) && (this.inactivityCallback instanceof Function)
                && (!this.isInactive)) {
                this.isInactive = true;
                this.inactivityCallback();
            }
        }
    }
}
