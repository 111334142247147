import * as ReactDOM from 'react-dom';
import './index.css';
import './theme/cubi.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import AppRoot from './app';
import i18n from './i18n/i18n';
import { I18nextProvider } from "react-i18next";
import { SnackbarProvider } from 'notistack';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

const theme = createTheme({
    palette: {
        primary: {
            main: '#37474f',
        },
        secondary: {
            main: '#0d47a1',
        },
    },
});

ReactDOM.render(
    (
        <I18nextProvider i18n={i18n}>
            <ThemeProvider theme={theme}>
                <SnackbarProvider maxSnack={5}>
                    <AppRoot />
                </SnackbarProvider>
            </ThemeProvider>
        </I18nextProvider>
    ), document.getElementById("mainContent"));

serviceWorkerRegistration.register();
