import environment from '../environment';
import { sa_event } from './analytics';

type eventType = {
    time: string,
    message: string,
}

type logType = {
    [key: string]: eventType[];
}

export abstract class Logging {
    private static initialized = false;
    private static orgConsole = window.console;
    private static notifyUser: any;

    public static initialize(fn: any) {
        if (!this.initialized) {
            this.initialized = true;
            this.notifyUser = fn;
            // @ts-ignore -> can be overridden
            window.console = {
                log: function (msg: string) {
                    Logging.log(msg);
                },
                info: function (msg: string) {
                    Logging.info(msg);
                },
                warn: function (msg: string) {
                    Logging.warn(msg);
                },
                error: function (msg: string) {
                    Logging.error(msg);
                }
            };
        }
    }

    private static logs: logType = {
        "log": [],
        "info": [],
        "warn": [],
        "error": [],
        "debug": [],
        "silly": [],
    };

    public static log(msg: string) {
        this.logs["log"].push({"time": this.getTimeStamp(), "message": msg});
        this.printToConsole("log", msg);
    }

    public static info(msg: string) {
        this.logs["info"].push({"time": this.getTimeStamp(), "message": msg});
        if (environment.fullLogging) {
            this.printToConsole("info", msg);
        }
    }

    public static warn(msg: string) {
        this.logs["warn"].push({"time": this.getTimeStamp(), "message": msg});
        this.printToConsole("warn", msg);
    }

    public static error(msg: string, notify: boolean = false) {
        // The condition filters out React warnings which are thrown as errors for some reason.
        if (!msg.toString().includes("Warning: ")) {
            sa_event("anonymous_error_counter", {
                event_message:JSON.stringify(msg, Object.getOwnPropertyNames(msg))
            })
        }

        this.logs["error"].push({"time": this.getTimeStamp(), "message": msg});
        this.printToConsole("error", msg);
        if (notify) {
            this.notifyUser(msg, {variant: 'error'});
        }
    }

    public static debug(msg: string) {
        this.logs["debug"].push({"time": this.getTimeStamp(), "message": msg});
        if (environment.fullLogging) {
            this.printToConsole("debug", msg);
        }
    }

    public static silly(msg: string) {
        this.logs["silly"].push({"time": this.getTimeStamp(), "message": msg});
        if (environment.fullLogging) {
            this.printToConsole("debug", msg);
        }
    }

    private static printToConsole(type: string, msg: string) {
        switch (type) {
            case "log":
                this.orgConsole.log(msg);
                break;
            case "info":
                this.orgConsole.info(msg);
                break;
            case "warn":
                this.orgConsole.warn(msg);
                break;
            case "error":
                this.orgConsole.error(msg);
                break;
            case "debug":
                this.orgConsole.debug(msg);
                break;
        }
    }

    private static getTimeStamp() {
        const d = new Date();
        let timeStamp = "";
        timeStamp += d.getUTCDate() + "." + d.getUTCMonth() + "." + d.getUTCFullYear();
        timeStamp += " - " + d.getUTCHours() + ":" + d.getUTCMinutes() + ":" + d.getUTCSeconds() + ":" + d.getUTCMilliseconds();
        return timeStamp;
    }

    public static getLogs() {
        return this.logs;
    }
}