import * as React from 'react';
import {Card} from '@material-ui/core';
import {CardProps} from '@material-ui/core/Card';

/**
 * Provides a Material UI card with a hover effect.
 */
const HoveringCard = React.forwardRef<unknown, CardProps>((props, ref) => {
    const [isHovering, setIsHovering] = React.useState(false);

    const style = {
        cursor: props.onClick && "pointer",
        ...props.style,
    };

    return (
        <Card
            onMouseOver={() => setIsHovering(true)}
            onMouseOut={() => setIsHovering(false)}
            raised={isHovering}
            {...props}
            style={style}
            ref={ref}
        >
            {props.children}
        </Card>
    );
});

export default HoveringCard;
