import * as React from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import WorkspaceManager, { Workspace } from '../workspace';

import { WithTranslation, withTranslation } from 'react-i18next';

import Monitor from "../monitor";
import FlatWorld from '../world';

// @ts-ignore
import * as Blockly from '@IT4Kids/cubi2-blockly';
import { SlideUp } from "./transitions";
import { Variable } from "../level-state";

/**
 * State for the New Variable Modal
 */
interface NewVariableState {
    open: boolean;
    varName: string;
    global: boolean;
    message: string|null;
    workspace: Workspace|null;
    callback?: (name: string) => any;
    type: string;
}

/**
 * The modal for creating a new variable
 */
class NewVariableModal extends React.Component<WithTranslation, NewVariableState> {
    constructor(props: WithTranslation) {
        super(props);
        this.state = {
            open: false,
            varName: "",
            global: true,
            message: null,
            workspace: null,
            type: ""
        };

        Blockly.Variables.createVariableButtonHandler = this.open;
    }

    /**
     * Changes the variable name after the user inputs it.
     * @param {React.ChangeEvent<HTMLInputElement>} event
     */
    handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({varName: event.target.value});
    };

    handleGlobalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({global: event.target.checked});
    };

    /**
     * Open dialog
     */
    open = (blocklyWorkspace: Blockly.Workspace, optCallback?: (name: string) => any, optType?: string) => {
        const workspaceManager = (window as any).workspaceManager as WorkspaceManager;
        let workspaceSvg = blocklyWorkspace as Blockly.WorkspaceSvg;
        workspaceSvg = workspaceSvg.isFlyout ? workspaceSvg.targetWorkspace : workspaceSvg;
        const workspace = workspaceManager.workspaces.find((ws) => ws && ws.blocklyWorkspace === workspaceSvg);
        if (workspace) {
            this.setState({
                open: true,
                varName: "",
                global: true,
                message: null,
                workspace: workspace,
                callback: optCallback,
                type: optType || ""
            });
        }
    };

    /**
     * Close dialog
     */
    cancel = () => {
        if (this.state.callback) {
            this.state.callback("");
        }
        this.setState({open: false});
    };

    /**
     * Close dialog
     */
    createVariable = () => {
        let newVar = this.state.varName.replace(/[\s\xa0]+/g, ' ').replace(/^ | $/g, '');
        if (this.state.varName === "" || newVar === Blockly.Msg['RENAME_VARIABLE'] || newVar === Blockly.Msg['NEW_VARIABLE']) {
            this.cancel();
            return;
        }

        const workspaceManager = (window as any).workspaceManager as WorkspaceManager;
        let existing = null;
        for (const ws of workspaceManager.workspaces) {
            existing = Blockly.Variables.nameUsedWithAnyType(newVar, ws.blocklyWorkspace);
            if (existing) {
                break;
            }
        }
        if (existing) {
            let msg = "";
            if (existing.type === this.state.type) {
                msg = Blockly.Msg['VARIABLE_ALREADY_EXISTS'].replace('%1', newVar);
            } else {
                msg = Blockly.Msg['VARIABLE_ALREADY_EXISTS_FOR_ANOTHER_TYPE'];
                msg = msg.replace('%1', newVar).replace('%2', existing.type);
            }
            this.setState({message: msg});
        } else {
            const variable: Variable = {id: Blockly.utils.genUid(), name: newVar, value: 0};
            const world = (window as any).world as FlatWorld;
            const monitor = new Monitor(variable.id, variable.name);
            world.registerMonitor(monitor, true);
            if (this.state.global) {
                workspaceManager.createVariable(variable, this.state.type);
            } else {
                this.state.workspace!.createVariable(variable, this.state.type);
            }
            if (this.state.callback) {
                this.state.callback(newVar);
            }
            this.setState({open: false});
        }
    };

    render() {
        const {t} = this.props;
        return (
            <Dialog
                open={this.state.open}
                onClose={this.cancel}
                TransitionComponent={SlideUp}
            >
                <DialogTitle id="form-dialog-title">{t('NewVariableModal.title')}</DialogTitle>
                <DialogContent>
                    {this.state.message && <b>{this.state.message}</b>}
                    <TextField
                        autoFocus={true}
                        id="variable-name"
                        label={t('NewVariableModal.NameFieldLabel')}
                        value={this.state.varName}
                        onChange={this.handleNameChange}
                        fullWidth={true}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={this.state.global}
                                onChange={this.handleGlobalChange}
                                value="global"
                            />
                        }
                        label={t('NewVariableModal.Global')}
                    />
                </DialogContent>

                <DialogActions>
                    <Button onClick={this.cancel} color="primary">
                        {t('Cancel')}
                    </Button>
                    <Button onClick={this.createVariable} color="primary">
                        {t('OK')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withTranslation()(NewVariableModal);
