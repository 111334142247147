// @ts-ignore
import * as Blockly from '@IT4Kids/cubi2-blockly';

import wsCubiTheme from './blockly.json';
import blockCategories from '../blocks.json';

export abstract class themePatcher {

    private static theme: Blockly.Theme | null = null;
    private static useColours = 2; // 1 = old theme, 2=> adds custom shadow colour; 3=> adds custom border colour

    public static loadTheme() {
        let blockStyles = wsCubiTheme["blockStyleMap"];
        let blockStylesExpanded = {};

        for (let style in blockStyles) {
            if (blockStyles.hasOwnProperty(style)) {
                let colour = blockStyles[style]["colour"];
                if (style === "events_cubi_blocks" || style === "procedure_blocks") {
                    let colours = colourFactory.findOtherColours(colour, this.useColours);
                    colours["hat"] = "cap";
                    blockStylesExpanded[style] = colours;
                } else {
                    blockStylesExpanded[style] = colourFactory.findOtherColours(colour, this.useColours);
                }
            }
        }
        this.theme = Blockly.Theme.defineTheme('cubi', {
            "blockStyles": blockStylesExpanded,
            "categoryStyles": wsCubiTheme["categoryStyleMap"],
            "componentStyles": wsCubiTheme["componentStyles"],
            "fontStyle": wsCubiTheme["fontStyle"]
        })
    }

    public static getTheme(): object | null {
        if (this.theme === null) {
            this.loadTheme();
        }
        return this.theme;
    }

    public static getCategoryStyleFromBlock(name: string): string {
        for (let categoryName in blockCategories) {
            if (blockCategories.hasOwnProperty(categoryName)) {
                let category = blockCategories[categoryName];
                if (category.blocks) {
                    for (let block of category.blocks) {
                        if (block.type === name) {
                            return categoryName.toLowerCase() + "_cubi_blocks";
                        }
                    }
                }
            }
        }
        return "";
    }
}

/*
Adapted from https://github.com/google/blockly/blob/master/theme_scripts/create_blockStyles.py
Copyright 2012 Google Inc.
https://developers.google.com/blockly/
Licensed under the Apache License, Version 2.0
 */
export abstract class colourFactory {
    private static _LIGHT_FACTOR: number = .6;
    private static _DARK_FACTOR: number = .4;

    public static findOtherColours(hex: string, c = 1): object {
        let rgb = this.hexToRgb(hex);
        let colourPrimary = this.rgbToHex(rgb);
        let colourSecondary = this.rgbToHex(this.lighten(rgb, this._LIGHT_FACTOR));
        let colourTertiary = this.rgbToHex(this.darken(rgb, this._DARK_FACTOR));
        let colours = {};
        colours["colourPrimary"] = colourPrimary;
        if (c === 2) {
            colours["colourSecondary"] = colourSecondary;
        }
        if (c === 3) {
            colours["colourTertiary"] = colourTertiary;
        }
        return colours;
    }

    private static rgbToHex(rgb: number[]): string {
        return '#' + rgb.map(x => {
            let hex = x.toString(16);
            return hex.length === 1 ? '0' + hex : hex;
        }).join('');
    }

    private static hexToRgb(hex: string): number[] {
        let step = 3 - (6 / hex.length);
        let r = Number.parseInt(hex.slice(1, 1 + step), 16);
        let g = Number.parseInt(hex.slice(1 + step, 1 + 2 * step), 16);
        let b = Number.parseInt(hex.slice(1 + 2 * step, 1 + 3 * step), 16);
        return [r, g, b];
    }

    private static lighten(rgb: number[], factor: number): number[] {
        let white = [255, 255, 255];
        return this.blend(white, rgb, factor);
    }

    private static darken(rgb: number[], factor: number): number[] {
        let black = [0, 0, 0];
        return this.blend(black, rgb, factor);
    }

    private static blend(rgb1: number[], rgb2: number[], factor: number): number[] {
        factor = Math.max(Math.min(factor, 1), 0);
        return [Math.round((rgb2[0] + (factor * (rgb1[0] - rgb2[0])))), Math.round((rgb2[1] + (factor * (rgb1[1] - rgb2[1])))), Math.round((rgb2[2] + (factor * (rgb1[2] - rgb2[2]))))];
    }
}