import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { LevelLoadModal, LevelObject, BASE_LEVEL_URL } from 'src/containers/LevelLoadModal';
import { List } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem/ListItem';
import Grid from '@material-ui/core/Grid/Grid';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import Rating from '@material-ui/lab/Rating/Rating';
import LevelManager from 'src/levels';
import i18n from "i18next";
import Storage from "src/utils/storage";
import { CloudLevel } from 'src/utils/awsCloud';
import { WithTranslation, withTranslation } from 'react-i18next';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} className={classes.tabs}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

interface ListItemsProps {
  parent: LevelLoadModal;
  levelConfig: Array<LevelObject>;
}

function ListItemsLevelOne(props: ListItemsProps) {
  const { parent, levelConfig } = props;
  let jsx: any[] = [];
  levelConfig.map((level, index) => {
    if (!level.template_exists) { return null; }
    if (level.template_exists && level.rating === 1) {
      jsx.push(<ListItem
        button={true}
        onClick={() => parent.loadURLLevel(BASE_LEVEL_URL + "Templates/" + level.filename)}
      >
        <ListItemText primary={
          <Grid container={true}>
            <Grid item={true} xs={9}>
              {level.title[i18n.resolvedLanguage] ?? level.title["en"]}
            </Grid>
            <Grid item={true} xs={3} style={{ textAlign: "right" }}>
              <Rating value={level.rating} max={3} size={"small"}
                emptyIcon={<img src={"favicon-16x16.png"} style={{ opacity: 0.3 }} alt={"Logo"} />}
                icon={<img src={"favicon-16x16.png"} alt={"Logo"} />}
                readOnly />
            </Grid>
          </Grid>
        } secondary={
          <React.Fragment>
            {level.description[i18n.resolvedLanguage] ?? level.description["en"]}
          </React.Fragment>
        } />
      </ListItem>);}
    return null;
  });
  return jsx;
}

function ListItemsLevelTwo(props: ListItemsProps) {
  const { parent, levelConfig } = props;
  let jsx: any[] = [];
  levelConfig.map((level, index) => {
    if (!level.template_exists) { return null; }
    if (level.template_exists && level.rating === 2) {
      jsx.push(<ListItem
        button={true}
        onClick={() => parent.loadURLLevel(BASE_LEVEL_URL + "Templates/" + level.filename)}
      >
        <ListItemText primary={
          <Grid container={true}>
            <Grid item={true} xs={9}>
              {level.title[i18n.resolvedLanguage] ?? level.title["en"]}
            </Grid>
            <Grid item={true} xs={3} style={{ textAlign: "right" }}>
              <Rating value={level.rating} max={3} size={"small"}
                emptyIcon={<img src={"favicon-16x16.png"} style={{ opacity: 0.3 }} alt={"Logo"} />}
                icon={<img src={"favicon-16x16.png"} alt={"Logo"} />}
                readOnly />
            </Grid>
          </Grid>
        } secondary={
          <React.Fragment>
            {level.description[i18n.resolvedLanguage] ?? level.description["en"]}
          </React.Fragment>
        } />
      </ListItem>);}
    return null;
  });
  return jsx;
}

function ListItemsLevelThree(props: ListItemsProps) {
  const { parent, levelConfig } = props;
  let jsx: any[] = [];
  levelConfig.map((level, index) => {
    if (!level.template_exists) { return null; }
    if (level.template_exists && level.rating === 3) {
      jsx.push(<ListItem
        button={true}
        onClick={() => parent.loadURLLevel(BASE_LEVEL_URL + "Templates/" + level.filename)}
      >
        <ListItemText primary={
          <Grid container={true}>
            <Grid item={true} xs={9}>
              {level.title[i18n.resolvedLanguage] ?? level.title["en"]}
            </Grid>
            <Grid item={true} xs={3} style={{ textAlign: "right" }}>
              <Rating value={level.rating} max={3} size={"small"}
                emptyIcon={<img src={"favicon-16x16.png"} style={{ opacity: 0.3 }} alt={"Logo"} />}
                icon={<img src={"favicon-16x16.png"} alt={"Logo"} />}
                readOnly />
            </Grid>
          </Grid>
        } secondary={
          <React.Fragment>
            {level.description[i18n.resolvedLanguage] ?? level.description["en"]}
          </React.Fragment>
        } />
      </ListItem>);}
    return null;
  });
  return jsx;
}

function ListItemsModules(props: ListItemsProps) {
  const { parent, levelConfig } = props;
  let jsx: any[] = [];
  levelConfig.map((level, index) => {
    if (!level.isModule) { return null; }
    jsx.push(<ListItem
      button={true}
      onClick={() => parent.loadURLLevel(BASE_LEVEL_URL + "Modules/" + level.filename)}
    >
      <ListItemText primary={
        <Grid container={true}>
          <Grid item={true} xs={9}>
            {level.title[i18n.resolvedLanguage] ?? level.title["en"]}
          </Grid>
          <Grid item={true} xs={3} style={{ textAlign: "right" }}>
            <Rating value={level.rating} max={3} size={"small"}
              emptyIcon={<img src={"favicon-16x16.png"} style={{ opacity: 0.3 }} alt={"Logo"} />}
              icon={<img src={"favicon-16x16.png"} alt={"Logo"} />}
              readOnly />
          </Grid>
        </Grid>
      } secondary={
        <React.Fragment>
          {level.description[i18n.resolvedLanguage] ?? level.description["en"]}
        </React.Fragment>
      } />
    </ListItem>);
    return null;
  });
  return jsx;
}

function ListItemsDevelopers(props: ListItemsProps) {
  const { parent, levelConfig } = props;
  let jsx: any[] = [];
  levelConfig.map((level, index) => {
    if (!level.isDeveloper) { return null; }
    jsx.push(<ListItem
      button={true}
      onClick={() => parent.loadURLLevel(BASE_LEVEL_URL + "Developer/" + level.filename)}
    >
      <ListItemText primary={
        <Grid container={true}>
          <Grid item={true} xs={9}>
            {level.title[i18n.resolvedLanguage] ?? level.title["en"]}
          </Grid>
          <Grid item={true} xs={3} style={{ textAlign: "right" }}>
            <Rating value={level.rating} max={3} size={"small"}
              emptyIcon={<img src={"favicon-16x16.png"} style={{ opacity: 0.3 }} alt={"Logo"} />}
              icon={<img src={"favicon-16x16.png"} alt={"Logo"} />}
              readOnly />
          </Grid>
        </Grid>
      } secondary={
        <React.Fragment>
          {level.description[i18n.resolvedLanguage] ?? level.description["en"]}
        </React.Fragment>
      } />
    </ListItem>);
    return null;
  });
  return jsx;
}

const isDevEmpty = ListItemsDevelopers.length === 0
console.warn(isDevEmpty)

function ListItemsProjects(props: ListItemsProps) {
  const { parent, levelConfig } = props;
  let jsx: any[] = [];
  levelConfig.map((level, index) => {
    if (!level.isProject) { return null; }
    if (level.isProject){
    jsx.push(<ListItem
      button={true}
      onClick={() => parent.loadURLLevel(BASE_LEVEL_URL + "Templates/" + level.filename)}
    >
      <ListItemText primary={
        <Grid container={true}>
          <Grid item={true} xs={9}>
            {level.title[i18n.resolvedLanguage] ?? level.title["en"]}
          </Grid>
          <Grid item={true} xs={3} style={{ textAlign: "right" }}>
            <Rating value={level.rating} max={3} size={"small"}
              emptyIcon={<img src={"favicon-16x16.png"} style={{ opacity: 0.3 }} alt={"Logo"} />}
              icon={<img src={"favicon-16x16.png"} alt={"Logo"} />}
              readOnly />
          </Grid>
        </Grid>
      } secondary={
        <React.Fragment>
          {level.description[i18n.resolvedLanguage] ?? level.description["en"]}
        </React.Fragment>
      } />
    </ListItem>);
    }
    return null;
  });
  return jsx;
}

function ListItemsSolutions(props: ListItemsProps) {
  const { parent, levelConfig } = props;

  const sortedLevels = levelConfig
    .filter(level => level.solution_exists)
    .sort((a, b) => {
      const titleA = a.title[i18n.resolvedLanguage] ?? a.title["en"];
      const titleB = b.title[i18n.resolvedLanguage] ?? b.title["en"];
      return titleA.localeCompare(titleB);
    });

  const jsx = sortedLevels.map((level, index) => (
    <ListItem
      button={true}
      onClick={() => parent.loadURLLevel(BASE_LEVEL_URL + "Solutions/" + level.filename)}
      key={index}
    >
      <ListItemText primary={
        <Grid container={true}>
          <Grid item={true} xs={9}>
            {level.title[i18n.resolvedLanguage] ?? level.title["en"]}
          </Grid>
          <Grid item={true} xs={3} style={{ textAlign: "right" }}>
            <Rating value={level.rating} max={3} size={"small"}
              emptyIcon={<img src={"favicon-16x16.png"} style={{ opacity: 0.3 }} alt={"Logo"} />}
              icon={<img src={"favicon-16x16.png"} alt={"Logo"} />}
              readOnly />
          </Grid>
        </Grid>
      } secondary={
        <React.Fragment>
          {level.description[i18n.resolvedLanguage] ?? level.description["en"]}
        </React.Fragment>
      } />
    </ListItem>
  ));

  return jsx;
}

function ListItemsTutorials(props: ListItemsProps) {
  const { parent, levelConfig } = props;
  let jsx: any[] = [];
  levelConfig.map((level, index) => {
    if (!level.tutorial_exists) { return null; }
    jsx.push(<ListItem
      button={true}
      onClick={() => parent.loadURLLevel(BASE_LEVEL_URL + "Tutorials/" + level.filename)}
    >
      <ListItemText primary={
        <Grid container={true}>
          <Grid item={true} xs={9}>
            {level.title[i18n.resolvedLanguage] ?? level.title["en"]} - Tutorial
          </Grid>
          <Grid item={true} xs={3} style={{ textAlign: "right" }}>
            <Rating value={level.rating} max={3} size={"small"}
              emptyIcon={<img src={"favicon-16x16.png"} style={{ opacity: 0.3 }} alt={"Logo"} />}
              icon={<img src={"favicon-16x16.png"} alt={"Logo"} />}
              readOnly />
          </Grid>
        </Grid>
      } secondary={
        <React.Fragment>
          {level.description[i18n.resolvedLanguage] ?? level.description["en"]}
        </React.Fragment>
      } />
    </ListItem>);
    return null;
  });
  return jsx;
}

function ListItemsCloud(props: ListItemsProps) {
  const { parent } = props;
  let jsx: any[] = [];
  let cloudLevels = Storage.get('CloudLevels', []) as CloudLevel[];
  cloudLevels = cloudLevels.reverse();

  const onClickHandler = (level: CloudLevel) => {
    return async () => {
      await parent.loadURLLevelCode(level.key)
    }
  }

  cloudLevels.map((level, index) => {
    jsx.push(<ListItem
      button={true}
      onClick={onClickHandler(level)}
    >
      <ListItemText primary={
        <Grid container={true}>
          <Grid item={true} xs={8}>
            {level.title}
          </Grid>
          <Grid item={true} xs={4} style={{ textAlign: "right" }}>
            <span style={{ fontSize: "0.75rem", fontStyle: "italic" }}>{(new Date(level.date)).toLocaleString()}</span>
          </Grid>
        </Grid>
      } secondary={
        <React.Fragment>
          {level.key}
        </React.Fragment>
      } />
    </ListItem>);
    return null;
  });
  return jsx;
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    paddingTop: "0px", 
  },
}));

interface LeveLoadModalTabsProps extends WithTranslation {
  parent: LevelLoadModal;
  levelConfig: Array<LevelObject>;
}

function LevelLoadModalTabs(props: LeveLoadModalTabsProps) {
  const classes = useStyles();
  const { parent, levelConfig, t } = props;

  let indices = [0,1,2,3,4,5,6,7];

  //Use this to get the far most right tab active:
  //const initialTab = (environment.ENV_NAME === 'dev' || Storage.get('CloudLevels')) ? Math.max.apply(null, indices) : 0;
  const initialTab = 0;
  const [value, setValue] = React.useState(initialTab);

  const handleChange = (event: React.SyntheticEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Grid  className={classes.root} container={true}>
      <Grid item={true} xs={4}>
        <Tabs  value={value} onChange={handleChange} aria-label="simple tabs example" variant="scrollable" scrollButtons="auto" orientation="vertical">
          {LevelManager.isBuildMode() && <Tab label={t('LevelLoadModal.Tabs.Solutions')} {...a11yProps(indices[0])} />}
          <Tab label={t('LevelLoadModal.Tabs.Modules')} {...a11yProps(indices[1])} />
          {!isDevEmpty && <Tab label={t('LevelLoadModal.Tabs.Developer')} {...a11yProps(indices[2])} />}
          <Tab label={t('LevelLoadModal.Tabs.LevelOne')} {...a11yProps(indices[3])} />
          <Tab label={t('LevelLoadModal.Tabs.LevelTwo')} {...a11yProps(indices[4])} />
          <Tab label={t('LevelLoadModal.Tabs.LevelThree')} {...a11yProps(indices[5])} />
          <Tab label={t('LevelLoadModal.Tabs.Projects')} {...a11yProps(indices[6])} />
          {Storage.get('CloudLevels') && <Tab label={t('LevelLoadModal.Tabs.Cloud')} {...a11yProps(indices[7])} />}
        </Tabs>
      </Grid>
      <Grid item={true} xs={8}>
        {LevelManager.isBuildMode() && (
          <TabPanel value={value} index={indices[0]}>
            <List disablePadding={true} className={"levelLoadModalList"}>
              {ListItemsSolutions({ parent, levelConfig }).map(component => component)}
            </List>
          </TabPanel>
        )}
        <TabPanel value={value} index={LevelManager.isBuildMode() ? indices[1] : indices[0]}>
          <List disablePadding={true} className={"levelLoadModalList"}>
            {ListItemsModules({ parent, levelConfig }).map(component => component)}
          </List>
        </TabPanel>
        {!isDevEmpty && (<TabPanel value={value} index={LevelManager.isBuildMode() ? indices[2] :  indices[1]}>
          <List disablePadding={true} className={"levelLoadModalList"}>
            {ListItemsDevelopers({ parent, levelConfig }).map(component => component)}
          </List>
        </TabPanel>)}
        <TabPanel value={value} index={LevelManager.isBuildMode() && !isDevEmpty ? indices[3] :  LevelManager.isBuildMode() || !isDevEmpty ?  indices[2] : indices[1]}>
          <List disablePadding={true} className={"levelLoadModalList"}>
          {[...ListItemsLevelOne({ parent, levelConfig }).map(component => component), ...ListItemsTutorials({ parent, levelConfig }).map(component => component)]}
          </List>
        </TabPanel>
        <TabPanel value={value} index={LevelManager.isBuildMode() && !isDevEmpty ? indices[4] :  LevelManager.isBuildMode() || !isDevEmpty ?  indices[3]: indices[2]}>
          <List disablePadding={true} className={"levelLoadModalList"}>
            {ListItemsLevelTwo({ parent, levelConfig }).map(component => component)}
          </List>
        </TabPanel>
        <TabPanel value={value} index={LevelManager.isBuildMode() && !isDevEmpty ? indices[5] :  LevelManager.isBuildMode() || !isDevEmpty ?  indices[4]: indices[3]}>
          <List disablePadding={true} className={"levelLoadModalList"}>
            {ListItemsLevelThree({ parent, levelConfig }).map(component => component)}
          </List>
        </TabPanel>
        <TabPanel value={value} index={LevelManager.isBuildMode() && !isDevEmpty ? indices[6] :  LevelManager.isBuildMode() || !isDevEmpty ?  indices[5]: indices[4]}>
          <List disablePadding={true} className={"levelLoadModalList"}>
            {ListItemsProjects({ parent, levelConfig }).map(component => component)}
          </List>
        </TabPanel>
        {Storage.get('CloudLevels') && (
          <TabPanel value={value} index={LevelManager.isBuildMode() && !isDevEmpty ? indices[7] :  LevelManager.isBuildMode() || !isDevEmpty ?  indices[6] : indices[5]}>
            <List disablePadding={true} className={"levelLoadModalList"}>
              {ListItemsCloud({ parent, levelConfig }).map(component => component)}
            </List>
          </TabPanel>
        )}
      </Grid>
    </Grid>
  );
}

export default withTranslation()(LevelLoadModalTabs);
