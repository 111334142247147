import * as React from 'react';
import { Grid, Typography } from '@material-ui/core';
import CostumeDisplay from '../components/CostumeDisplay/CostumeDisplay';
import { CostumeEntry } from '../../sprite';
import FolderIcon from '@material-ui/icons/Folder';
import * as Path from 'path';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import environment from '../../environment';
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next';

// Define styles for tree view
const useTreeItemStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.text.secondary,
        '&:hover > $content': {
            backgroundColor: theme.palette.action.hover,
        },
        '&:focus > $content, &$selected > $content': {
            backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
            color: 'var(--tree-view-color)',
        },
        '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
            backgroundColor: 'transparent',
        },
    },
    content: {
        color: theme.palette.text.secondary,
        borderTopRightRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        paddingRight: theme.spacing(1),
        '$expanded > &': {
            fontWeight: theme.typography.fontWeightRegular,
        },
    },
    group: {
        marginLeft: 0,
        '& $content': {
            paddingLeft: theme.spacing(2),
        },
    },
    expanded: {},
    selected: {},
    label: {
        fontWeight: 'inherit',
        color: 'inherit',
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.5, 0),
    },
    labelIcon: {
        marginRight: theme.spacing(1),
    },
    labelText: {
        fontWeight: 'inherit',
        marginRight: '10px',
    },
}));

//Props for ContentLibrary Tree
interface ContentLibraryTreeItemProps {
    [x: string]: any,

    readonly labelText: string,
    readonly labelIcon: any,
    readonly labelInfo?: string,
    readonly nodeId: any,
}

// Create styled tree view
const ContentLibraryTreeItem: React.FC<ContentLibraryTreeItemProps> = (props) => {
    const classes = useTreeItemStyles();
    const {labelText, labelIcon: LabelIcon, labelInfo, ...other} = props;

    return (
        <TreeItem
            label={
                <div className={classes.labelRoot}>
                    <LabelIcon color="inherit" className={classes.labelIcon}/>
                    <Typography variant="body2" className={classes.labelText}>
                        {labelText}
                    </Typography>
                    <Typography variant="caption" color="inherit">
                        {labelInfo}
                    </Typography>
                </div>
            }
            classes={{
                root: classes.root,
                content: classes.content,
                expanded: classes.expanded,
                selected: classes.selected,
                group: classes.group,
                label: classes.label,
            }}
            {...other}
        />
    );
};

/***
 * Props for the {@link ContentLibrary} component.
 */
interface ContentLibraryProps {
    /** The parsed content library JSON */
    readonly contentJSON: any;
    /** Called when the user clicked on a media. */
    readonly onMediaSelected: (costumeEntry: CostumeEntry) => void;
}

/***
 * Container for displaying a parsed content JSON.
 */
const ContentLibrary: React.FC<ContentLibraryProps> = (props) => {
    const {t} = useTranslation();
    const [currentPath, setCurrentPath] = React.useState(() => {
        const initialKey = Object.keys(props.contentJSON || {})[0] || '';

        return `/${initialKey === '.' ? '' : initialKey}`;
    });

    const getFromPath = React.useCallback((path: string = '/') => {
        if (path === '/') {
            return props.contentJSON;
        }
        return [props.contentJSON, ...path.split('/').filter(val => val !== '')].reduce((previousValue, currentValue) => {
            return previousValue[currentValue];
        });
    }, [props.contentJSON]);

    const getAllItems = (obj: any) => {
        let values: any[] = [];

        function traverse(obj: any) {
            for (let key in obj) {
                if (typeof obj[key] === 'object') {
                    traverse(obj[key]);
                } else {
                    values.push(obj[key]);
                }
            }
        }

        traverse(obj);
        return values;
    };

    const setPath = React.useCallback((path: string = '/') => {
        //Do we have an absolute path?
        let newPath;
        if (path.startsWith('/')) {
            newPath = path;
        } else {
            //Else join relative path to current path
            newPath = Path.join(currentPath, path);
        }
        setCurrentPath(newPath);
    }, [currentPath]);

    /***
     * Function for recursive search in json
     * Returns all renderedItems in a tree view
     */
    const recursiveSearchJson = React.useCallback((obj: object, path: string): any => {
        let x: any[] = [];
        //Search keys
        Object.keys(obj).forEach(key => {
            if (key !== '.') {
                // create new (absolut) Path
                const newPath = path + "/" + key;
                // Get the subfolders
                const newObj = getFromPath(newPath);
                // Do we have subfolders? Then use Folder Icon
                if (Object.keys(newObj).length > 1) {
                    x.push(
                        <ContentLibraryTreeItem
                            nodeId={newPath}
                            labelText={t(`LibraryCostumeModal.treeview.${key.replace(/\//g, '.')}`, key)}
                            labelIcon={FolderIcon}
                            labelInfo=""
                            onClick={() => {
                                setPath(newPath);
                            }}>
                            {recursiveSearchJson(newObj, newPath)}
                        </ContentLibraryTreeItem>
                    );
                }
                // No subfolders? Display number of costumes
                else {
                    x.push(
                        <ContentLibraryTreeItem
                            nodeId={newPath}
                            labelText={t(`LibraryCostumeModal.treeview.${key.replace(/\//g, '.')}`, key)}
                            labelIcon={PhotoLibraryIcon}
                            labelInfo={(newObj['.'] || []).length}
                            onClick={() => {
                                setPath(newPath);
                            }}/>
                    );
                }
            }
            return (<TreeItem nodeId="1" label="err"/>);
        });
        return x;
    }, [getFromPath, setPath, t]);

    const tree = React.useMemo(() => recursiveSearchJson(props.contentJSON, ""), [props.contentJSON, recursiveSearchJson]);
    const items = React.useMemo(() => getFromPath(currentPath), [currentPath, getFromPath]);
    const allSubItems = getAllItems(items);

    let renderedItems = [];

    if (currentPath.substring(0, currentPath.lastIndexOf("/")) !== "") {
        renderedItems.push(<Grid key={"back"} item={true} xs={6} md={4} lg={2} xl={2}>
            <CostumeDisplay
                readonly={true}
                costume={new CostumeEntry(t(`LibraryCostumeModal.back`), "./resources/ReplyIcon.svg")}
                onCostumeClicked={() => setPath(currentPath.substring(0, currentPath.lastIndexOf("/")))}
            /></Grid>);
    }

    renderedItems = renderedItems.concat(Object.keys(items).map((path: string, index: number) => {
        if (path === ".") {
            return <></>;
        }
        return (
            <Grid key={path} item={true} xs={6} md={4} lg={2} xl={2}>
                <CostumeDisplay
                    readonly={true}
                    costume={new CostumeEntry(t(`LibraryCostumeModal.treeview.${path.replace(/\//g, '.')}`, path), "./resources/FolderIcon.svg")}
                    onCostumeClicked={() => setPath(path)}
                />
            </Grid>);
    }));

    const costumes = allSubItems.map((costumeURL: string, index: number) => {
        return (
            <Grid key={index} item={true} xs={6} md={4} lg={2} xl={2}>
                <CostumeDisplay
                    readonly={true}
                    costume={new CostumeEntry(costumeURL.split('/').pop()?.split('.')[0] || '', Path.join(environment.CONTENT_FOLDER, costumeURL))}
                    onCostumeClicked={(costumeEntry) => props.onMediaSelected && props.onMediaSelected(costumeEntry)}
                />
            </Grid>);
    });

    if (allSubItems.length < 100) {
        renderedItems = renderedItems.concat(costumes);
    } else {
        renderedItems = renderedItems.concat((items["."] || [])
            .map((costumeURL: string, index: number) => {
                return (
                    <Grid key={index} item={true} xs={6} md={4} lg={2} xl={2}>
                        <CostumeDisplay
                            readonly={true}
                            costume={new CostumeEntry(costumeURL.split('/').pop()?.split('.')[0] || '', Path.join(environment.CONTENT_FOLDER, costumeURL))}
                            onCostumeClicked={(costumeEntry) => props.onMediaSelected && props.onMediaSelected(costumeEntry)}
                        />
                    </Grid>);
            })
        );
    }

    function getExpandedNodesForPath(str: string) {
        // Automatically generates all nodes that should be expanded to view a certain path.
        const segments = str.split('/');
        const result = [];

        // Constructing each segment progressively
        for (let i = 0; i < segments.length; i++) {
            result.push(segments.slice(0, i + 1).join('/'));
        }

        return result;
    }

    return (
        <Grid container spacing={3} alignItems="flex-start">
            {/*Outline - Treeview*/}
            {tree.length > 0 && <Grid item xs={2} style={{overflowY: 'auto', overflowX: 'hidden', minHeight: '70vh', maxHeight: '70vh'}}>
                <TreeView
                    selected={currentPath}
                    expanded={getExpandedNodesForPath(currentPath)}
                    defaultCollapseIcon={<ArrowDropDownIcon/>}
                    defaultExpandIcon={<ArrowRightIcon/>}
                    defaultEndIcon={<div style={{width: 24}}/>}
                    children={tree}
                    style={{position: 'static'}}
                />

            </Grid>}
            {/*Render Costumes*/}
            <Grid item xs style={{overflowY: 'auto', minHeight: '70vh', maxHeight: '70vh'}}>
                <Grid container={true} spacing={2}>
                    {
                        renderedItems
                    }
                </Grid>
            </Grid>
        </Grid>
    );
};
export default ContentLibrary;
