import * as React from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import environment from '../environment';
import Sprite from "../sprite";
import ContentLibrary from './ContentLibrary/ContentLibrary';
import { WithTranslation, withTranslation } from 'react-i18next';
import { SlideUp } from "./transitions";
import { Restore, RestoreCostumeAddStatus } from "../utils/restore";

interface LibraryCostumeProps extends WithTranslation {
    readonly trigger: any;
    readonly sprite: Sprite;
    readonly parent: any;
}

interface LibraryCostumeState {
    open: boolean;
    contentJSON: {
        backgrounds?: Record<string, any>,
        costumes?: Record<string, any>,
    };
}

class LibraryCostumeModal extends React.Component<LibraryCostumeProps, LibraryCostumeState> {
    constructor(props: LibraryCostumeProps) {
        super(props);
        this.state = {open: false, contentJSON: {}};
        fetch(environment.CONTENT_FOLDER + '/it4kids/content.json').then(res => res.json()).then(json => {
            this.setState({
                contentJSON: json
            });
        });
    }

    open = () => {
        this.setState({open: true});
        this.props.parent.handleCostumeMenuClose();
    };

    close = () => {
        this.setState({open: false});
    };

    select = (name: string, fileURL: string) => {
        Restore.costumeAddStatus = RestoreCostumeAddStatus.COSTUME;
        this.props.sprite.addCostumeUrl(name, fileURL);
        this.close();
    };

    render() {
        const {t} = this.props;
        const {contentJSON} = this.state;

        const relevantContent = this.props.sprite.isStaticBackground ? contentJSON.backgrounds : contentJSON.costumes

        return ([
            React.cloneElement(this.props.trigger, {key: "1", onClick: this.open}),
            (
                <Dialog
                    key="2"
                    open={this.state.open}
                    onClose={this.close}
                    TransitionComponent={SlideUp}
                    fullWidth
                    maxWidth ={'xl'}
                >
                    <DialogTitle id="form-dialog-title">{t('LibraryCostumeModal.title')}</DialogTitle>
                    <DialogContent style={{overflow: 'hidden'}}>
                        <ContentLibrary
                            contentJSON={relevantContent || {}}
                            onMediaSelected={(costumeEntry) => this.select(costumeEntry.name, costumeEntry.data)} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.close} color="primary">
                            {t('Cancel')}
                        </Button>
                    </DialogActions>
                </Dialog>
            )
        ]);
    }
}

export default withTranslation()(LibraryCostumeModal);
