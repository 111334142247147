import * as React from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';

import SettingsGroup from './components/SettingsGroup';
import SettingsItem from './components/SettingsItem';

import { WithTranslation, withTranslation } from 'react-i18next';
import FlatWorld from "../world";
import { SlideUp } from "./transitions";
import { Restore } from "../utils/restore";
import { translate } from "../i18n/i18n";
import LevelManager from '../levels';
import { SpriteEditorInstance, SpriteEditorTabs } from './SpriteEditor/SpriteEditor';
import { TutorialTabInstance } from './SpriteEditor/TutorialTab';
import TutorialSettings from "./components/TutorialSettings";
import { TutorialPage } from "src/level-state";
import Swal from "sweetalert2";

/**
 * Props for Settings Modal
 */
interface SettingsProps extends WithTranslation {
    readonly trigger: any;
    readonly parent: any;
}

/**
 * State for the Settings Modal
 */
interface SettingsState {
    open: boolean;
    centerAsOrigin: boolean;
    allowNewSprite: boolean;
    tutorialActive: boolean;
    tutorialPages: Array<TutorialPage>;
    hasUpdate: boolean;
}

/**
 * The Settings modal displays information on the current session.
 */
class SettingsModal extends React.Component<SettingsProps, SettingsState> {
    constructor(props: SettingsProps) {
        super(props);
        this.state = {
            open: false,
            allowNewSprite: true,
            centerAsOrigin: true,
            tutorialActive: true,
            tutorialPages: [],
            hasUpdate: false
        };
    }

    handleTutorialUpdate(pages: Array<TutorialPage>) {
        this.handleSettingsUpdate({ tutorialPages: pages })
    }

    handleSettingsUpdate(changeset: any) {
        this.setState({...changeset, hasUpdate: true})
    }

    /**
     * Update component
     */
    update() {
        let world = (window as any).world as FlatWorld;
        this.setState({
            allowNewSprite: LevelManager.allowNewSprite,
            centerAsOrigin: world.centerAsOrigin,
            tutorialActive: LevelManager.tutorial?.tutorialActive || false,
            tutorialPages: LevelManager.tutorial?.pages || [],
        });
    }

    /**
     * Save settings
     */
    handleSave = () => {
        const { allowNewSprite, tutorialPages, tutorialActive, centerAsOrigin } = this.state;

        let world = (window as any).world as FlatWorld;
        world.updateOrigin(centerAsOrigin);
        LevelManager.allowNewSprite = allowNewSprite;

        LevelManager.tutorial.tutorialActive = tutorialActive;
        SpriteEditorInstance.INSTANCE.setState({
            tabsValue: LevelManager.tutorial.tutorialActive === true ? SpriteEditorTabs.Tutorial : SpriteEditorTabs.Costumes,
        })
        if (tutorialPages.length > 0) {
            LevelManager.tutorial.pages = tutorialPages;
            TutorialTabInstance.INSTANCE?.setState({
                currentPageIndex: 0
            });
        }

        Restore.createRestorePoint(translate('RestoreMessages.settings.origin'));
        this.setState({hasUpdate: false, open: false})
    };

    /**
     * Open dialog
     */
    open = () => {
        this.update();
        this.setState({ open: true });
    };

    /**
     * Close dialog
     */
    close = () => {
        const {t} = this.props
        const {hasUpdate} = this.state

        if(hasUpdate) {
            Swal.fire({
                text: t('Dialogs.SettingsModalConfirmDiscard'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: t('Dialogs.ButtonYes'),
                cancelButtonText: t('Dialogs.ButtonNo'),
                confirmButtonColor: '#FD5151',
                cancelButtonColor: '#00A2E8',
                heightAuto: false
            }).then((result) => {
                // If want to discard, sure. bye.
                if (result.value) {
                    this.setState({ open: false });
                }
            });
        } else {
            this.setState({ open: false });
        }
    };

    render() {
        const { t, trigger } = this.props;
        const { open, allowNewSprite, tutorialPages, tutorialActive, centerAsOrigin } = this.state;

        return ([
            React.cloneElement(trigger, { key: "1", onClick: this.open }),
            (
                <Dialog
                    key="2"
                    open={open}
                    onClose={this.close}
                    TransitionComponent={SlideUp}
                    maxWidth={"sm"}
                    fullWidth
                >
                    <DialogTitle id="form-dialog-title">{t('SettingsModal.title')}</DialogTitle>
                    <DialogContent>
                        <SettingsGroup title={t('SettingsModal.Settings.title')}>
                            <SettingsItem title={t('SettingsModal.Settings.Origin.title')}
                                description={t('SettingsModal.Settings.Origin.description')}>
                                <Checkbox
                                    onChange={() => this.handleSettingsUpdate({ centerAsOrigin: !centerAsOrigin })}
                                    checked={centerAsOrigin}
                                />
                            </SettingsItem>
                            <SettingsItem title={t('SettingsModal.Settings.AllowNewSprite.title')}
                                description={t('SettingsModal.Settings.AllowNewSprite.description')}>
                                <Checkbox
                                    onChange={() => this.handleSettingsUpdate({ allowNewSprite: !allowNewSprite })}
                                    checked={allowNewSprite}
                                />
                            </SettingsItem>
                        </SettingsGroup>
                        <SettingsGroup title={t('SettingsModal.Settings.Tutorial.title')}>
                            <SettingsItem title={t('SettingsModal.Settings.Tutorial.activateTutorial')}
                                description={t('SettingsModal.Settings.Tutorial.activateTutorialDescription')}>
                                <Checkbox
                                    onChange={() => this.handleSettingsUpdate({ tutorialActive: !tutorialActive })}
                                    checked={tutorialActive}
                                />
                            </SettingsItem>
                        </SettingsGroup>

                        <SettingsGroup title={t('SettingsModal.Settings.TutorialPages.title')}>
                            <TutorialSettings pages={tutorialPages} onChange={this.handleTutorialUpdate.bind(this)} />
                        </SettingsGroup>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleSave} color="primary">
                            {t('SettingsModal.SaveSettings')}
                        </Button>
                    </DialogActions>
                </Dialog>
            )
        ]);
    }
}

export default withTranslation()(SettingsModal);
