import * as React from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

import { WithTranslation, withTranslation } from 'react-i18next';

import { SlideUp } from "./transitions";

import LevelManager from '../levels';
import { SessionStorage } from "src/utils/storage";

interface PasswordProps extends WithTranslation {
    readonly trigger: any;
    readonly parent: any;
}

interface PasswordState {
    open: boolean;
    password: string;
}

class PasswordModal extends React.Component<PasswordProps, PasswordState> {
    constructor(props: PasswordProps) {
        super(props);
        this.state = {
            open: false,
            password: ""
        };
    }

    requestToggleUserMode = (bypass: boolean = false) => {
        if (this.state.password === "it4kids" || bypass) {
            LevelManager.toggleUserMode();
            this.props.parent.forceUpdate();
            if (!bypass) {
                this.setState({open: false});
            }
        }
    };

    handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({password: event.target.value});
    };

    open = () => {
        if (SessionStorage.get('SkipPassword')) {
            this.requestToggleUserMode(true);
        } else {
            this.setState({open: true, password: ""});
        }
    };

    cancel = () => {
        this.setState({open: false});
    };

    render() {
        const {t} = this.props;
        return ([
            React.cloneElement(this.props.trigger, {onClick: this.open}),
            <Dialog
                open={this.state.open}
                onClose={this.cancel}
                TransitionComponent={SlideUp}
            >
                <DialogTitle id="form-dialog-title">{t("PasswordModal.title")}</DialogTitle>
                <DialogContent>
                    <TextField
                        type="password"
                        autoFocus={true}
                        id="password"
                        label={t("PasswordModal.label")}
                        value={this.state.password}
                        onChange={this.handlePasswordChange}
                        fullWidth={true}
                        onKeyPress={(e => {
                            if (e.key === "Enter") {
                                this.requestToggleUserMode();
                            }
                        })}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.cancel} color="primary">
                        {t("Cancel")}
                    </Button>
                    <Button onClick={() => {
                        this.requestToggleUserMode();
                    }} color="primary">
                        {t("OK")}
                    </Button>
                </DialogActions>
            </Dialog>
        ]);
    }
}

export default withTranslation()(PasswordModal);