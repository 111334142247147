import Button, {ButtonProps} from '@material-ui/core/Button';
import * as React from 'react';

/*const styles = createStyles({
    raisedPrimary: {
        color: '#122159',
        backgroundColor: 'red',
        '&:hover': {
            backgroundColor: 'green',
            // Reset on mouse devices
            '@media (hover: none)': {
                backgroundColor: 'blue',
            },
        },
    },

    flatPrimary: {
        color: 'white',
        backgroundColor: '#00A2E8',
        '&:hover': {
            backgroundColor: '#00A2E8',
        },
    },
});*/

const IT4KidsButtonWithStyles: React.FC<ButtonProps> = (props) => {
    /*const classes = {
        ...this.props.classes,
        raisedPrimary: this.props.disabled ? undefined : this.props.classes.raisedPrimary,
        flatPrimary: this.props.disabled ? undefined : this.props.classes.flatPrimary,
    };*/

    return (
        <Button
            {...props}
            color="primary"
        />
    );
};

export default IT4KidsButtonWithStyles;
