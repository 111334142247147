/*
This module allows to find correct names for newly created objects.
TODO Consider if we use UUIDs instead.
 */

/**
 * Gets the first free ID in a list objects that have an ID.
 * @param {{id: number}[]} arr
 * @return {number} The first unused ID.
 */
export function getFreeId(arr: { id: number }[]) {
    let count = 0;
    const func = (item: { id: number }) => item.id === count;
    while (arr.find(func)) {
        count++;
    }
    return count;
}

/**
 * Gets the first free slot in an array.
 * @param {any[]} arr
 * @return {number} The index of the the free slot.
 */
export function getFreeArraySlot(arr: any[]) {
    for (let i = 0; i < arr.length; i++) {
        if (!arr[i]) {
            return i;
        }
    }
    return arr.length;
}

/**
 * Find an unused name for any object. It will attach a count integer to the name.
 * Example: If "name","name1","foo" exist and we search for "name", we will get "name2".
 * @param {{name: string}[]} arr The array of named objects.
 * @param {string} nameArg The name we want to find an unused newName for.
 * @return {string} The name, possibly suffixed with a count. "name" -> "name1".
 */
export function getFreeName(arr: { name: string }[], nameArg: string) {
    let name = nameArg;
    if (arr.find((item) => item && item.name === name)) {
        let count = 1;
        const func = (item: { name: string }) => item && item.name === name + count
        while (arr.find(func)) {
            count++;
        }
        name = name + count;
    }
    return name;
}
