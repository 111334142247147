import { Logging } from './logging';

export default class Storage {
    private static ls = localStorage;
    private static identifier = 'Cubi_';

    static get(key: string, fallback: object|undefined = undefined) {
        key = this.identifier + key;
        let value = this.ls.getItem(key);
        if (value !== null) {
            return JSON.parse(value);
        } else {
            return fallback;
        }
    }

    static set(key: string, value: Object) {
        key = this.identifier + key;
        try {
            this.ls.setItem(key, JSON.stringify(value));
            return true;
        } catch (e) {
            Logging.error(e);
            return false;
        }
    }

    static del(key: string) {
        key = this.identifier + key;
        this.ls.removeItem(key);
    }
}

export class SessionStorage {
    private static ss = sessionStorage;
    private static identifier = 'Cubi_';

    static get(key: string, fallback: object|undefined = undefined) {
        key = this.identifier + key;
        let value = this.ss.getItem(key);
        if (value !== null) {
            return JSON.parse(value);
        } else {
            return fallback;
        }
    }

    static set(key: string, value: Object) {
        key = this.identifier + key;
        try {
            this.ss.setItem(key, JSON.stringify(value));
            return true;
        } catch (e) {
            return false;
        }
    }
}