import FilesBase, {ReactFilesFile} from "react-files";

/**
 * Extends files for acceptable filetypes.
 * This is used when loading a new level to check if its a file we can load.
 */
export default class Files extends FilesBase {
    fileTypeAcceptable(file: ReactFilesFile): boolean {
        let accepts = this.props.accepts;
        if (accepts) {
            if (file.extension !== 'none') {
                if (accepts.find((acc) => (acc.charAt(0) === '.' && acc.substr(1) === file.extension)) !== undefined) {
                    return true;
                }
            }

            return super.fileTypeAcceptable(file);
        } else {
            return true;
        }
    }
}
