import * as PIXI from 'pixi.js-legacy';
import { Serializeable } from "./levels";
import FlatWorld from './world';

import { SavedState, MonitorSavedState } from './level-state';

export default class Monitor extends Serializeable {

    id: string;
    name: string;
    value: string;
    textContainer: PIXI.Container;
    textElem: PIXI.Text;
    boxElem: PIXI.Graphics;
    dragging: boolean;
    data: any;
    dragPoint: PIXI.Point;
    textWidth: number;

    static MARGIN = {x: 8, y: 4};

    static deserialize(savedState: SavedState) {
        let monitorSavedState = savedState as MonitorSavedState;

        let monitor = new Monitor(monitorSavedState.id, monitorSavedState.name);
        monitor.x = monitorSavedState.position.x;
        monitor.y = monitorSavedState.position.y;
        monitor.visible = monitorSavedState.visible;
        return monitor;
    }

    constructor(id: string, name: string) {
        super();

        this.dragging = false;
        this.dragPoint = new PIXI.Point();

        this.id = id;
        this.name = name;
        this.value = '0';
        this.textContainer = new PIXI.Container();

        this.textContainer.interactive = true;
        this.textContainer.on('mousedown', this._onPointerDown, this)
            .on('touchstart', this._onPointerDown, this)
            .on('mouseup', this._onPointerUp, this)
            .on('mouseupoutside', this._onPointerUp, this)
            .on('touchend', this._onPointerUp, this)
            .on('touchendoutside', this._onPointerUp, this)
            .on('mousemove', this._onPointerMove, this)
            .on('touchmove', this._onPointerMove, this);

        const textStyle = new PIXI.TextStyle({
            fontSize: 14,
            wordWrap: true,
            wordWrapWidth: 400,
        });

        this.textElem = new PIXI.Text("", textStyle);
        this.boxElem = new PIXI.Graphics();

        this.textElem.x = Monitor.MARGIN.x;
        this.textElem.y = Monitor.MARGIN.y;

        this.textContainer.addChild(this.boxElem);
        this.textContainer.addChild(this.textElem);

        this.textWidth = 0;

        this.visible = true;
        this.x = 0;
        this.y = 0;

        this.updateText();
    }

    setName(name: string) {
        this.name = name;
        this.updateText();
    }

    setValue(value: string) {
        this.value = value;
        this.updateText();
    }

    get visible() {
        return this.textContainer.visible;
    }

    set visible(value: boolean) {
        this.textContainer.visible = value;
    }

    get x() {
        return this.textContainer.position.x;
    }

    set x(value: number) {
        const bounds = this.getBounds();

        value = Math.max(value, 0);
        value = Math.min(value, FlatWorld.worldSize().width - bounds.width);
        value = Math.round(value);
        this.textContainer.position.x = value;
    }

    get y() {
        return -this.textContainer.position.y;
    }

    set y(value: number) {
        const bounds = this.getBounds();

        value *= -1;
        value = Math.max(value, 0);
        value = Math.min(value, FlatWorld.worldSize().height - bounds.height);
        value = Math.round(value);
        this.textContainer.position.y = value;
    }

    private updateText() {
        this.textElem.text = this.name + ": " + this.value;

        const textWidth = this.textElem.width;
        if (this.textWidth === textWidth) {
            return;
        }

        this.textWidth = textWidth;

        this.boxElem.clear();
        this.boxElem.lineStyle(3, 0xA0A0A0);
        this.boxElem.beginFill(0xFFFFFF);
        this.boxElem.drawRoundedRect(0, 0, this.textWidth + 2 * Monitor.MARGIN.x, this.textElem.height + 2 * Monitor.MARGIN.y, 8);
        this.boxElem.endFill();
    }

    getBounds() {
        return new PIXI.Rectangle(this.x, -this.y, this.textWidth + 2 * Monitor.MARGIN.x, this.textElem.height + 2 * Monitor.MARGIN.y);
    }

    /**
     * Handles a mouse/touch down event.
     * Calls external event handler {@link Sprite.onClick()}.
     *
     * @param {PIXI.InteractionEvent} event The event info.
     * @private
     */
    _onPointerDown(event: PIXI.InteractionEvent) {
        const mousePos = event.data.getLocalPosition(this.textContainer.parent);
        mousePos.y *= -1;
        if (!this.dragging) {
            this.data = event.data;
            this.dragging = true;
            this.dragPoint = mousePos;
            this.dragPoint.x -= this.x;
            this.dragPoint.y -= this.y;
        }
    }

    /**
     * Handles a mouse/touch up event.
     *
     * @param {PIXI.InteractionEvent} event The event info.
     * @private
     */
    _onPointerUp = (event: PIXI.InteractionEvent) => {
        if (this.dragging) {
            this.dragging = false;
            this.data = null;
        }
    };

    /**
     * Handles a mouse/touch move event.
     *
     * @param {PIXI.InteractionEvent} event The event info.
     * @private
     */
    _onPointerMove = (event: PIXI.InteractionEvent) => {
        if (this.dragging) {
            const newPosition = this.data.getLocalPosition(this.textContainer.parent);
            newPosition.y *= -1;
            this.x = newPosition.x - this.dragPoint.x;
            this.y = newPosition.y - this.dragPoint.y;
        }
    };

    serialize(): MonitorSavedState {
        return {
            id: this.id,
            name: this.name,
            position: {
                x: this.x,
                y: this.y
            },
            visible: this.visible,
        } as MonitorSavedState;
    }
}
