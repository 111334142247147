/**
 * All Serializeable's should extend this interface,
 * which defines all information that are serialized to/from JSON.
 * See existing components for implementation aid.
 */
export interface SavedState {
}

export interface CostumeSavedState extends SavedState {
    id: number;
    name: string;
    data: string;
    isSystemCostume: boolean;
}

export interface BackgroundSavedState extends SavedState {
    id: number;
    name: string;
    position: {
        x: number,
        y: number,
    };
    rotation: number;
    scale: number;
    mirrored: boolean;
    orientation: number;
    programmable: boolean;
    draggable: boolean;
    visible: boolean;
    costumeId: number;
    costumes: CostumeSavedState[];
}

export interface SpriteSavedState extends SavedState {
    id: number;
    name: string;
    position: {
        x: number,
        y: number,
    };
    rotation: number;
    scale: number;
    mirrored: boolean;
    orientation: number;
    programmable: boolean;
    draggable: boolean;
    deletable: boolean;
    mobileOnly: boolean;
    isInput: boolean;
    visible: boolean;
    layer: number;
    costumeId: number;
    costumes: CostumeSavedState[];
    allowCostumeEdit: boolean;
    startConfiguration: StartConfiguration;
}

export interface StartConfiguration {
    position: {
        x: number,
        y: number,
    };
    rotation: number;
    scale: number;
    mirrored: boolean;
    orientation: number;
    visible: boolean;
    layer: number;
}

export interface Variable {
    id: string;
    name: string;
    value: any;
}

export interface WorkspaceSavedState extends SavedState {
    toolbox?: string[]|undefined;
    workspacexml: string;
    variables: Variable[];
}

export interface TutorialSavedState extends SavedState {
    tutorialActive: boolean;
    currentPageIndex: number;
    pages: Array<TutorialPage>;
}

export interface TutorialPage {
    description: string;
    mediaURL: string;
}

export interface MonitorSavedState extends SavedState {
    id: string;
    name: string;
    position: {
        x: number,
        y: number,
    };
    visible: boolean;
}

export interface WorkspaceManagerSavedState extends SavedState {
    background: BackgroundSavedState;
    backgroundWorkspace: WorkspaceSavedState;
    monitors: MonitorSavedState[];
    settings: {
        centerAsOrigin: boolean;
        selectedWorkspaceID: number;
        allowNewSprite: boolean;
    };
    title: string;
    tutorial?: TutorialSavedState;
    variables: Variable[];
    workspaces: {
        sprite: SpriteSavedState;
        workspace: WorkspaceSavedState;
    }[];
}

export interface LevelSavedState {
    projectdata: WorkspaceManagerSavedState;
    softwaredata: SoftwareSavedState;
}

export interface SoftwareSavedState extends SavedState {
    version: string;
}

export enum WinStates {
    NONE,
    WON,
    LOST
}

export interface WinState {
    state: WinStates;
}
