import * as React from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { WithTranslation, withTranslation } from 'react-i18next';
import { SlideUp } from "./transitions";
import {Button, IconButton, Toolbar, Tooltip, Typography} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import {AppRoot, notifyUser} from "../app";
import {translate} from "../i18n/i18n";
import Save from "@material-ui/icons/Save";
import {loadLevelAws, showDownload} from "../utils/awsCloud";
import LevelDownloadModal from './LevelDownloadModal';

/**
 * The props for the GamemasterModal
 */
interface GamemasterProps extends WithTranslation {}

/**
 * The state for the GamemasterModal
 */
interface GamemasterState {
    open: boolean;
    url: string;
    title: string;
    buttons: boolean;
    levelAccessKey: string;
    lvlcode: string;
}

export class GamemasterModalInstance {
    public static INSTANCE : GamemasterModal;
}

/**
 * Displays a modal that allows saving the level to a local file.
 */
class GamemasterModal extends React.Component<GamemasterProps, GamemasterState> {

    constructor(props: GamemasterProps) {
        super(props);
        this.state = { open: false, url: "https://i4k.org", title: "Info Window", buttons: false, levelAccessKey: '', lvlcode: '' };
        GamemasterModalInstance.INSTANCE = this;
    }

    /**
     * Opens the modal.
     */
    public open = (URL: string, title: string, buttons: boolean, lvlcode: string) => {
            this.setState({ open: true, url: URL, title: title, buttons: buttons, lvlcode: lvlcode});
        AppRoot.INSTANCE.pauseProject();
    };

    /**
     * Closes the modal.
     */
    close = () => {
        this.setState({open: false});
        AppRoot.INSTANCE.runProject();
    };

    handleClose = () => {
        //this.setState({ open: false });
    };

    save = () => {
        showDownload('');
        this.close();
    };
    copyCode() {
        const { t } = this.props;
        navigator.clipboard.writeText(this.state.levelAccessKey)
        notifyUser(t('LevelDownloadModal.NOTIFY_CODE_COPIED'), { variant: 'success' });
    }

    render() {
        return ([
                <Dialog
                    key="2"
                    open={this.state.open}
                    onClose={this.close}
                    TransitionComponent={SlideUp}
                    fullWidth={true}
                    maxWidth={'xl'}
                >
                    <Toolbar>
                        <Typography variant="h6" style={{flex: 1}}>
                            {this.state.title}
                        </Typography>
                        <IconButton edge="end" color="inherit" onClick={this.close} aria-label="close">
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent style={{
                            height: "100vh"
                        }}>
                        <Typography
                            display={"block"}
                            align={"center"}
                            variant={'h5'}
                            style={{ cursor: "pointer", visibility: this.state.buttons ? "visible" : "hidden" }}
                            onClick={() => this.copyCode()}>
                            {this.state.levelAccessKey !== '' ? "Code:" : ""}
                            <Tooltip title={translate('LevelDownloadModal.TooltipCopyCode').toString()}>
                                <code style={{marginLeft: '20px', visibility: this.state.buttons ? "visible" : "hidden"}}>{this.state.levelAccessKey}</code>
                            </Tooltip>
                        </Typography>

                        <iframe src ={this.state.url} title={"gamemaster preview iframe"} style={{
                            height: "100%",
                            width: "100%",
                            display: "block",
                            border: "none"
                        }}>
                            <p> Your browser does not support iframes.</p>
                        </iframe>

                    </DialogContent>
                    <DialogActions>
                        <LevelDownloadModal trigger={<Button onClick={this.save} color="primary" startIcon={<Save />} style={{ visibility: this.state.buttons ? "visible" : "hidden" }}>
                            {translate('MENU.SAVE_PROJECT')}
                        </Button>} parent={this} />
                        <Button onClick={()=> {loadLevelAws(this.state.lvlcode); this.close()}} color="primary" startIcon={<Save />} style={{ visibility: this.state.buttons ? "visible" : "hidden" }}>
                            {translate('GamemasterModal.Loadlevel')}
                        </Button>
                        <Button color="inherit" onClick={this.close} aria-label="close">
                            {translate('GamemasterModal.close')}
                        </Button>
                    </DialogActions>
                </Dialog>
        ]);
    }
}

export default withTranslation()(GamemasterModal);
