import Movement from './movement';
import Graphics from './graphics';
import Control from './control';
import Sensing from './sensing';
import Debug from './debug';
import Utils from './utils';

import Input from '../input';
import RenderUtils from '../render_utils';
import CodeRunner from '../interpreter';
import EventEmitter from 'eventemitter3';
import FlatWorld from '../world';
import Sprite from "../sprite";

/*
 This module stores the canvas to interpreter interface.
 Its a simple JS-Object which is parsed during runtime and an instance
 of it is available in the interpreter under the name 'api'.

 To get an idea of how this module is used and extended, take a look at the blocks defined blocks.js.
 Specifically, you should build an understanding of how testConnection (function with no arguments)
 and move (function with arguments) work.

 If you encounter any weird behaviour, this might be down to the sandboxing of the
 interpreter. Please report an issue on Github, if necessary.
 */

export default class Api {

    utils: Utils;
    movement: Movement;
    graphics: Graphics;
    control: Control;
    sensing: Sensing;
    debug: Debug;

    constructor(input: Input, renderUtils: RenderUtils, interpreter: CodeRunner, eventEmitter: EventEmitter, world: FlatWorld) {
        this.utils = new Utils(eventEmitter, world);
        this.movement = new Movement(interpreter, renderUtils, world);
        this.graphics = new Graphics(interpreter);
        this.control = new Control(interpreter, eventEmitter, world, input);
        this.sensing = new Sensing(input, renderUtils, world);
        this.debug = new Debug();
        Sprite.renderUtils = renderUtils;
        Sprite.eventEmitter = eventEmitter;
    }
}
