import * as React from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import WorkspaceManager, { Workspace } from '../workspace';

import { WithTranslation, withTranslation } from 'react-i18next';

// @ts-ignore
import * as Blockly from '@IT4Kids/cubi2-blockly';
import { SlideUp } from "./transitions";
import { Variable } from "../level-state";

/**
 * State for the Rename Variable Modal
 */
interface RenameVariableState {
    open: boolean;
    varName: string;
    message: string | null;
    workspace?: Workspace;
    variable: Variable|null;
    callback?: (name: string) => void;
}

/**
 * The modal for renaming a variable
 */
class RenameVariableModal extends React.Component<WithTranslation, RenameVariableState> {
    constructor(props: WithTranslation) {
        super(props);
        this.state = {
            open: false,
            varName: "",
            message: null,
            variable: null
        };

        Blockly.Variables.renameVariable = this.open;
    }

    /**
     * Changes the variable name after the user inputs it.
     * @param {React.ChangeEvent<HTMLInputElement>} event
     */
    handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({varName: event.target.value});
    };

    /**
     * Open dialog
     */
    open = (blocklyWorkspace: Blockly.Workspace, blocklyVariable: Blockly.VariableModel, optCallback?: (name: string) => void) => {
        const workspaceManager = (window as any).workspaceManager as WorkspaceManager;
        let workspaceSvg = blocklyWorkspace as Blockly.WorkspaceSvg;
        workspaceSvg = workspaceSvg.isFlyout ? workspaceSvg.targetWorkspace : workspaceSvg;
        let workspace = workspaceManager.workspaces.find((ws) => ws && ws.blocklyWorkspace === workspaceSvg);
        let variable = undefined;
        if (workspace) {
            variable = workspace.variables.find((v) => v.id === blocklyVariable.getId());
        }
        if (!variable) {
            workspace = undefined;
            variable = workspaceManager.variables.find((v) => v.id === blocklyVariable.getId());
        }
        if (variable) {
            this.setState({
                open: true,
                varName: variable.name,
                message: null,
                workspace: workspace,
                variable: variable,
                callback: optCallback
            });
        }
    };

    /**
     * Close dialog
     */
    cancel = () => {
        if (this.state.callback) {
            this.state.callback("");
        }
        this.setState({open: false});
    };

    /**
     * Close dialog
     */
    renameVariable = () => {
        let newVar = this.state.varName.replace(/[\s\xa0]+/g, ' ').replace(/^ | $/g, '');
        if (this.state.varName === "" || newVar === Blockly.Msg['RENAME_VARIABLE'] || newVar === Blockly.Msg['NEW_VARIABLE']) {
            this.cancel();
            return;
        }

        const workspaceManager = (window as any).workspaceManager as WorkspaceManager;
        let existing = null;
        for (const ws of workspaceManager.workspaces) {
            existing = Blockly.Variables.nameUsedWithAnyType(newVar, ws.blocklyWorkspace);
            if (existing) {
                break;
            }
        }
        if (existing && existing.getId() !== this.state.variable!.id) {
            const msg = Blockly.Msg['VARIABLE_ALREADY_EXISTS'].replace('%1', newVar);
            this.setState({message: msg});
        } else {
            if (this.state.workspace) {
                this.state.workspace.renameVariable(this.state.variable!, newVar);
            } else {
                // global variable
                workspaceManager.renameVariable(this.state.variable!, newVar);
            }
            if (this.state.callback) {
                this.state.callback(newVar);
            }
            this.setState({open: false});
        }
    };

    render() {
        const {t} = this.props;
        return (
            <Dialog
                open={this.state.open}
                onClose={this.cancel}
                TransitionComponent={SlideUp}
            >
                <DialogTitle id="form-dialog-title">{t('RenameVariableModal.title')}</DialogTitle>
                <DialogContent>
                    {this.state.message && <b>{this.state.message}</b>}
                    <TextField
                        autoFocus={true}
                        id="variable-name"
                        label={t('RenameVariableModal.NameFieldLabel')}
                        value={this.state.varName}
                        onChange={this.handleNameChange}
                        fullWidth={true}
                    />
                </DialogContent>

                <DialogActions>
                    <Button onClick={this.cancel} color="primary">
                        {t('Cancel')}
                    </Button>
                    <Button onClick={this.renameVariable} color="primary">
                        {t('OK')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withTranslation()(RenameVariableModal);
