import React from "react";
import {TransitionProps} from "@material-ui/core/transitions";
import Slide from "@material-ui/core/Slide";

export const SlideUp = React.forwardRef<unknown, TransitionProps>((props, ref) => {
    // @ts-ignore
    return <Slide direction="up" ref={ref} {...props} />;
});

export const SlideLeft = React.forwardRef<unknown, TransitionProps>((props, ref) => {
    // @ts-ignore
    return <Slide direction="left" ref={ref} {...props} />;
});