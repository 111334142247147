import * as React from "react";

import { WithTranslation, withTranslation } from 'react-i18next';
import { Box, Button } from "@material-ui/core";
import TutorialPageEditor from "./TutorialPageEditor";
import { TutorialPage } from "src/level-state";
import { Add } from "@material-ui/icons";

/**
 * Props for TutorialSettings
 */
interface TutorialSettingsProps extends WithTranslation {
    readonly onChange: Function;
    readonly pages: Array<TutorialPage>;
}

/**
 * State for the TutorialSettings
 */
interface TutorialSettingsState { }

/**
 * The Settings modal displays information on the current session.
 */
class TutorialSettings extends React.Component<TutorialSettingsProps, TutorialSettingsState> {
    handleNewPage = () => {
        const { onChange, pages } = this.props;

        const newPages = [...pages];

        newPages.push({ mediaURL: '', description: '' });

        onChange(newPages);
    }

    handlePageUpdate = (index: number, page: TutorialPage) => {
        const { onChange, pages } = this.props;

        const newPages = [...pages];

        if (newPages[index]) {
            newPages[index] = page;
        }

        onChange(newPages);

    }

    handlePageDelete = (index: number) => {
        const { onChange, pages } = this.props;

        const newPages = [...pages];

        if (newPages[index]) {
            delete newPages[index];
        }

        onChange(newPages.filter(e => e !== null));
    }

    handlePageMove = (index: number, direction: 'up' | 'down') => {
        const { onChange, pages } = this.props;

        const newPages = [...pages];

        if (index === newPages.length - 1 && direction === 'down') return;
        if (index === 0 && direction === 'up') return;

        if (direction === 'up') {
            const before = newPages[index - 1];
            newPages[index - 1] = newPages[index];
            newPages[index] = before;
        } else {
            const after = newPages[index + 1];
            newPages[index + 1] = newPages[index];
            newPages[index] = after;
        }

        onChange(newPages);
    }

    render() {
        const { pages, t} = this.props;

        return (
            <>
                {pages.map((page, i) => (
                    <TutorialPageEditor
                        index={i}
                        page={page}
                        totalPages={pages.length}
                        handleUpdate={this.handlePageUpdate}
                        handleDelete={this.handlePageDelete}
                        handleMove={this.handlePageMove} />
                ))}
                <Box my={1} textAlign={'center'}>
                    <Button
                        startIcon={<Add />}
                        onClick={this.handleNewPage}>
                        {t('SettingsModal.Settings.TutorialPages.addPage')}
                    </Button>
                </Box>
            </>
        );
    }
}

export default withTranslation()(TutorialSettings);