import * as React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IT4KidsButtonWithStyles from "./components/Button";

import { WithTranslation, withTranslation } from 'react-i18next';

import { SlideUp } from "./transitions";
import { Table, TableBody, TableCell, TableRow, IconButton, TableHead } from "@material-ui/core";
import { Restore } from "../utils/restore";
import Swal from "sweetalert2";
import RestoreOutlined from "@material-ui/icons/RestoreOutlined";
import Backup from "@material-ui/icons/Backup";

/**
 * Props for the LevelRestoreModal.
 */
interface LevelRestoreProps extends WithTranslation {
    readonly trigger: any;
    readonly parent: any;
}

/**
 * State of the LevelRestoreModal.
 */
interface LevelRestoreState {
    open: boolean;
}

/**
 * Shows a modal allowing to load a local level or one of the default levels.
 */
class LevelRestoreModal extends React.Component<LevelRestoreProps, LevelRestoreState> {
    constructor(props: LevelRestoreProps) {
        super(props);
        this.state = {open: false};
    }

    /**
     * Show this modal.
     */
    open = () => {
        this.setState({open: true});
    };

    /**
     * Hide this modal.
     */
    close = () => {
        this.setState({open: false});
        this.props.parent.handleClose();
    };

    /**
     * Close without parent
     */
    closeWithoutParent = () => {
        this.setState({open: false});
    };

    render() {
        const {t, trigger} = this.props;

        const toDateString = (t: number): string => {
            return (new Date(t)).toLocaleString();
        }

        let tableRows = [];
        let restorePoints = Restore.getRestorePoints();
        if (restorePoints.length === 0) {
            tableRows.push(
                <TableRow>
                    <TableCell style={{padding: '2px 14px'}}>
                        {'--:--'}
                    </TableCell>
                    <TableCell style={{padding: '2px 14px'}}>{t('LevelRestoreModal.NoRestorePoints')}</TableCell>
                </TableRow>
            );
        } else {
            for (let i = restorePoints.length - 1; i >= 0; i--) {
                tableRows.push(
                    <TableRow>
                        <TableCell style={{padding: '2px 14px'}}>
                            {toDateString(restorePoints[i].time)}
                        </TableCell>
                        <TableCell style={{padding: '2px 14px'}}>{restorePoints[i].comment}</TableCell>
                        <TableCell align={"right"} style={{padding: '2px 14px'}}>
                            <IconButton onClick={() => {
                                Swal.fire({
                                    text: t('Dialogs.RestoreProjectConfirm'),
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonText: t('Dialogs.ButtonYes'),
                                    cancelButtonText: t('Dialogs.ButtonNo'),
                                    confirmButtonColor: '#FD5151',
                                    cancelButtonColor: '#00A2E8',
                                    heightAuto: false
                                }).then((result) => {
                                    if (result.value) {
                                        this.close();
                                        Restore.loadRestorePoint(i);
                                    }
                                });
                            }}><RestoreOutlined /></IconButton>
                        </TableCell>
                    </TableRow>
                );
            }
        }

        return ([
            React.cloneElement(trigger, {key: "1", onClick: () => this.open()}),
            (
                <Dialog
                    key="2"
                    open={this.state.open}
                    onClose={this.close}
                    TransitionComponent={SlideUp}
                    scroll={"paper"}
                >
                    <DialogTitle id="level-dialog-slide-title">
                        {t('LevelRestoreModal.title')}
                    </DialogTitle>
                    <DialogContent style={{overflowX: 'hidden', overflowY: 'scroll'}}>
                        <p>{t('LevelRestoreModal.LoadBackup')}</p>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{fontSize: '1rem', padding: '2px 14px'}}>
                                        {toDateString(Restore.getLastDownloadedLevelTime())}
                                    </TableCell>
                                    <TableCell style={{fontSize: '1rem', padding: '2px 14px'}}>{t('RestoreMessages.export')}</TableCell>
                                    <TableCell align={"right"} style={{fontSize: '1rem', padding: '2px 14px'}}>
                                        <IconButton onClick={() => {
                                            Swal.fire({
                                                text: t('Dialogs.LoadSavedProjectConfirm'),
                                                icon: 'warning',
                                                showCancelButton: true,
                                                confirmButtonText: t('Dialogs.ButtonYes'),
                                                cancelButtonText: t('Dialogs.ButtonNo'),
                                                confirmButtonColor: '#FD5151',
                                                cancelButtonColor: '#00A2E8',
                                                heightAuto: false
                                            }).then((result) => {
                                                if (result.value) {
                                                    this.close()
                                                    Restore.loadLastDownloadedLevel();
                                                }
                                            });
                                        }}><Backup /></IconButton>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                        </Table>
                        <p>{t('LevelRestoreModal.ChooseRestorePoint')}</p>
                        <Table style={{width: '100%'}}>
                            <TableBody>
                                {tableRows}
                            </TableBody>
                        </Table>
                    </DialogContent>
                    <DialogActions>
                        <IT4KidsButtonWithStyles onClick={this.closeWithoutParent}>
                            {t('Cancel')}
                        </IT4KidsButtonWithStyles>
                    </DialogActions>
                </Dialog>
            )
        ]);
    }
}

export default withTranslation()(LevelRestoreModal);