import Input from "../input";
import FlatWorld from "../world";
import RenderUtils from "../render_utils";
import Sprite from "../sprite";

export default class Sensing {

    private _input: Input;
    private _renderUtils: RenderUtils;
    private _world: FlatWorld;
    private _ignoredKeysStates: Object;
    private static _allKeysIdentifier: number = -1;

    /**
     * Sensing allows for collision and color checks.
     * @param input
     * @param renderUtils
     * @param world
     * @constructor
     */
    constructor(input: Input, renderUtils: RenderUtils, world: FlatWorld) {
        this._input = input;
        this._renderUtils = renderUtils;
        this._world = world;
        this._ignoredKeysStates = {};
    }

    isAnythingTouching() {
        const sprites = Sprite.getAllSprites();
        sprites.forEach(sprite => {
            Sprite.renderUtils.isTouchingEdge(sprite);
            sprite.monitoredSprites.forEach(spriteId => {
                Sprite.renderUtils.isTouchingSprite(sprite, spriteId);
            });
        });
        sprites.forEach(sprite => {
            sprite.lastPosition = [sprite.x, sprite.y];
        });
    }

    /**
     * Checks if a sprite is touching another sprite.
     * @param sprite
     * @param otherSpriteId
     * @return {boolean}
     */
    isTouchingSprite(sprite: Sprite, otherSpriteId: number) {
        return this._renderUtils.isTouchingSprite(sprite, otherSpriteId);
    }

    /**
     * Checks if a sprite is touching the mouse.
     * @param sprite
     * @return {boolean}
     */
    isTouchingMouse(sprite: Sprite) {
        return this._renderUtils.isTouchingMouse(sprite);
    }

    /**
     * Checks if a sprite is touching the edge.
     * @param sprite
     * @return {boolean}
     */
    isTouchingEdge(sprite: Sprite) {
        return this._renderUtils.isTouchingEdge(sprite);
    }

    /**
     * Check if a sprite is touching a color.
     * @param sprite
     * @param color
     * @param colorMask
     * @return {*|boolean}
     */
    isTouchingColor(sprite: Sprite, color: number, colorMask: number) {
        return this._renderUtils.isTouchingColor(sprite, color, colorMask);
    }

    /**
     * Returns the distance between the given sprites.
     * @param sprite
     * @param otherSpriteId
     * @return {number}
     */
    distanceTo(sprite: Sprite, otherSpriteId: number) {
        let otherSprite = this._world.sprites[otherSpriteId];
        let dx = otherSprite.x - sprite.x;
        let dy = otherSprite.y - sprite.y;
        return Math.sqrt(dx * dx + dy * dy);
    }

    /**
     * Returns the distance to the given edge
     * @param sprite
     * @param direction
     */
    distanceToEdge(sprite: Sprite, direction: number) {
        const stageBounds = this._renderUtils.getStageBounds();
        const spriteBounds = this._renderUtils.getBounds(sprite);
        let distance = Number.MAX_SAFE_INTEGER;
        switch (direction) {
            case 1: {
                if (stageBounds.top > spriteBounds.top) {
                    distance = Math.floor(stageBounds.top - spriteBounds.top);
                } else {
                    distance = Math.floor(spriteBounds.top - stageBounds.top);
                }
                break;
            }
            case 2: {
                if (stageBounds.bottom > spriteBounds.bottom) {
                    distance = Math.floor(stageBounds.bottom - spriteBounds.bottom);
                } else {
                    distance = Math.floor(spriteBounds.bottom - stageBounds.bottom);
                }
                break;
            }
            case 3: {
                if (stageBounds.left > spriteBounds.left) {
                    distance = Math.floor(stageBounds.left - spriteBounds.left);
                } else {
                    distance = Math.floor(spriteBounds.left - stageBounds.left);
                }
                break;
            }
            case 4: {
                if (stageBounds.right > spriteBounds.right) {
                    distance = Math.floor(stageBounds.right - spriteBounds.right);
                } else {
                    distance = Math.floor(spriteBounds.right - stageBounds.right);
                }
                break;
            }
        }
        return distance;
    }

    /**
     * Checks if a key is pressed.
     * @param key
     * @return {boolean}
     */
    isKeyDown(key: number) {
        if (this.getIgnoredKeyState(key)) {
            return false;
        }
        if (key === -1) {
            return this._input.pressedKeys.length > 0;
        }

        return this._input.pressedKeys.indexOf(key) > -1;
    }
    /**
     * Toggles ignoring a key input on/off.
     * @param option
     */
    ignoreKeyInput(key: number, option: boolean) {
        this._ignoredKeysStates[key] = option;
    }

    ignoreAllKeyInputs(option: boolean) {
        this._ignoredKeysStates[Sensing._allKeysIdentifier] = option;
    }

    getIgnoredKeyState(key: number): boolean {
        if (this._ignoredKeysStates[Sensing._allKeysIdentifier] === true) return true;
        return this._ignoredKeysStates[key] === true;
    }
}
