import * as React from "react";
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Collapse from '@material-ui/core/Collapse';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Toolbox from '../toolbox';
import WorkspaceManager from "../workspace";
import { WithTranslation, withTranslation } from 'react-i18next';
import { SlideUp } from "./transitions";
import {ListItemIcon} from "@material-ui/core";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';

interface ToolboxCreatorProps extends WithTranslation {
    readonly trigger: any;
    readonly parent: any;
}

interface ToolboxCreatorState {
    open: boolean;
    collapse: object;
    checked: string[];
    selectedCategories: string[];
    selectAll: boolean;
    selectUsed: boolean;
    selectAllUsed: boolean;
}

class ToolboxCreatorModal extends React.Component<ToolboxCreatorProps, ToolboxCreatorState> {

    constructor(props: ToolboxCreatorProps) {
        super(props);
        this.state = {
            open: false,
            collapse: {},
            checked: [],
            selectedCategories: [],
            selectAll: false,
            selectUsed: false,
            selectAllUsed: false,
        };
    }

    /**
     * Update component
     */
    update() {
        const workspaceManager = (window as any).workspaceManager as WorkspaceManager;
        let blocks: string[] = [];
        if (workspaceManager.activeWorkspace) {
            blocks = workspaceManager.activeWorkspace.toolbox.getActiveBlocks();
        }

        this.setState({
            checked: blocks
        });
    }

    /**
     * Check every block
     */
    selectAll() {
        let newChecked: string[] = [];
        Object.keys(Toolbox.blocks).forEach((category: string) => {
            newChecked = newChecked.concat(Toolbox.blocks[category].blocks.map((block: any) => block.type));
        });
        this.setState({checked: newChecked});
        this.setState({selectAll: true});
    }

    /**
     * Uncheck every block
     */
    selectNone() {
        this.setState({checked: []});
        this.setState({selectAll: false});
    }

    /**
     * Check only used blocks
     */
    selectUsed(frozen: boolean){
        const workspaceManager = (window as any).workspaceManager as WorkspaceManager;
        let usedBlocks: string[] = [];

        workspaceManager.activeWorkspace?.blocklyWorkspace.getAllBlocks(true).forEach((b: any) =>{
            if(frozen){
                if(!b.isFrozen() && !b.isHidden())
                    usedBlocks.push(b.type)
            }
            else{
                usedBlocks.push(b.type)
            }

        });

        this.setState({
            checked: usedBlocks
        });
    }

    /**
     * Open dialog
     */
    open = () => {
        this.update();
        this.setState({open: true});
    };

    /**
     * Close dialog
     */
    close = () => {
        this.setState({open: false});
        this.props.parent.handleClose();
    };

    /**
     * Handles the collapse-event for a given category
     *
     * @param {string} category The category to toggle collapse on
     */
    handleCollapse(category: string) {
        const newCollapse = Object.assign({}, this.state.collapse);
        newCollapse[category] = !this.state.collapse[category];

        this.setState({collapse: newCollapse});
    }

    /**
     * Handles the checked-event for a given block and updates the state
     *
     * @param {string} block The block to toggle checked state on
     */
    handleToggle = (block: string) => {
        this.setState({checked: this.setToggle(block)});
    };

    /**
     * Sets the toggle state of a given block and returns the updated array
     * 
     * @param {string} block The block to toggle checked state on
     * @param {boolean|null} toggled The checked state (defaults to toggle)
     * @param {string[]} checked previous checked array (defaults to state)
     * @returns {string[]} The updated checked array
     */
    setToggle = (block: string, toggled: boolean|null = null, checked: string[] = this.state.checked) => {
        const currentIndex = checked.indexOf(block);
        if (toggled === null) {
            toggled = currentIndex === -1;
        }
        const newChecked = [...checked];

        if (currentIndex === -1 && toggled) {
            newChecked.push(block);
        } else if (currentIndex !== -1 && !toggled) {
            newChecked.splice(currentIndex, 1);
        }
        return newChecked;
    };

    /**
     * Handles the checked-event for a given category and updates the state
     * 
     * @param {string} category The category to toggle checked state on
     */
    handleToggleCategory = (category: string) => {
        const {selectedCategories} = this.state;
        const currentIndex = selectedCategories.indexOf(category);
        const newSelection = [...selectedCategories];

        if (currentIndex === -1) {
            newSelection.push(category);
        } else {
            newSelection.splice(currentIndex, 1);
        }
        let newChecked = [...this.state.checked];
        //Set all block values for this category
        //Note: Caching the checked array is necessary because setting the state is asynchronous
        Toolbox.blocks[category].blocks.forEach((block: any) => newChecked = this.setToggle(block.type, currentIndex === -1, newChecked));
        this.setState({checked: newChecked, selectedCategories: newSelection});
    }

    /**
     * Retrieves display text of block
     */
    getDisplayText(blockType: string) {
        let isAdvancedType = Boolean(['functions', 'variables'].indexOf(blockType) + 1);
        if (isAdvancedType) {
            return this.props.t(`Categories.${blockType.charAt(0).toUpperCase() + blockType.slice(1)}`);
        } else {
            return this.props.t(`BlockTranslations.${blockType}.message0`).replace(/%\d+/g, '...').replace('%%', '%');
        }
    }

    /**
     * Returns the categories in the same order as in the toolbox
     */
    getCategories(){
        let categoryOrder = {
                "Events": null,
                "Movement": null,
                "Control": null,
                "Senses": null,
                "Math": null,
                "Loops": null,
                "Looks": null,
                "Advanced": null,
                "Gamemaster": null,
                "Debug": null,
            }
            for (let c in categoryOrder) {
                categoryOrder[c] = Toolbox.blocks[c];
            }
            return categoryOrder;
    }

    render() {
        const {t} = this.props;
        return ([
            React.cloneElement(this.props.trigger, {key: "1", onClick: this.open}),
            (
                <Dialog
                    key="2"
                    open={this.state.open}
                    onClose={this.close}
                    TransitionComponent={SlideUp}
                    fullWidth
                    maxWidth={"sm"}
                >
                    <DialogTitle id="form-dialog-title">{t('ToolboxCreatorModal.title')}</DialogTitle>
                    <List>
                        {/*SelectAll Button*/}
                        <ListItem button onClick={() => this.state.selectAll ? this.selectNone() : this.selectAll()}
                                  color={"primary"}>
                            <ListItemIcon>
                                {this.state.selectAll ? <CheckBoxIcon/> : <CheckBoxOutlineBlankIcon/>}
                            </ListItemIcon>
                            <ListItemText>
                                {t('ToolboxCreatorModal.SelectAll')}
                            </ListItemText>
                        </ListItem>
                        {/*Students only Button*/}
                        <ListItem button onClick={() => {
                            this.selectUsed(true)
                            this.setState({selectUsed: !this.state.selectUsed, selectAllUsed: false, selectAll: false});
                        }}>
                            <ListItemIcon>
                                {this.state.selectUsed ? <CheckBoxIcon/> : <CheckBoxOutlineBlankIcon/>}
                            </ListItemIcon>
                            <ListItemText>
                                {t('ToolboxCreatorModal.SelectUsed')}
                            </ListItemText>
                        </ListItem>
                        {/*All used Button*/}
                        <ListItem button  onClick={() => {
                            this.selectUsed(false)
                            this.setState({selectAllUsed: !this.state.selectAllUsed, selectUsed: false, selectAll: false});
                        }}>
                            <ListItemIcon>
                                {this.state.selectAllUsed ? <CheckBoxIcon/> : <CheckBoxOutlineBlankIcon/>}
                            </ListItemIcon>
                            <ListItemText>
                                {t('ToolboxCreatorModal.SelectAllUsed')}
                            </ListItemText>
                        </ListItem>
                    </List>

                    <DialogContent style = {{minHeight: '60vh', maxHeight: '60vh'}}>
                        <List>
                            {/* map every category to create collapse objects */}
                            {Object.keys(this.getCategories()).map((category: string, index) => {
                                if (Toolbox.blocks.hasOwnProperty(category) && !Toolbox.blocks[category]._isSeperator && !Toolbox.blocks[category]._hide) {
                                    let cn = "category-icon category-icon-" + category.toLowerCase();
                                    if(category === "Advanced") cn = "category-icon category-icon-functions";
                                    if(category === "Debug") cn = "";
                                    return (
                                        <div key={index}>
                                            <ListItem button={true} onClick={() => this.handleCollapse(category)}>
                                                <ListItemIcon className={cn}/>
                                                <ListItemText inset={false} primary={t(`Categories.${category}`)}/>
                                                {this.state.collapse[category] ? <KeyboardArrowUp /> :
                                                    <KeyboardArrowDown />}
                                            </ListItem>
                                            <Collapse in={this.state.collapse[category]} timeout="auto" unmountOnExit={true}>
                                                <List>
                                                  {/* Checkbox for toggling entire category */}
                                                  <ListItem
                                                      key={category}
                                                      role={undefined}
                                                      dense={true}
                                                      button={true}
                                                      onClick={this.handleToggleCategory.bind(this, category)}
                                                      style={{fontStyle: "italic", paddingLeft: "2px"}}
                                                  >
                                                      <Checkbox
                                                          checked={this.state.selectedCategories.indexOf(category) !== -1}
                                                          tabIndex={-1}
                                                          disableRipple={true}
                                                          color="primary"
                                                      />
                                                      <ListItemText primary={t('ToolboxCreatorModal.SelectAllFromCategory')}/>
                                                  </ListItem>
                                                    {/* remove duplicate block types and map every block to create checkbox objects */}
                                                    {Toolbox.blocks[category].blocks.filter((block: any, index: number, self: any) => {return self.findIndex((b: any) => (b.type === block.type)) === index})
                                                        .map((block: any) => (
                                                            <ListItem
                                                                key={block.type}
                                                                role={undefined}
                                                                dense={true}
                                                                button={true}
                                                                onClick={this.handleToggle.bind(this, block.type)}
                                                            >
                                                                <Checkbox
                                                                    checked={this.state.checked.indexOf(block.type) !== -1}
                                                                    tabIndex={-1}
                                                                    disableRipple={true}
                                                                    color="primary"
                                                                />
                                                                <ListItemText primary={this.getDisplayText(block.type)}/>
                                                            </ListItem>
                                                        ))}
                                                </List>
                                            </Collapse>
                                        </div>
                                    )
                                }
                                else return (<div/>);
                            })}
                        </List>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                const workspaceManager = (window as any).workspaceManager as WorkspaceManager;
                                if (workspaceManager.activeWorkspace) {
                                    workspaceManager.activeWorkspace.setToolbox(new Toolbox(this.state.checked));
                                }
                                this.close();
                            }}
                            color="primary"
                            disabled={this.state.checked.length === 0}
                        >{t('ToolboxCreatorModal.ConfirmToolbox')}
                        </Button>
                    </DialogActions>
                </Dialog>
            )
        ]);
    }
}

export default withTranslation()(ToolboxCreatorModal);
