import LevelManager from "../levels";

export const sa_event = function (eventName, data) {
    let analytics = window.analytics;
    const metadata = {
        sessionID: analytics.sessionID, // Stays the same for the browser session, across level reloads
        levelSessionID: analytics.levelSessionID, // Changes when loading a new level, either manually or by fixing the editor
        levelURL: LevelManager.levelURL,
        levelTitle: LevelManager.levelTitle,

    };
    Object.assign(metadata, data);
    window.sa_event(eventName, metadata);
};