import { Button, Card, CardActions, CardContent, CardMedia, IconButton, TextField, Typography } from '@material-ui/core';
import { ArrowDownward, ArrowUpward, Delete } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { TutorialPage } from 'src/level-state';

interface TutorialPageEditorProps extends WithTranslation {
    totalPages: number;
    index: number;
    page: TutorialPage,
    handleUpdate: Function;
    handleDelete: Function;
    handleMove: Function;
}

interface TutorialPageEditorState {}

class TutorialPageEditor extends React.Component<TutorialPageEditorProps, TutorialPageEditorState> {
    handlePageDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {index, page, handleUpdate} = this.props;

        const newPage = {...page};

        newPage.description = e.currentTarget.value;

        handleUpdate(index, newPage)
    }

    onImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {files} = e.target

        if(!files || files.length !== 1) return

        const {index, page, handleUpdate} = this.props;

        const reader = new FileReader()

        reader.onload = () => {
            const newPage = {
                ...page,
                mediaURL: reader.result
            }

            handleUpdate(index, newPage)
        }

        reader.readAsDataURL(files[0])
    }

    render() {
        const {totalPages, index, handleDelete, handleMove, page, t} = this.props;

        return (
        <Card style={{ display: 'flex', marginBottom: '30px', alignContent: 'stretch' }}>
            {page.mediaURL !== '' && <CardMedia image={page.mediaURL} style={{ width: 210 }} />}
            {page.mediaURL === '' && <Skeleton variant="rect" width={210} style={{height: 'auto'}} />}
            <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                <CardContent>
                    <Typography style={{fontSize: 14}} color="textSecondary" gutterBottom>
                        {t('SettingsModal.Settings.TutorialPages.page')} #{index + 1}
                    </Typography>
                    <Button variant="contained" component="label" size="small">
                        {t('SettingsModal.Settings.TutorialPages.selectImage')}
                        <input hidden accept="image/*" type="file" onChange={this.onImageChange} />
                    </Button>
                    <TextField multiline fullWidth value={page.description} onChange={this.handlePageDescriptionChange} placeholder={t('SettingsModal.Settings.TutorialPages.description')} />
                </CardContent>
                <CardActions>
                    <IconButton onClick={() => handleMove(index, 'up')} disabled={index === 0}>
                        <ArrowUpward />
                    </IconButton>
                    <IconButton onClick={() => handleMove(index, 'down')} disabled={index === totalPages - 1}>
                        <ArrowDownward />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(index)}>
                        <Delete />
                    </IconButton>
                </CardActions>
            </div>
        </Card>
        );
    }
}

export default withTranslation()(TutorialPageEditor);