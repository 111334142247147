import CodeRunner, { Runtime } from "../interpreter";
import Sprite from "../sprite";
import FlatWorld from "../world";
import RenderUtils from "../render_utils";

export default class Movement {

    private _interpreter: CodeRunner;
    private _renderUtils: RenderUtils;
    private _world: FlatWorld;

    constructor(interpreter: CodeRunner, renderUtils: RenderUtils, world: FlatWorld) {
        this._interpreter = interpreter;
        this._world = world;
        this._renderUtils = renderUtils;
    }

    /**
     * This function sets the x-position of the sprite
     * @param sprite
     * @param x
     */
    setX(sprite: Sprite, x: number) {
        sprite.setX(x);
        this._interpreter.newFrame();
    }

    /**
     * This function sets the y-position of the sprite
     * @param sprite
     * @param y
     */
    setY(sprite: Sprite, y: number) {
        sprite.setY(y);
        this._interpreter.newFrame();
    }

    /**
     * Sets the X and Y coordinate of a sprite.
     * @param sprite
     * @param x
     * @param y
     */
    goto(sprite: Sprite, x: number, y: number) {
        sprite.setX(x);
        sprite.setY(y);
        this._interpreter.newFrame();
    }

    /**
     * Gets the X-coordinate of a sprite.
     * @param sprite
     * @return {*}
     */
    getX(sprite: Sprite) {
        return sprite.x;
    }

    /**
     * Gets the Y-coordinate of a sprite.
     * @param sprite
     * @return {*}
     */
    getY(sprite: Sprite) {
        return sprite.y;
    }

    /**
     * Gets the rotation in degrees of a sprite.
     * @param sprite
     * @return {number}
     */
    getRotation(sprite: Sprite) {
        return ((sprite.rotation % 360) + 360) % 360;
    }

    /**
     * This function moves the sprite by the given vector
     * @param sprite
     * @param x
     * @param y
     */
    moveBy(sprite: Sprite, x: number, y: number) {
        sprite.setX(sprite.x + x);
        sprite.setY(sprite.y + y);
        this._interpreter.newFrame();
    }

    /**
     * This function moves a sprite (defined in canvas.js) by steps in its current direction.
     * @param sprite
     * @param steps The px to move by.
     */
    move(sprite: Sprite, steps: number) {

        const rotation = sprite.rotation * Math.PI / 180;
        sprite.setX(sprite.x + steps * Math.sin(rotation));
        sprite.setY(sprite.y + steps * Math.cos(rotation));
        this._interpreter.newFrame();
    }

    /**
     * Walks a sprite smoothly for any given steps.
     * Requires to call it in a loop, see {@link glide} and their blocks.
     * @param runtime
     * @param sprite
     * @param steps
     */
    walk(runtime: Runtime, sprite: Sprite, steps: number) {

        const rotation = sprite.rotation * Math.PI / 180;
        let now = Date.now();

        if (!runtime.data.glide) {
            const x = sprite.x + steps * Math.sin(rotation);
            const y = sprite.y + steps * Math.cos(rotation);

            const duration = Math.abs(steps) / sprite.speed;
            runtime.data.glide = {
                startTime: now,
                endTime: now + duration * 1000,
                startPos: { x: sprite.x, y: sprite.y },
                endPos: { x: x, y: y }
            };
        }

        const data = runtime.data.glide;
        if (now >= data.endTime) {
            sprite.setX(data.endPos.x);
            sprite.setY(data.endPos.y);
            this._interpreter.newFrame();
            delete runtime.data.glide;
            return true;
        }

        const dt = (Date.now() - data.startTime) / (data.endTime - data.startTime);
        sprite.setX(data.startPos.x + (data.endPos.x - data.startPos.x) * dt);
        sprite.setY(data.startPos.y + (data.endPos.y - data.startPos.y) * dt);
        this._interpreter.newFrame();

        runtime.yield();
        return false;
    }

    /**
     * Sets a sprite speed for walking.
     * @param sprite
     * @param speed
     */
    setSpeed(sprite: Sprite, speed: number) {
        sprite.speed = speed;
    }

    /**
     * This function moves a sprite (defined in canvas.js) in x and y direction regardless of its rotation.
     * @param sprite
     * @param stepsX The px to move in the x-direction
     * @param stepsY The px to move in the y-direction
     */
    moveDirectional(sprite: Sprite, stepsX: number, stepsY: number) {
        sprite.setX(sprite.x + stepsX);
        sprite.setY(sprite.y + stepsY);
        this._interpreter.newFrame();
    }

    /**
     * This function rotates the sprite (defined in canvas.js) by steps.
     * @param sprite
     * @param steps
     */
    rotate(sprite: Sprite, steps: number) {
        sprite.rotation += steps;
        this._interpreter.newFrame();
    }

    /**
     * This function rotates the sprite with animation.
     * Has to be called until returning true, just like {@link walk}
     */
    rotateAnimated(runtime: Runtime, sprite: Sprite, steps: number) {

        let now = Date.now();

        if (!runtime.data.rotation) {
            const targetRot = sprite.rotation + steps;
            const duration = Math.abs(steps) / (sprite.speed);
            runtime.data.rotation = {
                startTime: now,
                endTime: now + duration * 1000,
                startRot: sprite.rotation,
                endRot: targetRot
            };
        }

        const data = runtime.data.rotation;
        if (now >= data.endTime) {
            sprite.rotation = data.endRot % 360;
            this._interpreter.newFrame();
            delete runtime.data.rotation;
            return true;
        }

        const dt = (now - data.startTime) / (data.endTime - data.startTime);
        sprite.rotation = (data.startRot + (data.endRot - data.startRot) * dt) % 360;
        this._interpreter.newFrame();

        runtime.yield();
        return false;
    }

    /**
     * This function rotates the sprite towards the another sprite
     * @param sprite sprite to rotate
     * @param targetSprite sprite towards which to other sprites rotates
     */
    rotateToSprite(sprite: Sprite, targetSpriteid: number) {
        let targetSprite = this._world.sprites[targetSpriteid];
        const dx = targetSprite.x - sprite.x;
        const dy = targetSprite.y - sprite.y;
        sprite.rotation = (Math.atan2(dy, -dx) - Math.PI / 2) * 180 / Math.PI;
        this._interpreter.newFrame();
    }

    /**
     * This function rotates the sprite towards the mouse
     * @param sprite
     */
    rotateToMouse(sprite: Sprite) {
        let mousePos = this._renderUtils.getMousePos();
        const dx = mousePos.x - sprite.x;
        const dy = -1 * mousePos.y - sprite.y;
        sprite.rotation = (Math.atan2(dy, -dx) - Math.PI / 2) * 180 / Math.PI;

        this._interpreter.newFrame();
    }

    /**
     * Sets the rotation in degrees of a sprite.
     * @param {Sprite} sprite
     * @param rotation
     */
    setRotation(sprite: Sprite, rotation: number) {
        sprite.rotation = rotation;
        this._interpreter.newFrame();
    }

    /**
     * Glides the sprite to a point for a specfic duration
     * @param runtime
     * @param sprite
     * @param seconds Duration in seconds
     * @param x x-Coordinate.
     * @param y y-Coordinate.
     * @return {boolean}
     */
    glide(runtime: Runtime, sprite: Sprite, seconds: number, x: number, y: number) {
        let now = Date.now();

        if (!runtime.data.glide) {
            runtime.data.glide = {
                startTime: now,
                endTime: now + seconds * 1000,
                startPos: { x: sprite.x, y: sprite.y },
                endPos: { x: x, y: y }
            };
        }

        const data = runtime.data.glide;
        if (now >= data.endTime) {
            sprite.setX(data.endPos.x);
            sprite.setY(data.endPos.y);
            this._interpreter.newFrame();
            delete runtime.data.glide;
            return true;
        }

        const dt = (Date.now() - data.startTime) / (data.endTime - data.startTime);
        sprite.setX(data.startPos.x + (data.endPos.x - data.startPos.x) * dt);
        sprite.setY(data.startPos.y + (data.endPos.y - data.startPos.y) * dt);
        this._interpreter.newFrame();

        runtime.yield();
        return false;
    }

    /**
     * Glides the sprite to another sprite for a specfic duration
     * @param runtime
     * @param sprite
     * @param seconds Duration in seconds
     * @param target Target sprite
     * @return {boolean}
     */
    glideToSprite(runtime: Runtime, sprite: Sprite, seconds: number, targetSpriteid: number) {
        let target = this._world.sprites[targetSpriteid];

        return this.glide(runtime, sprite, seconds, target.x, target.y);
    }
}
