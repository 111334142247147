export default class Hashing {
    private static FNV1a_OFFSET_BASIS: number = 2166136261;

    /**
     * Generates a pseudo 64bit hash based on the FNV-1a algorithm.
     * The algorithm runs forwards (original) and backwards on the input to calculate two different hashes.
     * These hashes are then concatenated to receive a 64bit output.
     *
     * @param input
     */
    static generateHash(input: string) {
        let hashFwd = this.FNV1a_OFFSET_BASIS;
        let hashBwd = this.FNV1a_OFFSET_BASIS;
        let iFwd = 0;
        let iBwd = input.length - 1;
        while (iFwd <= input.length - 1 && iBwd >= 0) {
            hashFwd ^= input.charCodeAt(iFwd++);
            hashFwd += (hashFwd << 1) + (hashFwd << 4) + (hashFwd << 7) + (hashFwd << 8) + (hashFwd << 24);
            hashBwd ^= input.charCodeAt(iBwd--);
            hashBwd += (hashBwd << 1) + (hashBwd << 4) + (hashBwd << 7) + (hashBwd << 8) + (hashBwd << 24);
        }
        return (hashFwd >>> 0).toString(16).padStart(8, '0') + (hashBwd >>> 0).toString(16).padStart(8, '0');
    }
}