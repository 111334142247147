import * as React from 'react';
import './LoadingOverlay.css';
import {WithTranslation, withTranslation} from 'react-i18next';

import CubiLogo from '../../assets/img/cubi-animated.gif';

/***
 * Props for the {@link LoadingOverlay} component.
 */
interface LoadingOverlayProps extends WithTranslation {
    loading?: boolean;
}

/***
 * State for the {@link LoadingOverlay} component.
 */
interface LoadingOverlayState {
    loading: boolean;
}

class LoadingOverlay extends React.Component<LoadingOverlayProps, LoadingOverlayState> {

    static INSTANCE: LoadingOverlay;

    constructor(props: LoadingOverlayProps) {
        super(props);
        LoadingOverlay.INSTANCE = this;
        this.state = {
            loading: this.props.loading || false
        };
    }

    render() {
        const {t} = this.props;
        const {loading} = this.state;
        return (
            <div style={{display: loading ? 'inline' : 'none'}} id="preloader">
                <div style={{backgroundImage: `url(${CubiLogo})`}}/>
                <p>{t('LoadingOverlay.LoadingText')}</p>
            </div>
        );
    }
}

function setLoading(isLoading: boolean) {
    if (LoadingOverlay.INSTANCE) {
        LoadingOverlay.INSTANCE.setState({loading: isLoading});
    }
}

export function withLoading(config: { autoEnd: boolean } = {autoEnd: true}) {
    return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
        const originalMethod = descriptor.value;
        descriptor.value = function () {
            const alreadyLoading = LoadingOverlay.INSTANCE.state.loading;
            if (!alreadyLoading) {
                setLoading(true);
            }
            const res = originalMethod.apply(this, arguments);
            if (!alreadyLoading && config.autoEnd) {
                setLoading(false);
            }
            return res;
        };
        return descriptor;
    };
}

export function endLoading() {
    setLoading(false);
}

export default withTranslation()(LoadingOverlay);
