/**
 * Escapes XML to HTML-safe strings.
 * Sanatizes for <, >, &, ', "
 * @param {string} unsafeString The unsanatized string
 * @return {string} A sanatized string.
 */
export default function escapeXml(unsafeString: string) {
    return unsafeString.replace(/[<>&'"]/g, (c) => {
        switch (c) {
            case '<':
                return '&lt;';
            case '>':
                return '&gt;';
            case '&':
                return '&amp;';
            case '\'':
                return '&apos;';
            case '"':
                return '&quot;';
            default:
                return c;
        }
    });
}
