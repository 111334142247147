import * as React from 'react';
import { Button, Grid, MobileStepper } from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import LevelManager from 'src/levels';
import DOMPurify from 'dompurify';
import { WithTranslation, withTranslation } from 'react-i18next';

interface TutorialTabProps extends WithTranslation {
    currentPageIndex?: number;
}

interface TutorialTabState {
    currentPageIndex: number;
}

export class TutorialTabInstance {
    public static INSTANCE: TutorialTab | null;
}

export class TutorialTab extends React.Component<TutorialTabProps, TutorialTabState> {
    constructor(props: TutorialTabProps) {
        super(props);
        this.state = {
            currentPageIndex: props.currentPageIndex || LevelManager.tutorial?.currentPageIndex || 0,
        };
    }

    componentDidMount() {
        TutorialTabInstance.INSTANCE = this;
    }

    handleNext = () => {
        if (LevelManager.tutorial) {
            LevelManager.tutorial.currentPageIndex = Math.min(LevelManager.tutorial.currentPageIndex + 1, LevelManager.tutorial.pages.length - 1);
            this.setState({
                currentPageIndex: LevelManager.tutorial.currentPageIndex
            });
        }
    };

    handleBack = () => {
        if (LevelManager.tutorial) {
            LevelManager.tutorial.currentPageIndex = Math.max(LevelManager.tutorial.currentPageIndex - 1, 0);
            this.setState({
                currentPageIndex: LevelManager.tutorial.currentPageIndex
            });
        }
    };

    render() {
        const {t} = this.props;

        if (LevelManager.tutorial?.pages[this.state.currentPageIndex] === undefined ) {
            return <div>{t('SpriteEditor.TutorialTabNotFound')}</div>;
        }

        const maxPages = LevelManager.tutorial.pages.length;
        const currentDescription = LevelManager.tutorial.pages[this.state.currentPageIndex].description;
        const currentURL = LevelManager.tutorial.pages[this.state.currentPageIndex].mediaURL;

        return (
            <div>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={12} lg={7}>
                                <img
                                    style={{maxHeight: "20vh", maxWidth: "100%", display: "block", margin: "0 auto"}}
                                    src={currentURL}
                                    alt="Tutorial"/>
                        </Grid>
                        <Grid item xs={12} md={12} lg={5}>
                            {maxPages > 1 &&
                            <MobileStepper
                                variant="text"
                                steps={maxPages}
                                position="static"
                                style={{
                                    flexGrow: 1,
                                }}
                                activeStep={this.state.currentPageIndex}
                                nextButton={
                                    <Button size="small" onClick={this.handleNext} disabled={this.state.currentPageIndex === maxPages - 1}>
                                        <KeyboardArrowRight/>
                                    </Button>
                                }
                                backButton={
                                    <Button size="small" onClick={this.handleBack} disabled={this.state.currentPageIndex === 0}>
                                        <KeyboardArrowLeft/>
                                    </Button>
                                }
                            />
                            }
                            <p  style={{wordBreak: 'break-word'}} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize( currentDescription )}}/>
                        </Grid>
                    </Grid>
            </div>);

    }

}

export default withTranslation()(TutorialTab);
