import * as React from 'react';
import { Typography } from '@material-ui/core';

interface SettingsGroupProps {
    readonly title: string;
    readonly style?: any;
}

const SettingsGroup: React.FC<SettingsGroupProps> = (props) => {
    return (
        <div
            style={{
                ...props.style
            }}
        >
            <Typography variant="h6" style={{padding: '10px 0 0 0'}}>
                {props.title}
            </Typography>
            {props.children}
        </div>
    );
};

export default SettingsGroup;
