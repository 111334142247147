import Sprite from './sprite';
import WorkspaceManager from './workspace';
import FlatWorld from './world';
import { translate, translateBlock } from './i18n/i18n';
import { themePatcher } from "./theme/themePatcher";

// @ts-ignore
import * as Blockly from '@IT4Kids/cubi2-blockly';

import blockCategories from './blocks.json';
import RenderUtils from './render_utils';
import { WinStates } from './level-state';
import isMobile from 'ismobilejs';
//import Blockly from "@IT4Kids/cubi2-blockly/blockly";
//import StatementInput = Blockly.blockRendering.StatementInput;

//@ts-ignore
declare module '@IT4Kids/cubi2-blockly' {
    var Blocks: any;
    var JavaScript: any;
}

/*
 This file holds all necessary Block-definitions and their JavaScript conversion code.

 This could be written differently to automate definition and reduce mental workload.
 Check the Blockly-Docs for syntax explanation.
 */

export interface EventFilter {
    (block: Blockly.Block, sprite: Sprite, ...args: any[]): boolean;
}

interface Event {
    type: string;
    filterFunc: EventFilter;
}

Blockly.JavaScript.addReservedWords("sprite,runtime,api,getParam");

// @ts-ignore (suppress return type)
Blockly.ContextMenu.blockHelpOption = () => void 0;

/* use this to show help option in context menu
Blockly.ContextMenu.blockHelpOption = (block: Blockly.BlockSvg) => {
    return {
        enabled: false,
        text: Blockly.Msg['HELP'],
        callback: function() { }
    };
}
 */

Blockly.JavaScript.LOOP_END = 'runtime.yield();\n';

const EventBlocks: Event[] = [
    { type: "start", filterFunc: (block: Blockly.Block, sprite: Sprite, eventName: string) => true },
    { type: "receive", filterFunc: (block: Blockly.Block, sprite: Sprite, eventName: string) => eventName === block.getFieldValue('event_name') },
    {
        type: "key_pressed", filterFunc: (block: Blockly.Block, sprite: Sprite, pressedKey: number) => {
            let checkKey = Number.parseInt(block.getFieldValue('key'), 10);
            return checkKey === -1 //any key
                || pressedKey === checkKey //pressed key
                || (checkKey >= 48 && checkKey <= 57 && pressedKey === checkKey + 48); //numpad alternative
        }
    },
    {
        type: "touched", filterFunc: (block: Blockly.Block, sprite: Sprite, trigger: Sprite, event: string) => {
            let object = block.getFieldValue('object');
            if (event === "edge" && object === "edge") {
                return trigger === sprite;
            } else {
                return trigger.id === sprite.id && parseInt(event, 10) === parseInt(object.slice(7), 10);
            }
        }
    },
    { type: "clicked", filterFunc: (block: Blockly.Block, sprite: Sprite, clickedSprite: Sprite) => clickedSprite === sprite },
    {
        type: "clicked_other", filterFunc: (block: Blockly.Block, sprite: Sprite, clickedSprite: Sprite) => {
            let clicked = block.getFieldValue('object')
            return 'sprite:' + clickedSprite.id === clicked 
        }
    },
    { type: "start_clone", filterFunc: (block: Blockly.Block, sprite: Sprite, clone: Sprite) => clone === sprite },
    { type: "gameover", filterFunc: (block: Blockly.Block, sprite: Sprite, eventName: string) => eventName === block.getFieldValue('winstate') },
];

export function initBlocks() {
    for (let categoryName in blockCategories) {
        if (blockCategories.hasOwnProperty(categoryName)) {
            let category = blockCategories[categoryName];
            if (category.blocks) {
                for (let block of category.blocks) {
                    if (block._dummy) {
                        continue;
                    }
                    if (category.hasOwnProperty("style")) {
                        block.style = category.style.slice(0, -8) + "blocks";
                    }
                    block = translateBlock(block);
                    Blockly.Blocks[block.type] = {
                        init: function () {
                            this.jsonInit(block);
                        }
                    };
                }
            }
        }
    }
}

initBlocks()

function getCurrentSprite(block: Blockly.Block) {
    const workspaceManager = (window as any).workspaceManager as WorkspaceManager;
    const blockWorkspace = block.isInFlyout ? (block.workspace as Blockly.WorkspaceSvg).targetWorkspace : block.workspace;
    const workspace = workspaceManager.workspaces.find(ws => ws && ws.blocklyWorkspace === blockWorkspace);
    return workspace ? workspaceManager.getSprite(workspace) as Sprite : null;
}

function addSpriteOptions(options: [string, string][], self: Sprite | null, prefix: string = "") {
    let world = (window as any).world as FlatWorld;
    world.sprites.forEach((sprite) => {
        if (!sprite.isStaticBackground && sprite !== self) {
            options.push([sprite.name, prefix + sprite.id]);
        }
    });
}

Blockly.JavaScript['testConnection'] = function (block: Blockly.Block) {
    return 'api.debug.testConnection();\n';
};

Blockly.JavaScript['debug_any'] = function (block: Blockly.Block) {
    return block.getFieldValue('code');
};

Blockly.JavaScript['move'] = function (block: Blockly.Block) {
    let steps = Blockly.JavaScript.valueToCode(block, 'steps', Blockly.JavaScript.ORDER_COMMA) || '0';
    return "api.movement.move(sprite, " + steps + ");\n";
};

Blockly.JavaScript['walk'] = function (block: Blockly.Block) {
    let steps = Blockly.JavaScript.valueToCode(block, 'steps', Blockly.JavaScript.ORDER_COMMA) || '0';
    return 'while (!api.movement.walk(runtime.runtime(), sprite, ' + steps + ')) {}\n';
};

Blockly.JavaScript['set_speed'] = function (block: Blockly.Block) {
    let speed = Blockly.JavaScript.valueToCode(block, 'speed', Blockly.JavaScript.ORDER_COMMA) || '0';
    return 'api.movement.setSpeed(sprite, ' + speed + ');\n';

};

Blockly.JavaScript['moveDirectional'] = function (block: Blockly.Block) {
    let direction = block.getFieldValue('direction');
    const order = (direction === "left" || direction === "down") ? Blockly.JavaScript.ORDER_UNARY_NEGATION : Blockly.JavaScript.ORDER_COMMA;
    let steps = Blockly.JavaScript.valueToCode(block, 'steps', order) || '0';
    let moveX = "0", moveY = "0";

    switch (direction) {
        case "left": {
            moveX = "-" + steps;
            break;
        }
        case "right": {
            moveX = steps;
            break;
        }
        case "up": {
            moveY = steps;
            break;
        }
        case "down": {
            moveY = "-" + steps;
            break;
        }
        default:
    }

    return 'api.movement.moveDirectional(sprite, ' + moveX + ', ' + moveY + ');\n';
};

Blockly.JavaScript['rotate'] = function (block: Blockly.Block) {
    let direction = block.getFieldValue('direction');
    const order = (direction === "left") ? Blockly.JavaScript.ORDER_UNARY_NEGATION : Blockly.JavaScript.ORDER_COMMA;
    let degree = Blockly.JavaScript.valueToCode(block, 'degree', order) || '0';

    if (direction === "left") {
        degree = '-' + degree;
    }

    return 'api.movement.rotate(sprite, ' + degree + ');\n';
};

Blockly.JavaScript['rotateAnimated'] = function (block: Blockly.Block) {
    let direction = block.getFieldValue('direction');
    const order = (direction === "left") ? Blockly.JavaScript.ORDER_UNARY_NEGATION : Blockly.JavaScript.ORDER_COMMA;
    let degree = Blockly.JavaScript.valueToCode(block, 'degree', order) || '0';

    if (direction === "left") {
        degree = '-' + degree;
    }

    return 'while (!api.movement.rotateAnimated(runtime.runtime(), sprite, ' + degree + ')) {}\n';
};

Blockly.JavaScript['set_direction'] = function (block: Blockly.Block) {
    let direction = Blockly.JavaScript.valueToCode(block, 'direction', Blockly.JavaScript.ORDER_COMMA) || '0';
    return 'api.movement.setRotation(sprite, ' + direction + ');\n';
};

Blockly.JavaScript['direction_selector'] = function (block: Blockly.Block) {
    let code = block.getFieldValue('direction_dropdown');
    return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript['wait'] = function (block: Blockly.Block) {
    let seconds = Blockly.JavaScript.valueToCode(block, 'seconds', Blockly.JavaScript.ORDER_COMMA) || '0';
    return "api.control.waitAsync(" + seconds + ");\n";
};

Blockly.JavaScript['goto'] = function (block: Blockly.Block) {
    let numberX = Blockly.JavaScript.valueToCode(block, 'x', Blockly.JavaScript.ORDER_COMMA) || '0';
    let numberY = Blockly.JavaScript.valueToCode(block, 'y', Blockly.JavaScript.ORDER_COMMA) || '0';
    return 'api.movement.goto(sprite, ' + numberX + ', ' + numberY + ');\n';
};

Blockly.JavaScript['glide'] = function (block: Blockly.Block) {
    let seconds = Blockly.JavaScript.valueToCode(block, 'seconds', Blockly.JavaScript.ORDER_COMMA) || '0';
    let numberX = Blockly.JavaScript.valueToCode(block, 'x', Blockly.JavaScript.ORDER_COMMA) || '0';
    let numberY = Blockly.JavaScript.valueToCode(block, 'y', Blockly.JavaScript.ORDER_COMMA) || '0';
    return 'while (!api.movement.glide(runtime.runtime(), sprite, ' + seconds + ', ' + numberX + ', ' + numberY + ')) {}\n';
};

Blockly.Blocks['glide_to_sprite'] = {
    spriteList: function (): [string, string][] {
        const options: [string, string][] = [];
        addSpriteOptions(options, getCurrentSprite(this), "sprite:");
        if (options.length === 0) {
            options.push(['-', '']);
        }
        return options;
    },

    onChange: function () {
        let world = (window as any).world as FlatWorld;
        this.setEnabled(world.sprites.length > 2);
    },

    init: function () {
        this.appendDummyInput()
            .appendField(translate('BlockTranslations.glide_to_sprite.message0'))
            .appendField(new Blockly.FieldDropdown(this.spriteList.bind(this)), "object")
            .appendField(translate('BlockTranslations.glide_to_sprite.message1'))
            .appendField(new Blockly.FieldNumber(1), "seconds")
            .appendField(translate('BlockTranslations.glide_to_sprite.message2'));
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setStyle(themePatcher.getCategoryStyleFromBlock('glide_to_sprite'));
        this.setTooltip(translate('BlockTranslations.glide_to_sprite.tooltip'));
        this.setHelpUrl(translate('BlockTranslations.glide_to_sprite.helpUrl'));
        this.setOnChange(this.onChange.bind(this));
    }
};
Blockly.JavaScript['glide_to_sprite'] = function (block: Blockly.Block) {
    let object = block.getFieldValue('object');
    let seconds = block.getFieldValue('seconds') || '0';

    return "while (!api.movement.glideToSprite(runtime.runtime(), sprite, " + seconds + ", " + object.slice(7) + ")) {}\n";
};

Blockly.JavaScript['set_x'] = function (block: Blockly.Block) {
    let xPos = Blockly.JavaScript.valueToCode(block, 'x_pos', Blockly.JavaScript.ORDER_COMMA) || '0';
    return "api.movement.setX(sprite, " + xPos + ");\n";
};

Blockly.JavaScript['set_y'] = function (block: Blockly.Block) {
    let yPos = Blockly.JavaScript.valueToCode(block, 'y_pos', Blockly.JavaScript.ORDER_COMMA) || '0';
    return "api.movement.setY(sprite, " + yPos + ");\n";
};

Blockly.JavaScript['change_x'] = function (block: Blockly.Block) {
    let steps = Blockly.JavaScript.valueToCode(block, 'steps', Blockly.JavaScript.ORDER_COMMA) || '0';
    return "api.movement.moveBy(sprite, " + steps + ", 0);\n";
};

Blockly.JavaScript['increase_x'] = function (block: Blockly.Block) {
    let steps = Blockly.JavaScript.valueToCode(block, 'steps', Blockly.JavaScript.ORDER_COMMA) || '0';
    return "api.movement.moveBy(sprite, " + steps + ", 0);\n";
};

Blockly.JavaScript['decrease_x'] = function (block: Blockly.Block) {
    let steps = Blockly.JavaScript.valueToCode(block, 'steps', Blockly.JavaScript.ORDER_COMMA) || '0';
    steps *= (-1);
    return "api.movement.moveBy(sprite, " + steps + ", 0);\n";
};

Blockly.JavaScript['change_y'] = function (block: Blockly.Block) {
    let steps = Blockly.JavaScript.valueToCode(block, 'steps', Blockly.JavaScript.ORDER_COMMA) || '0';
    return "api.movement.moveBy(sprite, 0, " + steps + ");\n";
};

Blockly.JavaScript['increase_y'] = function (block: Blockly.Block) {
    let steps = Blockly.JavaScript.valueToCode(block, 'steps', Blockly.JavaScript.ORDER_COMMA) || '0';
    return "api.movement.moveBy(sprite, 0, " + steps + ", 0);\n";
};
Blockly.JavaScript['decrease_y'] = function (block: Blockly.Block) {
    let steps = Blockly.JavaScript.valueToCode(block, 'steps', Blockly.JavaScript.ORDER_COMMA) || '0';
    steps *= (-1);
    return "api.movement.moveBy(sprite, 0, " + steps + ", 0);\n";
};

Blockly.JavaScript['next_costume'] = function (block: Blockly.Block) {
    return "api.graphics.nextCostume(sprite);\n";
};

Blockly.JavaScript['previous_costume'] = function (block: Blockly.Block) {
    return "api.graphics.previousCostume(sprite);\n";
};

Blockly.Blocks['set_costume'] = {
    costumeList: function (): [string, string][] {
        const sprite = getCurrentSprite(this);
        if (!sprite) {
            return [["", ""]];
        }
        return sprite.costumes.map((costume) => [costume.name, "" + costume.id]);
    },

    init: function () {
        this.appendDummyInput()
            .appendField(translate('BlockTranslations.set_costume.message0'));
        this.appendDummyInput()
            .appendField(new Blockly.FieldDropdown(this.costumeList.bind(this)), "costume");
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setStyle(themePatcher.getCategoryStyleFromBlock('set_costume'));
        this.setTooltip("");
        this.setHelpUrl("");
    }
};
Blockly.JavaScript['set_costume'] = function (block: Blockly.Block) {
    let costume = block.getFieldValue('costume');
    return "api.graphics.setCostume(sprite, " + costume + ");\n";
};

Blockly.JavaScript['show'] = function (block: Blockly.Block) {
    return "api.graphics.setVisibility(sprite, true);\n";
};

Blockly.JavaScript['hide'] = function (block: Blockly.Block) {
    return "api.graphics.setVisibility(sprite, false);\n";
};

Blockly.JavaScript['mirrorX'] = function (block: Blockly.Block) {
    return "api.graphics.mirrorX(sprite);\n";
};

Blockly.JavaScript['mirrorY'] = function (block: Blockly.Block) {
    return "api.graphics.mirrorY(sprite);\n";
};

Blockly.JavaScript['change_size'] = function (block: Blockly.Block) {
    let value = block.getFieldValue('value');
    return 'api.graphics.changeSize(sprite, ' + value + ');\n';
};

Blockly.JavaScript['set_size'] = function (block: Blockly.Block) {
    let value = block.getFieldValue('value');
    return 'api.graphics.setSize(sprite, ' + value + ');\n';
};

Blockly.JavaScript['say_nothing'] = function (block: Blockly.Block) {
    return "api.graphics.attachTextPermanently(sprite, '', 'speech');\n";
};

Blockly.JavaScript['say'] = function (block: Blockly.Block) {
    let text = Blockly.JavaScript.valueToCode(block, 'text', Blockly.JavaScript.ORDER_COMMA) || '';
    return "api.graphics.attachTextPermanently(sprite, " + text + ", 'speech');\n";
};

Blockly.JavaScript['think'] = function (block: Blockly.Block) {
    let text = Blockly.JavaScript.valueToCode(block, 'text', Blockly.JavaScript.ORDER_COMMA) || '';
    return "api.graphics.attachTextPermanently(sprite, " + text + ", 'thought');\n";
};

Blockly.JavaScript['say_time_limited'] = function (block: Blockly.Block) {
    let text = Blockly.JavaScript.valueToCode(block, 'text', Blockly.JavaScript.ORDER_COMMA) || '';
    let seconds = block.getFieldValue('seconds');
    return "api.graphics.attachTextAsync(sprite, " + text + ", 'speech', " + seconds + ");\n";
};

Blockly.JavaScript['think_time_limited'] = function (block: Blockly.Block) {
    let text = Blockly.JavaScript.valueToCode(block, 'text', Blockly.JavaScript.ORDER_COMMA) || '';
    let seconds = block.getFieldValue('seconds');
    return "api.graphics.attachTextAsync(sprite, " + text + ", 'thought', " + seconds + ");\n";
};

Blockly.JavaScript['text_contains'] = function (block: Blockly.Block) {
    let string = Blockly.JavaScript.valueToCode(block, 'string', Blockly.JavaScript.ORDER_COMMA) || '';
    let substring = Blockly.JavaScript.valueToCode(block, 'substring', Blockly.JavaScript.ORDER_COMMA) || '';
    console.log("(" + string + ".indexOf(" + substring + ") >= 0)");
    return ["(" + string + ".indexOf(" + substring + ") >= 0)", Blockly.JavaScript.ORDER_FUNCTION_CALL];
};

Blockly.JavaScript['to_top_layer'] = function (block: Blockly.Block) {
    return "api.graphics.toTopLayer(sprite);\n";
};

Blockly.JavaScript['move_layers_back'] = function (block: Blockly.Block) {
    let layers = block.getFieldValue('layers');
    return "api.graphics.moveLayersBack(sprite, " + layers + ");\n";
};

Blockly.Blocks['is_touching'] = {
    spriteList: function (): [string, string][] {
        let options: [string,string][]
        if (!isMobile(window.navigator).any){
            options = [[translate('BlockTranslations.is_touching.mouse'), "mouse"], [translate('BlockTranslations.is_touching.edge'), "edge"]];
        }
        else {
        options = [[translate('BlockTranslations.is_touching.edge'), "edge"]];
        }
        addSpriteOptions(options, getCurrentSprite(this), "sprite:");
        return options;
    },

    init: function () {
        this.appendDummyInput()
            .appendField(translate('BlockTranslations.is_touching.message0'))
            .appendField(new Blockly.FieldDropdown(this.spriteList.bind(this)), "object")
            .appendField(translate('BlockTranslations.is_touching.message1'));
        this.setOutput(true, "Boolean");
        this.setStyle(themePatcher.getCategoryStyleFromBlock('is_touching'));
        this.setTooltip("");
        this.setHelpUrl("");
    }
};
Blockly.JavaScript['is_touching'] = function (block: Blockly.Block) {
    let order = Blockly.JavaScript.ORDER_FUNCTION_CALL;
    let code = "false";

    let object = block.getFieldValue('object');
    if (object.startsWith("sprite:")) {
        let otherSpriteId = object.slice(7);
        code = "api.sensing.isTouchingSprite(sprite, " + otherSpriteId + ")";
    } else if (object === "mouse") {
        code = "api.sensing.isTouchingMouse(sprite)";
    } else if (object === "edge") {
        code = "api.sensing.isTouchingEdge(sprite)";
    } else {
        order = Blockly.JavaScript.ORDER_ATOMIC;
    }

    return [code, order];
};

Blockly.Blocks['touched'] = {
    spriteList: function (): [string, string][] {
        const options: [string, string][] = [[translate('BlockTranslations.touched.edge'), "edge"]];
        addSpriteOptions(options, getCurrentSprite(this), "sprite:");
        return options;
    },

    init: function () {
        this.appendDummyInput()
            .appendField(translate('BlockTranslations.touched.message0'))
            .appendField(new Blockly.FieldDropdown(this.spriteList.bind(this)), "object")
            .appendField(translate('BlockTranslations.touched.message1'));
        this.setNextStatement(true, null);
        this.setStyle(themePatcher.getCategoryStyleFromBlock('touched'));
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['touched'] = function (block: Blockly.Block) {
    return "";
};

Blockly.Blocks['is_touching_color'] = {
    init: function () {
        let field = new Blockly.FieldColour("#ffffff");
        field.setColumns(5);
        this.appendDummyInput()
            .appendField(translate('BlockTranslations.is_touching_color.message0'))
            .appendField(field, "color")
            .appendField(translate('BlockTranslations.is_touching_color.message1'));
        this.setOutput(true, "Boolean");
        this.setStyle(themePatcher.getCategoryStyleFromBlock('is_touching_color'));
        this.setTooltip("");
        this.setHelpUrl("");
        Blockly.bindEvent_(this.svgGroup_, 'mousedown', this, function () {
            let renderUtils = (window as any).renderUtils as RenderUtils;
            field.setColours(renderUtils.colorPalette);
        });
    }
};

Blockly.JavaScript['is_touching_color'] = function (block: Blockly.Block) {
    let color = block.getFieldValue('color');
    let code = "api.sensing.isTouchingColor(sprite, 0x" + color.slice(1, 7) + ")";
    return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
};


Blockly.Blocks['distance'] = {
    spriteList: function (): [string, string][] {
        const options: [string, string][] = [];
        addSpriteOptions(options, getCurrentSprite(this));
        options.push([translate('BlockTranslations.distance.edge_top'), "edge_1"]);
        options.push([translate('BlockTranslations.distance.edge_bottom'), "edge_2"]);
        options.push([translate('BlockTranslations.distance.edge_left'), "edge_3"]);
        options.push([translate('BlockTranslations.distance.edge_right'), "edge_4"]);
        return options;
    },

    init: function () {
        this.appendDummyInput()
            .appendField(translate('BlockTranslations.distance.message0'))
            .appendField(new Blockly.FieldDropdown(this.spriteList.bind(this)), "object");
        this.setOutput(true, "Number");
        this.setStyle(themePatcher.getCategoryStyleFromBlock('distance'));
        this.setTooltip("");
        this.setHelpUrl("");
    }
};
Blockly.JavaScript['distance'] = function (block: Blockly.Block) {
    let object = block.getFieldValue('object');
    let code = "";
    if (object.startsWith("edge_")) {
        code = "api.sensing.distanceToEdge(sprite, " + object.substring(5) + ")";
    } else {
        code = "api.sensing.distanceTo(sprite, " + object + ")";
    }
    return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
};

Blockly.JavaScript['start'] = function (block: Blockly.Block) {
    return "";
};

Blockly.Blocks['receive'] = {
    eventList: function (): [string, string][] {
        const options: [string, string][] = [];
        const workspaceManager = (window as any).workspaceManager as WorkspaceManager;
        workspaceManager.workspaces.forEach(ws => {
            ws.blocklyWorkspace.getBlocksByType('send', false).forEach((block: any) => {
                let entry = [block.getFieldValue('msg'), block.getFieldValue('msg')];
                if (options.findIndex(optn => optn[0] === entry[0]) === -1) {
                    //@ts-ignore
                    options.push(entry);
                }
            });
        });
        if (options.length === 0) {
            options.push(['', '']);
        }
        return options;
    },

    loaded: false,

    init: function () {
        let field;
        if (this.loaded) {
            field = new Blockly.FieldDropdown(this.eventList.bind(this));
        } else {
            //field = new Blockly.FieldDropdown(this.eventList.bind(this));
            field = new Blockly.FieldTextInput(undefined, (value: string) => {
                return value.trim();
            });
        }
        this.appendDummyInput('input')
            .appendField(translate('BlockTranslations.receive.message0'))
            .appendField(field, 'event_name')
            .appendField(translate('BlockTranslations.receive.message1'));
        this.setNextStatement(true, null);
        this.setStyle(themePatcher.getCategoryStyleFromBlock('receive'));
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['receive'] = function (block: Blockly.Block) {
    return "";
};

Blockly.Blocks['send'] = {
    init: function () {
        this.appendDummyInput('input')
            .appendField(translate('BlockTranslations.send.message0'))
            .appendField(new Blockly.FieldTextInput(undefined, (value: string) => {
                return value.trim();
            }), 'msg')
            .appendField(translate('BlockTranslations.send.message1'));
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setStyle(themePatcher.getCategoryStyleFromBlock('send'));
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['send'] = function (block: Blockly.Block) {
    let msg = block.getFieldValue('msg');
    return "api.utils.fireEvent('receive',  '" + msg + "');\n";
};

Blockly.JavaScript['clicked'] = function (block: Blockly.Block) {
    return "";
};

Blockly.JavaScript['clicked_other'] = function (block: Blockly.Block) {
    return "";
};

Blockly.JavaScript['get_x'] = function (block: Blockly.Block) {
    let code = 'api.movement.getX(sprite)';
    return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
};

Blockly.JavaScript['get_y'] = function (block: Blockly.Block) {
    let code = 'api.movement.getY(sprite)';
    return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
};

Blockly.JavaScript['get_rotation'] = function (block: Blockly.Block) {
    let code = 'api.movement.getRotation(sprite)';
    return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
};

Blockly.JavaScript['wait_until'] = function (block: Blockly.Block) {
    let condition = Blockly.JavaScript.valueToCode(block, 'condition',
        Blockly.JavaScript.ORDER_LOGICAL_NOT) || 'true';
    let branch = Blockly.JavaScript.addStartAndStop('', block);
    return 'while(!' + condition + ') {\n' + branch + '}\n';
};

Blockly.Blocks['clicked_other'] = {
    spriteList: function (): [string, string][] {
        const options: [string, string][] = [];
        addSpriteOptions(options, getCurrentSprite(this), "sprite:");
        if (options.length === 0) {
            options.push(['-', '']);
        }
        return options;
    },

    init: function () {
        this.appendDummyInput()
            .appendField(translate('BlockTranslations.clicked_other.message0'))
            .appendField(new Blockly.FieldDropdown(this.spriteList.bind(this)), "object")
            .appendField(translate('BlockTranslations.clicked_other.message1'));
        this.setNextStatement(true, null);
        this.setStyle(themePatcher.getCategoryStyleFromBlock('touched'));
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

function keyList() {
    let options = [
        [translate('BlockTranslations.key_pressed.anykey'), "-1"],
        [translate('BlockTranslations.key_pressed.arrow_up'), "38"],
        [translate('BlockTranslations.key_pressed.arrow_down'), "40"],
        [translate('BlockTranslations.key_pressed.arrow_right'), "39"],
        [translate('BlockTranslations.key_pressed.arrow_left'), "37"]
    ];
    for (let i = 0; i < 26; i++) {
        options.push([String.fromCharCode(97 + i), '' + (65 + i)]);
    }
    for (let i = 0; i < 10; i++) {
        options.push([String.fromCharCode(48 + i), '' + (48 + i)]);
    }
    return options;
}

Blockly.Blocks['key_pressed'] = {
    init: function () {
        this.appendDummyInput()
            .appendField(translate('BlockTranslations.key_pressed.message0'))
            .appendField(new Blockly.FieldDropdown(keyList), "key")
            .appendField(translate('BlockTranslations.key_pressed.message1'));
        this.setNextStatement(true, null);
        this.setStyle(themePatcher.getCategoryStyleFromBlock('key_pressed'));
        this.setTooltip("");
        this.setHelpUrl("");
    }
};
Blockly.JavaScript['key_pressed'] = function (block: Blockly.Block) {
    return '';
};


Blockly.Blocks['is_key_pressed'] = {
    init: function () {
        this.appendDummyInput()
            .appendField(translate('BlockTranslations.is_key_pressed.message0'))
            .appendField(new Blockly.FieldDropdown(keyList), "key")
            .appendField(translate('BlockTranslations.is_key_pressed.message1'));
        this.setOutput(true, "Boolean");
        this.setStyle(themePatcher.getCategoryStyleFromBlock('is_key_pressed'));
        this.setTooltip("");
        this.setHelpUrl("");
    }
};
Blockly.JavaScript['is_key_pressed'] = function (block: Blockly.Block) {
    let key = block.getFieldValue('key');
    let code = 'api.sensing.isKeyDown(' + key + ')';
    return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
};

Blockly.Blocks['sim_key_press'] = {
    init: function () {
        this.appendDummyInput()
            .appendField(translate('BlockTranslations.sim_key_press.message0'))
            .appendField(new Blockly.FieldDropdown(keyList), "key");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setStyle(themePatcher.getCategoryStyleFromBlock('sim_key_press'));
        this.setTooltip("");
        this.setHelpUrl("");
    }
};
Blockly.JavaScript['sim_key_press'] = function (block: Blockly.Block) {
    let key = block.getFieldValue('key');
    return 'api.control.simulateKeyPress(' + key + ', sprite)\n';
};

Blockly.JavaScript['controls_forever'] = function (block: Blockly.Block) {
    var branch = Blockly.JavaScript.statementToCode(block, 'DO');
    branch = Blockly.JavaScript.addStartAndStop(branch, block);
    return 'while (true) {\n' + branch + '}\n';
};

Blockly.Blocks['controls_ignore_key_inputs'] = {
    init: function () {
        this.appendDummyInput()
            .appendField(translate('BlockTranslations.controls_ignore_key_inputs.message0'))
            .appendField(new Blockly.FieldDropdown(keyList), "key");
        this.appendStatementInput("DO").setCheck(null);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setStyle(themePatcher.getCategoryStyleFromBlock('controls_ignore_key_inputs'));
        this.setTooltip("");
        this.setHelpUrl("");
    }
};
Blockly.JavaScript['controls_ignore_key_inputs'] = function (block: Blockly.Block) {
    let key = block.getFieldValue('key');
    let branch = Blockly.JavaScript.statementToCode(block, 'DO');
    return 'api.sensing.ignoreKeyInput(' + key + ', true);\n' + branch + '\n api.sensing.ignoreKeyInput(' + key + ',false);\n';
};

Blockly.JavaScript['stop'] = function (block: Blockly.Block) {
    let stopTarget = block.getFieldValue('stop_target');
    if (stopTarget === "all") {
        return 'api.control.stopAll();\n';
    } else {
        return 'api.control.stopTarget(runtime.runtime(), sprite, "' + stopTarget + '");\n';
    }
};

Blockly.JavaScript['math_random'] = function (block: Blockly.Block) {
    const valueMin = Blockly.JavaScript.valueToCode(block, 'MIN', Blockly.JavaScript.ORDER_COMMA) || 0;
    const valueMax = Blockly.JavaScript.valueToCode(block, 'MAX', Blockly.JavaScript.ORDER_COMMA) || 0;
    const code = 'api.utils.random(' + valueMin + ', ' + valueMax + ')';
    return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
};

Blockly.JavaScript['start_clone'] = function (block: Blockly.Block) {
    return '';
};

Blockly.Blocks['create_clone'] = {
    spriteList: function (): [string, string][] {
        const mySprite = getCurrentSprite(this);
        if (!mySprite) {
            return [["", ""]];
        }

        const options: [string, string][] = [[translate('BlockTranslations.create_clone.self'), "self"]];
        addSpriteOptions(options, mySprite);
        return options;
    },

    init: function () {
        this.appendDummyInput()
            .appendField(translate('BlockTranslations.create_clone.message0'))
            .appendField(new Blockly.FieldDropdown(this.spriteList.bind(this)), "object");
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setStyle(themePatcher.getCategoryStyleFromBlock('create_clone'));
        this.setTooltip("");
        this.setHelpUrl("");
    }
};
Blockly.JavaScript['create_clone'] = function (block: Blockly.Block) {
    let object = block.getFieldValue('object');
    if (object === "self") {
        return "api.control.createClone(sprite);\n";
    } else {
        return "api.control.createCloneFromID(" + object + ");\n";
    }
};

Blockly.JavaScript['delete_clone'] = function (block: Blockly.Block) {
    return 'api.control.deleteClone(sprite);\n';
};
Blockly.Blocks['rotate_to_sprite'] = {
    spriteList: function (): [string, string][] {
        let options: [string, string][] =[['','']]
        if (!isMobile(window.navigator).any){
            options = [[translate('BlockTranslations.rotate_to_sprite.mouse'), "mouse"]];
        }
        addSpriteOptions(options, getCurrentSprite(this), "sprite:");
        return options;
    },

    init: function () {
        this.appendDummyInput()
            .appendField(translate('BlockTranslations.rotate_to_sprite.message0'))
            .appendField(new Blockly.FieldDropdown(this.spriteList.bind(this)), "object");
        this.setInputsInline(true);
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.setStyle(themePatcher.getCategoryStyleFromBlock('rotate_to_sprite'));
        this.setTooltip(translate('BlockTranslations.rotate_to_sprite.tooltip'));
        this.setHelpUrl(translate('BlockTranslations.rotate_to_sprite.helpUrl'));
    }
};
Blockly.JavaScript['rotate_to_sprite'] = function (block: Blockly.Block) {
    let code = "false";
    let object = block.getFieldValue('object');
    if (object.startsWith("sprite:")) {
        code = "api.movement.rotateToSprite(sprite, " + object.slice(7) + ");\n";
    } else if (object === "mouse") {
        code = "api.movement.rotateToMouse(sprite);\n";
    }

    return code;
};
Blockly.JavaScript['timer'] = function (block: Blockly.Block) {
    let time = 'api.control.getTime()';
    return [time, Blockly.JavaScript.ORDER_FUNCTION_CALL];
};

// TODO: move blocks to variables category
Blockly.JavaScript['variables_show'] = function (block: Blockly.Block) {
    // TODO: escape id
    return "api.utils.showMonitor('" + block.getFieldValue('VAR') + "');\n";
};

Blockly.JavaScript['variables_hide'] = function (block: Blockly.Block) {
    // TODO: escape id
    return "api.utils.hideMonitor('" + block.getFieldValue('VAR') + "');\n";
};

Blockly.JavaScript['variables_reduce'] = function(block: Blockly.Block) {
    // Add to a variable in place.
    var argument0 = Blockly.JavaScript.valueToCode(block, 'DELTA',
        Blockly.JavaScript.ORDER_SUBTRACTION) || '0';
    var varName = Blockly.JavaScript.nameDB_.getName(
        block.getFieldValue('VAR'), Blockly.VARIABLE_CATEGORY_NAME);
    return varName + ' = (typeof ' + varName + ' == \'number\' ? ' + varName +
        ' : 0) - ' + argument0 + ';\n';
};

Blockly.JavaScript['notify'] = function (block: Blockly.Block) {
    let message_type = block.getFieldValue('message_type');
    let text = Blockly.JavaScript.valueToCode(block, 'text', Blockly.JavaScript.ORDER_COMMA) || '';
    return "api.utils.notify(" + text + "," + message_type + ");\n";
};

Blockly.JavaScript['open_url'] = function (block: Blockly.Block) {
    let URL = Blockly.JavaScript.valueToCode(block, 'URL', Blockly.JavaScript.ORDER_COMMA) || '';
    return "api.utils.popUp(" + URL + ");\n";
};

Blockly.JavaScript['open_iframe'] = function (block: Blockly.Block) {
    let URL = Blockly.JavaScript.valueToCode(block, 'URL', Blockly.JavaScript.ORDER_COMMA) || '';
    let title = Blockly.JavaScript.valueToCode(block, 'title', Blockly.JavaScript.ORDER_COMMA) || '';
    let buttontemp = block.getFieldValue('buttonbool');
    let lvlcode = Blockly.JavaScript.valueToCode(block, 'levelcode', Blockly.JavaScript.ORDER_COMMA) || '';
    return "api.utils.openIframe(" + URL + "," + title + "," + buttontemp + "," + lvlcode + " );\n";
};

Blockly.JavaScript['set_tutorial_page'] = function (block: Blockly.Block) {
    let pageIndex = block.getFieldValue('pageIndex') || 0;
    return "api.utils.tutorialSetPage(" + pageIndex + ");\n";
};

Blockly.JavaScript['next_tutorial_page'] = function (block: Blockly.Block) {
    return "api.utils.tutorialNextPage();\n";
};

Blockly.JavaScript['previous_tutorial_page'] = function (block: Blockly.Block) {
    return "api.utils.tutorialPreviousPage();\n";
};

Blockly.JavaScript['stop_level'] = function (block: Blockly.Block) {
    return "api.utils.stopLevel()";
};

Blockly.Blocks['do_as_sprite'] = {
    spriteList: function (): [string, string][] {
        const options: [string, string][] = [];
        addSpriteOptions(options, getCurrentSprite(this), "sprite:");
        if (options.length === 0) {
            options.push(['-', '']);
        }
        return options;
    },

    init: function () {
        this.setPreviousStatement(true, null);
        this.setNextStatement(true, null);
        this.appendDummyInput()
            .appendField(translate('BlockTranslations.do_as_sprite.message0'))
            .appendField(new Blockly.FieldDropdown(this.spriteList.bind(this)), "object");
        this.appendStatementInput('DO');
        //this.setOutput(true, "Boolean");
        this.setStyle(themePatcher.getCategoryStyleFromBlock('do_as_sprite'));
        this.setTooltip("");
        this.setHelpUrl("");
    }
};

Blockly.JavaScript['do_as_sprite'] = function (block: Blockly.Block) {
    let object = block.getFieldValue('object');
    var branch = Blockly.JavaScript.statementToCode(block, 'DO').replaceAll("sprite", "api.utils.getSpriteFromID(" + object.slice(7) + ")");
    return branch;
};

Blockly.JavaScript['open_level_from_code'] = function (block: Blockly.Block) {
    let object = Blockly.JavaScript.valueToCode(block, 'lvlcode', Blockly.JavaScript.ORDER_COMMA) || '';
    return "api.utils.loadLevelFromString(" + object + ");\n";
};

Blockly.JavaScript['gameover'] = function (block: Blockly.Block) {
    return "";
};

Blockly.JavaScript['win'] = function (block: Blockly.Block) {
    return "api.utils.setWinState(" + WinStates.WON + ");\n";
};

Blockly.JavaScript['lose'] = function (block: Blockly.Block) {
    return "api.utils.setWinState(" + WinStates.LOST + ");\n";
};


Blockly.JavaScript['pause_level'] = function (block: Blockly.Block) {
    return "api.utils.pauseLevel();\n";
};

Blockly.JavaScript['restart_level'] = function (block: Blockly.Block) {
    return "api.utils.restartLevel();\n";
};

Blockly.JavaScript['reset_level'] = function (block: Blockly.Block) {
    return "api.utils.resetLevel();\n";
};

//Imported blocks from Blockly

export { Blockly, EventBlocks };
