import * as React from "react";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import LevelManager from '../levels';
import {AppRoot, notifyUser} from '../app';
import {WithTranslation, withTranslation} from 'react-i18next';
import {SlideUp} from "./transitions";

let JSZip = require("jszip");

/**
 * The props for the LevelExportModal
 */
interface LevelDownlaodProps extends WithTranslation {
    readonly trigger: any;
    readonly parent: any;
}

/**
 * The state for the LevelExportModal
 */
interface LevelExportState {
    open: boolean;
    name: string;
}

/**
 * Displays a modal that allows saving the level to a local file.
 */
class LevelExportModal extends React.Component<LevelDownlaodProps, LevelExportState> {
    constructor(props: LevelDownlaodProps) {
        super(props);
        this.state = {open: false, name: 'Level'};
    }

    /**
     * Opens the modal.
     */
    open = () => {
        this.setState({open: true});
        this.setState({name: LevelManager.levelTitle});
    };

    /**
     * Closes the modal.
     */
    close = () => {
        this.setState({open: false});
        this.props.parent.handleClose();
    };

    /**
     * Same as close but does not close parent.
     */
    closeWithoutParent = () => {
        this.setState({open: false});
    };

    /**
     * Shows the browsers Export-file dialog and closes the modal.
     */
    save = () => {
        this.showExport();
        this.close();
    };

    /**
     * Changes the filename after the user inputs it.
     * @param {React.ChangeEvent<HTMLInputElement>} event
     */
    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({name: event.target.value});
    };

    /**
     * Submits on enter key event.
     */
    handleKeyInput = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            this.save();
        }
    };

    /**
     * Shows the browsers Export file dialog with the correct file name.
     */
    async showExport() {
        const {t} = this.props;
        let levelManager = (window as any).levelManager as LevelManager;
        AppRoot.INSTANCE.resetProject();
        const filename = this.state.name + '.zip';
        const levelname = this.state.name;
        let zip = new JSZip();
        //zip exported level
        let file = levelManager.exportFile();      
        zip.file(this.state.name + '.cubi', file);
        //download, modify and zip html file
        let html = await fetch('scorm/cubi.html');
        let htmlBlob = await html.blob();
        let htmlText = await htmlBlob.text();
        const re = /{level_name}/gi;
        htmlText = htmlText.replace(re, levelname);
        zip.file('cubi.html', htmlText);
        //download, modify and zip xml file
        let imsmanifest = await fetch('scorm/imsmanifest.xml');
        let imsmanifestBlob = await imsmanifest.blob();
        let imsmanifestText = await imsmanifestBlob.text();
        imsmanifestText = imsmanifestText.replace(re, levelname);
        zip.file('imsmanifest.xml', imsmanifestText);
        //download and zip js file
        let js = await fetch('scorm/script.js');
        let jsBlob = await js.blob();
        let jsText = await jsBlob.text();
        jsText = jsText.replace(re, levelname);
        zip.file('script.js', jsText);
        //bundle and download zip
        zip.generateAsync({type: 'blob'})
            .then(function (blob: Blob) {
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = filename
                link.click()
                URL.revokeObjectURL(link.href)
            });
        notifyUser(t('LevelExportModal.NOTIFY_SAVED'), {variant: 'success'});
    }

    render() {
        const {t} = this.props;
        return ([
            React.cloneElement(this.props.trigger, {key: "1", onClick: this.open}),
            (
                <Dialog
                    key="2"
                    open={this.state.open}
                    onClose={this.close}
                    TransitionComponent={SlideUp}
                >
                    <DialogTitle id="form-dialog-title">{t('LevelExportModal.title')}</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus={true}
                            id="project-name"
                            label={t('LevelExportModal.NameFieldLabel')}
                            value={this.state.name}
                            onChange={this.handleChange}
                            fullWidth={true}
                            onKeyPress={this.handleKeyInput}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeWithoutParent} color="primary">
                            {t('Cancel')}
                        </Button>
                        <Button onClick={this.save} color="primary">
                            {t('Export')}
                        </Button>
                    </DialogActions>
                </Dialog>
            )
        ]);
    }
}

export default withTranslation()(LevelExportModal);
