import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import LanguageDetector from "i18next-browser-languagedetector";

import translationsDE from './de.json';
import translationsEN from './en.json';
import translationsES from './es.json';
import translationsNL from './nl.json';
import translationsUA from './ua.json';
import translationsFR from './fr.json';

// @ts-ignore
import * as Blockly from '@IT4Kids/cubi2-blockly';
import { initBlocks } from "src/blocks";

var De = require('@IT4Kids/cubi2-blockly/msg/de');
var En = require('@IT4Kids/cubi2-blockly/msg/en');
var Es = require('@IT4Kids/cubi2-blockly/msg/es');
var Nl = require('@IT4Kids/cubi2-blockly/msg/nl');
var Ua = require('@IT4Kids/cubi2-blockly/msg/uk');
var Fr = require('@IT4Kids/cubi2-blockly/msg/fr');

export const I18NFallbackLanguage: string = "en";

i18n.on('languageChanged', (language) => {
    //language is a formatted locale name, that has the format of "<language>-<REGION>". We only care about the language part.
    //Example explaination here https://learn.microsoft.com/en-us/windows/win32/intl/locale-names
    switch (language.slice(0, 2)) {
        case 'de':
            Blockly.setLocale(De);
            break;
        case 'en':
            Blockly.setLocale(En);
            break;
        case 'es':
            Blockly.setLocale(Es);
            break;
        case 'nl':
            Blockly.setLocale(Nl);
            break;
        case 'ua':
            Blockly.setLocale(Ua);
            break;
        case 'fr':
            Blockly.setLocale(Fr);
            break;
        default:
            Blockly.setLocale(En);
            break;
    }

    initBlocks()
})

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources: {
            de: { translations: translationsDE },
            en: { translations: translationsEN },
            es: { translations: translationsES },
            fr: { translations: translationsFR },
            nl: { translations: translationsNL },
            ua: { translations: translationsUA },
        },
        fallbackLng: [I18NFallbackLanguage],
        defaultNS: 'translations',
        detection: {
            lookupQuerystring: 'lang'
        }
    })

/**
 * Translates a block by replacing the entire key.
 * This actually works by checking all translations in the translation dict and then checks if the
 * block definition contains t(key) as a sanity check.
 * @param block
 */
export function translateBlock(block: any) {

    function setNestedKey(target: any, path: string, value: string) {
        const keys = path.split('-');
        const len = keys.length;
        const orig = target;

        for (let i = 0; i < len; i++) {
            let prop = keys[i];

            if (i === len - 1) {
                const targetVal = target[prop];
                if (targetVal !== undefined) {
                    target[prop] = value;

                }
                break;
            }
            if (!target[prop]) {
                target[prop] = {};
            }
            target = target[prop];
        }
        return orig;
    }

    const blockTrans = i18n.t('BlockTranslations.' + block.type, { returnObjects: true });
    for (let key of Object.keys(blockTrans)) {
        block = setNestedKey(block, key, i18n.t('BlockTranslations.' + block.type + '.' + key, { returnObjects: true }));
    }
    return block;
}

export function translate(key: string) {
    return i18n.t(key);
}

export default i18n;
