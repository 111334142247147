import CodeRunner, { Runtime } from "../interpreter";
import EventEmitter from "eventemitter3";
import Sprite from "../sprite";
import FlatWorld from "../world";
import { AppRoot } from '../app';
import Input from "src/input";

export default class Control {

    private _interpreter: CodeRunner;
    private _eventEmitter: EventEmitter;
    private _world: FlatWorld;
    private _input: Input;

    /**
     * Control offers an interface to various code flow tasks
     * such as waiting and threads (clones)
     * @param interpreter The interpreter of the current context
     * @param eventEmitter Event Emitter
     * @param world Flat world
     * @constructor
     */
    constructor(interpreter: CodeRunner, eventEmitter: EventEmitter, world: FlatWorld, input: Input) {
        this._interpreter = interpreter;
        this._eventEmitter = eventEmitter;
        this._world = world;
        this._input = input;
    }

    /**
     * This function lets the sprite wait for a particular amount of seconds
     * Async function: the interpreter is paused until <code>callback</code> is called
     * @param seconds
     * @param callback Passed by the interpreter, used to resume the interpreter
     */
    waitAsync(seconds: number, callback: Function) {
        setTimeout(() => {
            callback();
        }, seconds * 1000);
    }

    stopAll() {
        AppRoot.INSTANCE.pauseProject();
    }

    /**
     * Offers functionality to halt execution from a sprite.
     * This can be done for this sprite or just one block-chain of the sprite.
     * @param runtime
     * @param sprite
     * @param target "this" or "sprite_other".
     */
    stopTarget(runtime: Runtime, sprite: Sprite, target: "this" | "sprite_other") {
        switch (target) {
            case 'this':
                runtime.exit();
                break;
            case 'sprite_other':
                this._interpreter.stopByOwner(sprite.id, [runtime.threadId()]);
                break;
            default:
        }
    }

    /**
     * Spawns a clone of a sprite and fires the event.
     * @param sprite
     */
    createClone(sprite: Sprite) {
        let clone = sprite.clone();
        if (AppRoot.INSTANCE.state.isPlaying && clone) {
            this._eventEmitter.emit("start_clone", clone);
        }
        this._interpreter.newFrame();
    }

    /**
     * Spawns a clone of a sprite and fires the event.
     * @param spriteId
     */
    createCloneFromID(spriteId: number) {
        let clone = this._world.sprites[spriteId].clone();
        if (AppRoot.INSTANCE.state.isPlaying && clone) {
            this._eventEmitter.emit("start_clone", clone);
        }
        this._interpreter.newFrame();
    }

    /**
     * Deletes a sprite, if it is a clone.
     * @param sprite The sprite clone that should be deleted
     */
    deleteClone(sprite: Sprite) {
        let root = sprite.parent;
        if (root) {
            this._interpreter.stopBySpriteGuid(sprite.guid);
            root.removeClone(sprite);
            this._interpreter.newFrame();
        }
    }

    getTime() {
        return ((Date.now() - this._interpreter.getStartTime()) / 1000);
    }

    simulateKeyPress(key: number, sprite: Sprite) {
        if (this._input.pressedKeys.indexOf(key) === -1) {
            this._input.pressedKeys.push(key);
            setTimeout(() => this._input.pressedKeys.splice(this._input.pressedKeys.indexOf(key), 1), 0);
        }
        this._eventEmitter.emit("key_pressed", key);
        if (sprite.holding) {
            setTimeout(() => this.simulateKeyPress(key, sprite), 50);
        }
    }
}
