import * as React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button/Button';
import AnalyticsModal from './AnalyticsModal';
import SettingsModal from './SettingsModal';
import LevelLoadModal from './LevelLoadModal';
import ToolboxCreatorModal from './ToolboxCreatorModal';
import LevelDownloadModal from './LevelDownloadModal';
import LevelRestoreModal from "./LevelRestoreModal";
import LevelExportModal from "./LevelExportModal";
import IconButton from '@material-ui/core/IconButton/IconButton';
import BrushIcon from '@material-ui/icons/Brush';
import RemoveIcon from '@material-ui/icons/Remove';
import FolderIcon from '@material-ui/icons/Folder';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import SaveIcon from '@material-ui/icons/Save';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import SettingsIcon from '@material-ui/icons/Settings';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import HealingIcon from '@material-ui/icons/Healing';
import TuneIcon from '@material-ui/icons/Tune';
import DehazeIcon from '@material-ui/icons/Dehaze';
import ReplayIcon from '@material-ui/icons/Replay';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import TranslateIcon from '@material-ui/icons/Translate';
import FeedbackIcon from '@material-ui/icons/Feedback';
import BuildIcon from '@material-ui/icons/Build';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import {
    Divider,
    Drawer,
    alpha,
    FormControl,
    InputBase,
    isWidthDown,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    makeStyles,
    MenuItem,
    Select,
    Toolbar,
    Tooltip,
    WithWidth,
    withWidth,
    isWidthUp
} from '@material-ui/core';
import LevelManager, { SaveType } from '../levels';
import { WithTranslation, withTranslation } from 'react-i18next';
import logo from '../assets/img/cubi-animated.gif';
import i4klogo from '../assets/img/it-for-kids.png';

import mt from 'mousetrap';
import Swal from 'sweetalert2';
import { AppRoot } from '../app';
import PasswordModal from "./PasswordModal";
import BugReportModal from './BugReportModal';
import environment, { changeEnvironment } from 'src/environment';
import { sa_event } from '../utils/analytics';
import CloudOffIcon from '@material-ui/icons/CloudOff';
import { Restore } from 'src/utils/restore';
import Storage from 'src/utils/storage';

let pJSON = require("../../package.json");

export let fixeditorflag: number = 0

const useStyles = makeStyles(theme => ({
    logo: {
        height: 48,
        marginLeft: theme.spacing(2),
    },
    project: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.black, 0.15),
        transition: 'background-color 0.3s',
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    projectWithHover: {
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.black, 0.05)
        },
    },
    projectIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
    inputInputReadOnly: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
        cursor: 'default',
    },
}));

function ProjectTitleInput(props: any) {
    const classes = useStyles();
    const { value, onChange, onMouseDown, readOnly } = props;

    return (
        <div className={`${classes.project} ${readOnly ? '' : classes.projectWithHover}`}>
            <div className={classes.projectIcon}>
                <FolderIcon />
            </div>
            <InputBase
                placeholder="Levelname"
                classes={{
                    root: classes.inputRoot,
                    input: readOnly ? classes.inputInputReadOnly : classes.inputInput,
                }}
                onChange={onChange}
                value={value}
                id={'lebel-name'}
                onMouseDown={onMouseDown}
                inputProps={{ 'aria-label': 'project',  inputMode: 'text'  }}
                readOnly={readOnly}
                type="string"
            />
        </div>
    );
}

interface MenuAppBarProps extends WithTranslation, WithWidth {
    readonly isPlaying: boolean;
    readonly runProject: () => void;
    readonly pauseProject: () => void;
    readonly resetProject: () => void;
    readonly newLevel: () => void;
    readonly newSprite: () => void;
    readonly duplicateSprite: () => void;
    readonly setAllStartPositions: () => void;
}

interface MenuAppBarState {
    open: boolean;
    levelTitle: string;
}

class MenuAppBar extends React.Component<MenuAppBarProps, MenuAppBarState> {
    constructor(props: MenuAppBarProps) {
        super(props);
        this.state = {
            open: false,
            levelTitle: LevelManager.levelTitle,
        };
    }

    handleClose = () => {
        this.setState({ open: false });
    };
    handleProjectMenu = (event: any) => {
        this.setState({ open: true });
    };

    handleLevelNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ levelTitle: event.target.value });
        LevelManager.levelTitle = event.target.value;
    };

    onMouseDown = (event: React.MouseEvent) => {
        if (AppRoot.INSTANCE.view === "embedded") {
            event.preventDefault();
        }
    };

    onChangeLanguage = (event: React.ChangeEvent<{ value: unknown }>) => {
        const { t } = this.props;
        let level = new URLSearchParams(window.location.search).get('level');
        const langKey = event.target.value as string;
        if (level === null) {
            level = '';
        } else {
            level = '&level=' + level;
        }
        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?lang=' + langKey + level;
        if (AppRoot.INSTANCE.levelManager && AppRoot.INSTANCE.levelManager.downloadNecessary) {
            Swal.fire({
                text: t('Dialogs.ChangeLanguage'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: t('Dialogs.ButtonYes'),
                cancelButtonText: t('Dialogs.ButtonNo'),
                confirmButtonColor: '#FD5151',
                cancelButtonColor: '#00A2E8',
                heightAuto: false
            }).then((result) => {
                if (result.value) {
                    window.location.href = newurl;
                }
            });
        } else {
            window.location.href = newurl;
        }
    };

    fixEditor = async () => {
        fixeditorflag = 1
        let level = AppRoot.INSTANCE.levelManager?.save(SaveType.OBJECT)
        Restore.blockedReset = false
        Restore.reset(level)
        Storage.set("fixeditor",true)
        window.location.reload()
        // Close the menu
        this.handleClose()
        sa_event("fix_editor")
        fixeditorflag = 0
    }

    componentDidMount = () => {
        /***
         * Shortcuts
         */

        mt.bind('r', () => {
            if (!this.props.isPlaying) {
                this.props.resetProject();
            }
            return false;
        });
    };

    componentWillUnmount = () => {
        mt.unbind('r');
    };

    render() {
        const { open } = this.state;
        const { t, i18n, width } = this.props;
        const { fixEditor } = this;

        if ((LevelManager.levelTitleUpdate || this.state.levelTitle === '\u200B') && LevelManager.levelTitle !== '\u200B') {
            LevelManager.levelTitleUpdate = false;
            this.setState({ levelTitle: LevelManager.levelTitle });
        }

        let playPauseButton;
        if (!this.props.isPlaying) {
            playPauseButton =
                <IconButton color="inherit" id="play" onClick={this.props.runProject}
                    title={t('Tooltips.PLAY_PROJECT')}><PlayArrowIcon /></IconButton>;
        } else {
            playPauseButton =
                <IconButton color="inherit" id="pause" onClick={this.props.pauseProject}
                    title={t('Tooltips.PLAY_PROJECT')}><PauseIcon /></IconButton>;
        }

        let version = pJSON.version;
        if (environment.ENV_NAME === 'dev' || window.location.host.includes('dev')) {
            changeEnvironment('dev');
            version += '-dev';
        }


        return (
            <div>
                <AppBar position="static" color={"default"} elevation={0}>
                    <Toolbar style={{marginLeft: AppRoot.INSTANCE.view === "embedded" ? "-24px" : 0}}>
                        {
                            AppRoot.INSTANCE.view !== "embedded" &&
                            <Button
                                onClick={this.handleProjectMenu}
                                color="inherit"
                            >
                                <DehazeIcon style={{ paddingRight: '5px' }} />
                                {t('MENU.MENU_TITLE')}
                            </Button>
                        }
                        <ProjectTitleInput value={this.state.levelTitle} onChange={this.handleLevelNameChange}
                            onMouseDown={this.onMouseDown}
                            readOnly={AppRoot.INSTANCE.view === "embedded"} />
                        {
                            playPauseButton
                        }
                        <IconButton color="inherit" onClick={this.props.resetProject}
                            title={t('Tooltips.RESET_PROJECT')}><ReplayIcon /></IconButton>
                        {LevelManager.isBuildMode() &&
                            <IconButton color="inherit" onClick={this.props.setAllStartPositions}
                                title={t('Tooltips.SET_ALL_START_POSITIONS')}><LocationOnIcon /></IconButton>
                        }
                        {
                            LevelManager.isBuildMode() &&
                            <Button
                                disabled={true}
                                style={{fontSize: "0.5rem", background: "#145dad", color: "white", margin: "5px", borderRadius: 0}}>
                                <BuildIcon style={{paddingRight: '5px'}}/>
                                {isWidthUp('md', width) && t('MENU.BUILDMODE')}
                            </Button>
                        }
                        {
                            AppRoot.INSTANCE.state.isOffline &&
                            <Tooltip title={t('MENU.ISOFFLINE') as string}>
                                <span><Button
                                    disabled={true}
                                    style={{fontSize: "0.5rem", background: "#CB253D", color: "white", margin: "5px", borderRadius: 0}}>
                                    <CloudOffIcon style={{paddingRight: '5px'}}/>
                                </Button></span>
                            </Tooltip>
                        }
                        <div style={{display: "flex", flexGrow: 1}}/>
                        {
                            !AppRoot.INSTANCE.state.isOffline && AppRoot.INSTANCE.view !== "embedded" &&
                            <BugReportModal trigger={
                                isWidthDown('sm', width) ?
                                    <IconButton
                                        color="inherit"
                                        style={{marginRight: "10px"}}>
                                        <FeedbackIcon/>
                                    </IconButton>
                                    : <Button
                                        color="inherit"
                                        style={{marginRight: "10px"}}
                                        startIcon={<FeedbackIcon style={{paddingRight: '5px'}}/>}>
                                        {t('MENU.FEEDBACK')}
                                    </Button>}
                            />
                        }
                        <a href={"https://www.it-for-kids.org"} target={"_blank"} rel={"noopener noreferrer"}>
                            <img style={{ marginTop: "5px" }} height="40px" src={i4klogo} alt="it4kids" />
                        </a>
                        {
                            !isWidthDown('sm', width) &&
                            (<>
                                <img style={{ marginRight: "10px" }} height="40px" src={logo} alt="cubi" />
                                <Tooltip
                                    title={<span>Made with <span role="img" aria-label="love"
                                        style={{ fontFamily: "emojione mozilla" }}>❤️</span> by @IT4Kids/Developers.</span>}
                                >
                                    <Typography variant="caption">
                                        {version}
                                    </Typography>
                                </Tooltip>
                            </>)
                        }
                    </Toolbar>
                </AppBar>
                <Drawer
                    open={open}
                    anchor={'left'}
                    onClose={this.handleClose}
                >
                    <List subheader={<ListSubheader>{t('MENU.MENU_TITLE')}</ListSubheader>}>
                        <ListItem button onClick={() => {
                            if (AppRoot.INSTANCE.levelManager && AppRoot.INSTANCE.levelManager.downloadNecessary) {
                                Swal.fire({
                                    text: t('Dialogs.NewProjectConfirm'),
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonText: t('Dialogs.ButtonYes'),
                                    cancelButtonText: t('Dialogs.ButtonNo'),
                                    confirmButtonColor: '#FD5151',
                                    cancelButtonColor: '#00A2E8',
                                    heightAuto: false
                                }).then((result) => {
                                    if (result.value) {
                                        this.props.newLevel();
                                        this.handleClose();
                                    }
                                });
                            } else {
                                this.props.newLevel();
                                this.handleClose();
                            }
                        }}>
                            <ListItemIcon>
                                <NoteAddIcon color={"secondary"} />
                            </ListItemIcon>
                            <ListItemText primary={t('MENU.NEW_PROJECT')} />
                        </ListItem>
                        <LevelLoadModal trigger={<ListItem button>
                            <ListItemIcon>
                                <FolderIcon color={"secondary"} />
                            </ListItemIcon>
                            <ListItemText primary={t('MENU.OPEN_PROJECT')} />
                        </ListItem>} parent={this} />
                        <LevelDownloadModal trigger={<ListItem button>
                            <ListItemIcon>
                                <SaveIcon color={"secondary"} />
                            </ListItemIcon>
                            <ListItemText primary={t('MENU.SAVE_PROJECT')} />
                        </ListItem>} parent={this} />

                        {
                            LevelManager.isBuildMode() &&
                            <LevelExportModal
                                trigger={<ListItem button>
                                    <ListItemIcon>
                                        <SaveAltIcon color={"secondary"} />
                                    </ListItemIcon>
                                    <ListItemText primary={t('MENU.EXPORT_PROJECT')} />
                                </ListItem>}
                                parent={this} />
                        }

                        <LevelRestoreModal trigger={<ListItem button>
                            <ListItemIcon>
                                <ReplayIcon color={"secondary"} />
                            </ListItemIcon>
                            <ListItemText primary={t('MENU.RESTORE_PROJECT')} />
                        </ListItem>} parent={this} />
                        <ListItem>
                            <ListItemIcon>
                                <TranslateIcon color={"secondary"} />
                            </ListItemIcon>
                            <FormControl fullWidth>
                                <Select
                                    id="language-select"
                                    value={i18n.resolvedLanguage}
                                    label="language"
                                    onChange={this.onChangeLanguage}
                                >
                                    <MenuItem value={'de'}>Deutsch</MenuItem>
                                    <MenuItem value={'en'}>English</MenuItem>
                                    <MenuItem value={'ua'}>Українська (Ukrainian)</MenuItem>
                                    <MenuItem value={'fr'}>Français (French)</MenuItem>
                                    <MenuItem value={'es'}>Español (Spanish)</MenuItem>
                                    <MenuItem value={'nl'}>Nederlands (Dutch)</MenuItem>
                                </Select>
                            </FormControl>
                        </ListItem>
                        <ListItem button onClick={fixEditor}>
                            <ListItemIcon>
                                <HealingIcon color={"secondary"} />
                            </ListItemIcon>
                            <ListItemText primary={t('MENU.FIX_EDITOR')} />
                        </ListItem>
                    </List>
                    <Divider />
                    <List subheader={<ListSubheader>{t('MENU.ADVANCED_TITLE')}</ListSubheader>}>
                        {!LevelManager.isBuildMode() && <PasswordModal
                            trigger={<ListItem button>
                                <ListItemIcon>
                                    <BrushIcon color={"secondary"} />
                                </ListItemIcon>
                                <ListItemText>{t('MENU.BUILDMODE')}</ListItemText>
                            </ListItem>}
                            parent={this}
                        />}
                        {
                            LevelManager.isBuildMode() &&
                            <SettingsModal
                                trigger={<ListItem button>
                                    <ListItemIcon>
                                        <SettingsIcon color={"secondary"} />
                                    </ListItemIcon>
                                    <ListItemText primary={t('MENU.PROJECT_SETTINGS')} />
                                </ListItem>}
                                parent={this}
                            />
                        }
                        {
                            LevelManager.isBuildMode() &&
                            <ToolboxCreatorModal
                                trigger={<ListItem button>
                                    <ListItemIcon>
                                        <TuneIcon color={"secondary"} />
                                    </ListItemIcon>
                                    <ListItemText primary={t('MENU.TOOLBOX_SETTINGS')} />
                                </ListItem>}
                                parent={this}
                            />
                        }
                        {
                            LevelManager.isBuildMode() &&
                            <AnalyticsModal
                                trigger={<ListItem button>
                                    <ListItemIcon>
                                        <ShowChartIcon color={"secondary"} />
                                    </ListItemIcon>
                                    <ListItemText primary={t('MENU.ANALYTICS_SETTINGS')} />
                                </ListItem>}
                                parent={this}
                            />
                        }
                        {LevelManager.isBuildMode() && <ListItem button onClick={() => {
                            LevelManager.toggleUserMode();
                            this.forceUpdate();
                        }}
                        >
                            <ListItemIcon>
                                <RemoveIcon color={"secondary"} />
                            </ListItemIcon>
                            <ListItemText>{t('MENU.PLAYMODE')}</ListItemText>
                        </ListItem>}


                    </List>
                </Drawer>
            </div>
        );
    }
}

export default withWidth()(withTranslation()(MenuAppBar));
