export class badWordFilter{
    static badwords = [
        'analritter',
        'arsch',
        'arschficker',
        'arschlecker',
        'arschloch',
        'bimbo',
        'bratze',
        'bumsen',
        'bonze',
        'dödel',
        'fick',
        'ficken',
        'flittchen',
        'fotze',
        'fratze',
        'hackfresse',
        'hure',
        'hurensohn',
        'ische',
        'kackbratze',
        'kacke',
        'kacken',
        'kackwurst',
        'kampflesbe',
        'kanake',
        'kimme',
        'lümmel',
        'MILF',
        'Mist',
        'möpse',
        'morgenlatte',
        'möse',
        'mufti',
        'muschi',
        'nackt',
        'neger',
        'nigger',
        'nippel',
        'nutte',
        'onanieren',
        'orgasmus',
        'penis',
        'pimmel',
        'pimpern',
        'pinkeln',
        'pissen',
        'pisser',
        'popel',
        'poppen',
        'porno',
        'reudig',
        'rosette',
        'schabracke',
        'schlampe',
        'scheiße',
        'scheisser',
        'schiesser',
        'schnackeln',
        'schwanzlutscher',
        'schwuchtel',
        'stinkt',
        'tittchen',
        'titten',
        'vögeln',
        'vollpfosten',
        'wichse',
        'wichsen',
        'wichser',
    ]
}