import Sprite from '../../../sprite';
import * as React from 'react';
import LevelManager from '../../../levels';
import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { SpriteEditorInstance } from '../SpriteEditor';

interface SpriteEditInfoProps {
    readonly sprite: Sprite|null;
    readonly spriteInfo: string;
    readonly label?: string|JSX.Element;
    readonly width: number;
    readonly disabled: boolean;
    readonly updateDragging: Function;
    readonly icon?: JSX.Element;
}

export class SpriteEditInfoField extends React.Component<SpriteEditInfoProps> {
    private _infoValue: number;
    private _infoInput: string;
    public infoValueRejected: boolean = false;

    constructor(props: SpriteEditInfoProps) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this._infoValue = this.props.sprite![this.props.spriteInfo];
        if (this.props.spriteInfo === 'scale') {
            this._infoInput = this._infoValue.toFixed(2);
        } else {
            this._infoInput = Math.trunc(this._infoValue).toString();
        }
        SpriteEditorInstance.SPRITEFIELDS.push(this);
    }

    get infoValue() {
        if (this.props.disabled || LevelManager.isBuildMode()) {
            this.readNewValue();
        }
        if (this.infoValueRejected) {
            return this._infoInput;
        }
        if (this.props.spriteInfo === 'scale') {
            return this._infoInput;
        } else {
            return Math.trunc(this._infoValue).toString();
        }
    }

    set infoValue(value: string) {
        if (this.props.spriteInfo === 'scale') {
            this.props.sprite![this.props.spriteInfo] = parseFloat(value);
        } else {
            this.props.sprite![this.props.spriteInfo] = Math.trunc(parseFloat(value));
        }
    }

    readNewValue(force: boolean = false) {
        this._infoValue = this.props.sprite![this.props.spriteInfo];
        if (force) {
            this.infoValueRejected = false;
            if (this.props.spriteInfo === 'scale') {
                this._infoInput = this._infoValue.toFixed(2);
            } else {
                this._infoInput = Math.trunc(this._infoValue).toString();
            }
        }
    }

    handleChange = (event: any) => {
        let val = event.target.value;
        this._infoInput = val;
        if (isNaN(Number(val)) || val === '') {
            this.infoValueRejected = true;
        } else {
            this.infoValue = val;
            this.readNewValue();
            this.infoValueRejected = false;
        }
        this.props.updateDragging(true);
    };

    onBlur = () => {
        this.readNewValue(true);
        this.infoValueRejected = false;
        this.props.updateDragging(true);
    };

    render() {
        if (this.props.sprite) {
            return (
                <div style={{display: "inline-block"}}>
                    <Grid container alignItems="flex-end">
                        {this.props.icon && <Grid item>
                            {this.props.icon}
                        </Grid>}
                        <Grid item>
                            <TextField
                                label={this.props.label}
                                value={this.infoValue}
                                style={{width: this.props.width, marginRight: "10px"}}
                                onChange={this.handleChange}
                                onBlur={this.onBlur}
                                disabled={this.props.disabled}
                                error={this.infoValueRejected}
                            />
                        </Grid>
                    </Grid>
                </div>
            );
        } else {
            return;
        }
    }
}
