import escapeXml from './utils/xmlEscape';
import { Logging } from './utils/logging';
import { translate } from './i18n/i18n';
import Blockly from "@IT4Kids/cubi2-blockly/blockly";

import blocks from "./blocks.json";

/**
 * @override Restrict toolbox scale to 1, so when you scale the blocks you don't scale the toolbox.
 */
Blockly.VerticalFlyout.prototype.getFlyoutScale = function () {
    return 1;
};

export default class Toolbox {

    //Converts blocks array from blocks.json-file to dictionary with type as key
    static readonly blocks = blocks;

    private static defaultToolbox: string|undefined;
    private static readonly shadowTypes = {Number: {type: "math_number", name: "NUM"}, String: {type: "text", name: "TEXT"}};
    private static readonly functionsStr = '<category css-container="category category-functions" css-icon="category-icon category-icon-functions" name="' + translate('Categories.Functions') + '" categorystyle="procedure_category" custom="PROCEDURE"></category>';
    private static readonly variablesStr = '<category css-container="category category-variables" css-icon="category-icon category-icon-variables" name="' + translate('Categories.Variables') + '" categorystyle="variable_category" custom="VARIABLE"></category>';

    private activeBlocks: string[];
    private cachedXml: string|undefined;

    private categoryOrder = {
        "Events": null,
        "Movement": null,
        "Control": null,
        "Senses": null,
        "Math": null,
        "Variables": null,
        "Loops": null,
        "Looks": null,
        "Functions": null,
        "Advanced": null,
        "Gamemaster": null,
        "None": null
    };

    static getDefaultToolbox() {
        if (!Toolbox.defaultToolbox) {
            Toolbox.defaultToolbox = new Toolbox().generateToolbox();
        }
        return Toolbox.defaultToolbox;
    }

    constructor(activeBlocks?: string[]) {
        this.activeBlocks = activeBlocks ? activeBlocks : ([].concat(...Object.keys(Toolbox.blocks).map(key => Toolbox.blocks[key].blocks))).map((block: any) => block.type);
    }

    getActiveBlocks() {
        return this.activeBlocks;
    }

    /**
     * Generates a new toolbox with the given blocks.
     * @returns {string} The xml string for the generated toolbox
     */
    generateToolbox(): string {
        if (this.cachedXml) {
            return this.cachedXml;
        }

        Logging.debug(`Regenerating toolbox from ${this.activeBlocks}.`);
        //The resulting toolbox xml
        let xml: string = "<xml>";

        //Custom order for categories
        for (let c in this.categoryOrder) {
            this.categoryOrder[c] = Toolbox.blocks[c];
        }

        //Iterate over all categories
        for (let categoryName in this.categoryOrder) {
            if (categoryName === 'Variables' && this.activeBlocks.indexOf("variables") >= 0) {
                xml += Toolbox.variablesStr;
            } else if (categoryName === 'Functions' && this.activeBlocks.indexOf("functions") >= 0) {
                xml += Toolbox.functionsStr;
            }
            if (Toolbox.blocks.hasOwnProperty(categoryName) && !Toolbox.blocks[categoryName]._pseudo && Toolbox.blocks[categoryName].visible === "true") {

                //Load the current category object, including blocks and pseudo-attributes. See blocks.json
                const category = Toolbox.blocks[categoryName];

                if (category._isSeperator) {
                    xml += "<sep/>";
                } else {
                    //Tracks state to not show a category if we have not selected any blocks from that category.
                    let addCategory = false;

                    //Pregenerate the current category xml, regardless of wether we use it or not.
                    let categoryString = '<category css-container="category category-' + categoryName.toLowerCase() + '" css-icon="category-icon category-icon-' + categoryName.toLowerCase() + '" name="' + escapeXml(translate('Categories.' + categoryName)) + '" categorystyle="' + category.style + '">';

                    //Iterate over all blocks in the category
                    for (let block of category.blocks) {

                        //If this block is in the list of blocks from method parameter
                        if (this.activeBlocks.indexOf(block.type) >= 0) {
                            addCategory = true;

                            //Add block string to category
                            categoryString += '<block type="' + escapeXml(block.type) + '">';

                            //Add possible mutations of block
                            if (block.mutations) {
                                for (let mutation of block.mutations) {
                                    categoryString += `
                                    <mutation ${mutation[0]}="${mutation[1]}">
                                    </mutation>`;
                                }
                            }

                            //Replace "_shadowValue" in a block input with a number field.
                            if (block.args0) {
                                for (let blockInput of block.args0) {
                                    let type = Toolbox.shadowTypes[blockInput.check];
                                    if (blockInput._shadowValue && type) {
                                        categoryString += `
                                    <value name="${blockInput.name}">
                                        <shadow type="${type.type}">
                                            <field name="${type.name}">${blockInput._shadowValue}</field>
                                        </shadow>
                                    </value>`;
                                    }
                                }
                            }
                            //Close block tag
                            categoryString += "</block>";
                        }
                    }
                    //Close category and add if we hit at least one match
                    categoryString += "</category>";
                    if (addCategory) {
                        xml += categoryString;
                    }
                }
            }
        }

        //Close xml, finished toolbox generation
        xml += "</xml>";
        this.cachedXml = xml;
        return xml;
    }
}
