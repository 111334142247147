/*
 * This module exports functions for the communication with aws,
 * e.g. to store and load levels from cloud
 */

import environment from "src/environment";
import Swal from "sweetalert2";
import { translate } from "../i18n/i18n";
import LevelManager, { LoadingTypes } from "../levels";
import Storage from "../utils/storage";
import {AppRoot, notifyUser} from "../app";
import { Logging } from './logging';
/*
 * Get the aws api_endpoint
 */

const UPLOAD_API = {
    prod: "https://cloud.it-for-kids.org/api/v1/userLevelsUpload",
    dev: "https://cloud.it-for-kids.org/api/v1/userLevelsUpload",
};
const DOWNLOAD_API = {
    prod: "https://cloud.it-for-kids.org/api/v1/userLevelsDownload",
    dev: "https://cloud.it-for-kids.org/api/v1/userLevelsDownload",
};

/*
 * Interfaces for response
 */
interface UploadResponse {
    uploadURL: string;
    Key: string;
}
interface DownloadResponse {
    downloadURL: string;
    Key: string;
}

export interface CloudLevel {
    key: string;
    title: string;
    date: number;
}

/*
 * Alert the error
 */
function errorAlert(message: string) {
    Swal.fire({
        text: message,
        icon: "error",
        confirmButtonText: "Ok",
        confirmButtonColor: "#FD5151",
        heightAuto: false,
    });
}

/*
 * Receive an upload link
 */
async function getUploadLink(filesize: number) {
    try {
        let response = await fetch(UPLOAD_API[environment.ENV_NAME], {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                filesize,
            })
        });

        if (response.status !== 200) {
            throw new Error(translate("awsCloud.ErrorRequest"));
        }
        let json: UploadResponse = await response.json();
        return json;
    } catch (e) {
        errorAlert(e.message);
        let res: UploadResponse = { uploadURL: "", Key: "" };
        return res;
    }
}
/*
 * Receive a download link
 */
async function getDownloadLink(key: string) {
    try {
        let response = await fetch(
            DOWNLOAD_API[environment.ENV_NAME] + "?file=" + key
        );

        if (response.status !== 200) {
            throw new Error(translate("awsCloud.ErrorRequest"));
        }
        let json:DownloadResponse = await response.json();
        if(json.downloadURL === ""){
            throw new Error(translate("awsCloud.ErrorKey"));
        }
        return json;

    }
    catch(e) {
        Logging.error(e);
        errorAlert(e.message);
        let res: DownloadResponse = { downloadURL: "", Key: "" };
        return res;
    }
}

/*
 * Upload cubi file to cloud
 */
export async function uploadFileToCloud(file: string) {
    const data = new Blob([file], { type: "application/json" });
    const options = {
        method: "PUT",
        headers: {},
        body: data,
    };
    try {
        /**
         * Welcome in the world of encoding strings
         * 
         * The default `.length` of javascript does not look at the count of bytes.
         * For example, `'ü.length'` will return 1. However, it uses 2 bytes in storage.
         * 
         * Who decided that? I don't know. I hope they burn in hell.
         */
        const filesize = new Blob([file]).size
        const uploadInfo = await getUploadLink(filesize);
        if (uploadInfo.uploadURL === "") {
            throw new Error(translate("awsCloud.ErrorUpload"));
        }
        let response = await fetch(uploadInfo.uploadURL, options);
        if (response.status !== 200) {
            throw new Error(translate("awsCloud.ErrorRequest"));
        }
        return uploadInfo.Key;
    }
    catch (e) {
        Logging.error(e);
        errorAlert(e.message);
        return "";
    }
}

/*
 * Download cubi file from cloud
 */
export async function downloadFileFromCloud(key: string) {
    try {
        const downloadInfo = await getDownloadLink(key);
        if (downloadInfo.downloadURL === "") {
            throw new Error(translate("awsCloud.ErrorKey"));
        }
        let response = await fetch(downloadInfo.downloadURL);
        if (response.status !== 200) {
            throw new Error(translate("awsCloud.ErrorRequest"));
        }
        return await response.text();
    } catch (e) {
        errorAlert(e.message);
        return "";
    }
}

/*
 * Name is the filename '' for levelTitle
 */

export function showDownload(name: string) {
    let levelManager = (window as any).levelManager as LevelManager;
    AppRoot.INSTANCE.resetProject();
    let file = levelManager.exportFile();
    notifyUser(translate("LevelDownloadModal.NOTIFY_SAVED"), {
        variant: "success",
    });
    const blob = new Blob([file], { type: "application/json;charset=UTF-8" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    if (name === "") {
        link.download = LevelManager.levelTitle + ".cubi";
    } else {
        link.download = name + ".cubi";
    }
    link.click();
    URL.revokeObjectURL(link.href);
}

/*
 * Uplaod the current level to the Cloud and return the level code.
 */
export async function saveLevelInCloud() {
    let levelManager = (window as any).levelManager as LevelManager;
    AppRoot.INSTANCE.resetProject();
    let file = levelManager.exportFile();
    const key = await uploadFileToCloud(file);
    const filename = document.getElementById("project-name") as HTMLInputElement;
    if (key !== "") {
        let cloudLevels = Storage.get("CloudLevels", []) as CloudLevel[];
        cloudLevels.push({
            key: key,
            title: filename.value,
            date: Date.now(),
        });
        Storage.set("CloudLevels", cloudLevels);
    }
    return key;
}

/*
 * Load a level from a levelcode: String
 */
export async function loadLevelAws(key: string) {
    return await downloadFileFromCloud(key).then((res) => {
        if (res === "") {
            return false;
        }
        let levelManager = (window as any).levelManager as LevelManager;
        levelManager.importFile(res, {type: LoadingTypes.DIRECT_JSON, levelCodeOrUrl: key});
        return true;
    });
}

/**
 * Get static download link from key
 */
export async function getStaticLink(
    key: string,
    directLink: boolean = false,
    embedded: boolean = false
) {
    let embeddedParam = embedded ? "&embedded=1" : "";
    let linkOfLevel = `https://editor.it-for-kids.org/?levelCode=${key}${embeddedParam}`;
    if (directLink) {
        return linkOfLevel;
    } else {
        const resp = await getDownloadLink(key);
        return resp.downloadURL;
    }
}
(window as any).getStaticLink = getStaticLink;

export interface CloudLevel { }
